import store from "~/store";

export default async (to, from, next) => {
    if (store.getters["auth/check"]) {
        next({
            name: "dashboard",
            params: { work_shop_id: store.getters["auth/defaultWorkshopId"] }
        });
    } else {
        next();
    }
};
