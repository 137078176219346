var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "nav-item dropdown user-menu" }, [
    _c(
      "a",
      {
        staticClass: "nav-link dropdown-toggle",
        attrs: { href: "#", "data-toggle": "dropdown" },
      },
      [
        _c("img", {
          staticClass: "user-image img-circle elevation-2",
          attrs: { src: _vm.avatar, alt: "User Image" },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "d-none d-md-inline" }, [
          _vm._v(_vm._s(_vm.userName)),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-menu dropdown-menu-lg dropdown-menu-right" },
      [
        _c("li", { staticClass: "user-header bg-primary" }, [
          _c("img", {
            staticClass: "img-circle elevation-2",
            attrs: { src: _vm.avatar, alt: "User Image" },
          }),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v("\n        " + _vm._s(_vm.userName) + "\n        "),
              _vm._l(_vm.roles, function (role) {
                return _c("div", { key: role.name }, [
                  _c("small", [_vm._v(_vm._s(role.name))]),
                ])
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "li",
          { staticClass: "user-footer" },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-default btn-flat",
                on: { click: _vm.whoAmI },
              },
              [_c("i", { staticClass: "fas fa-sync-alt" })]
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "btn btn-default btn-flat",
                attrs: {
                  to: {
                    name: "profile",
                    params: { work_shop_id: _vm.currentWorkShopId },
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("profile.title")))]
            ),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn-default btn-flat float-right",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.logout.apply(null, arguments)
                  },
                },
              },
              [_vm._v(_vm._s(_vm.$t("profile.sign_out")))]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }