function page(path) {
    return () =>
        import(/* webpackChunkName: '' */ `~/pages/${path}`).then(
            (m) => m.default || m
        );
}

export default [
    { path: "/login", name: "login", component: page("auth/LoginPage.vue") },
    {
        path: "/",
        redirect: { name: "login" },
    },
    {
        path: "/:work_shop_id",
        name: "dashboard",
        component: page("DashBoardPage.vue"),
    },
    {
        path: "/:work_shop_id/profile",
        name: "profile",
        component: page("users/profile/ProfilePage.vue"),
    },
    {
        path: "/:work_shop_id/clients",
        name: "clients",
        component: page("clients/ClientsPage.vue"),
    },
    {
        path: "/:work_shop_id/clients/show/:clientId",
        component: page("clients/ClientShowPage.vue"),
        props: true,
        children: [
            {
                path: "",
                name: "client-show",
                component: page("clients/ClientInfoNestedPage.vue"),
                props: true,
            },
            {
                path: "address",
                name: "client-address-show",
                component: page("clients/ClientAddressNestedPage.vue"),
                props: true,
            },
            {
                path: "order-stat",
                name: "client-order-stat-show",
                component: page("clients/ClientOrderStatNestedPage.vue"),
                props: true,
            },
        ],
    },
    {
        path: "/:work_shop_id/discount-cards",
        name: "discount-cards",
        component: page("discountcards/DiscountCardsPage.vue"),
    },
    {
        path: "/:work_shop_id/delivery-dates",
        name: "delivery-dates",
        component: page("deliverydates/DeliveryDatesPage.vue"),
        props: true,
    },
    {
        path: "/tags/:type_tag_id",
        name: "tags",
        component: page("tags/TagPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/services",
        name: "services",
        component: page("services/ServicePage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/work-shops",
        name: "work-shops",
        component: page("workshops/WorkShopPage.vue"),
    },
    {
        path: "/:work_shop_id/users",
        name: "users",
        component: page("users/UsersPage.vue"),
    },
    {
        path: "/:work_shop_id/foremen",
        name: "foremen",
        component: page("foremen/ForemenPage.vue"),
    },
    {
        path: "/foremen-for-clients",
        name: "foremen-for-clients",
        component: page("foremen/for-clients/ForemenForClientsPage.vue"),
    },
    {
        path: "/:work_shop_id/foremen/services/:foreman_id",
        name: "foreman-services",
        component: page("foremen/ForemanServicesPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/my-services",
        name: "foreman-my-services",
        component: page("foremen/my-services/ForemanMyServicesPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/couriers",
        name: "couriers",
        component: page("courier/CouriersPage.vue"),
    },
    {
        path: "/:work_shop_id/orders/create/:client_id?",
        name: "orders-create",
        component: page("orders/OrderCreatePage.vue"),
    },
    {
        path: "/:work_shop_id/orders/edit/:order_id",
        name: "order-edit",
        component: page("orders/OrderEditPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/orders/show/:order_id",
        name: "order-show",
        component: page("orders/OrderShowPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/orders",
        name: "orders",
        component: page("orders/OrdersPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/orders/settings",
        name: "order-settings",
        component: page("orders/settings/OrderSettingsPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/articles",
        name: "articles",
        component: page("articles/ArticlesPage.vue"),
    },
    {
        path: "/:work_shop_id/towns",
        name: "towns",
        component: page("towns/TownsPage.vue"),
    },
    {
        path: "/:work_shop_id/metro-stations/:town_id",
        name: "metro-stations",
        component: page("metrostations/MetroStationsPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/projects",
        name: "projects",
        component: page("projects/projects/ProjectsPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/projects/settings/:project_id",
        name: "project-settings",
        component: page("projects/settings/ProjectSettingsPage.vue"),
        props: true,
    },
    {
        path: "/:work_shop_id/projects/:project_id/task/:task_id",
        component: page("projects/tasks/ProjectTaskShowPage.vue"),
        props: true,
        children: [
            {
                path: "",
                name: "project-show-task",
                component: page("projects/tasks/ProjectTaskAbouNestedPage.vue"),
                props: true,
            },
            {
                path: "sub-tasks",
                name: "project-show-task-sub-tasks",
                component: page(
                    "projects/tasks/ProjectTaskSubtaskNestedPage.vue"
                ),
                props: true,
            },
            {
                path: "stats",
                name: "project-show-task-stats",
                component: page("projects/tasks/ProjectTaskStatNestedPage.vue"),
                props: true,
            },
        ],
    },
    {
        path: "/:work_shop_id/projects/:project_id",
        name: "project-show",
        component: page("projects/projects/ProjectShowPage.vue"),
        props: true,
    },
    {
        path: "/500",
        name: "500-error",
        component: page("errors/Error500Page.vue"),
    },
    {
        path: "*",
        name: "404-not-found",
        component: page("errors/NotFound404Page.vue"),
    },
];
