var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _c("div", [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
        _vm.required
          ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "v-swatches",
          {
            class: { "is-invalid": _vm.form.errors.has(_vm.name) },
            attrs: {
              swatches: "text-advanced",
              id: _vm.id,
              "show-fallback": "",
              "fallback-input-type": "color",
              "popover-x": "right",
              "popover-y": "bottom",
              "spacing-size": 5,
            },
            model: {
              value: _vm.form[_vm.name],
              callback: function ($$v) {
                _vm.$set(_vm.form, _vm.name, $$v)
              },
              expression: "form[name]",
            },
          },
          [
            _c("div", [
              _c("input", {
                attrs: { slot: "trigger", readonly: "" },
                domProps: { value: _vm.form[_vm.name] },
                slot: "trigger",
              }),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }