<template>
  <select-field
    :title="$t('pages.orders.order_status')"
    :id="id"
    :name="name"
    :form="form"
    :label="optionLabel"
    :options="options"
    :multiple="multiple"
    :required="required"
    :selectable="(option) => parseInt(option.is_active) == 1"
  >
    <template v-slot:no-options="{ search, searching }">
      <template v-if="searching">{{
        $t("pages.orders.statuses.no_option_founded")
      }}</template>
      <em style="opacity: 0.5" v-else>{{
        $t("pages.orders.statuses.status_search")
      }}</em>
    </template>
    <template #option="option">
      <div style="margin: 2px">
        <order-status-formatted-field
          :status="option"
          :showTitle="false"
        ></order-status-formatted-field>
        <span style="margin-left: 5px">{{ option["name"] }}</span>
      </div>
    </template>
  </select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";
import { OrderStatus } from "./index";
import OrderStatusFormattedField from "./OrderStatusFormattedField.vue";

import {
  DataSearching,
  DataFiltering,
  GetDataRequest,
} from "../../../api/request";
import api from "../../../api/orders";

import { mapGetters } from "vuex";
import { arrayWrap } from "../../../utils/functions";

export default {
  extends: BaseListField,
  components: {
    SelectField,
    OrderStatusFormattedField,
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    optionLabel() {
      return `${this.locale}name`;
    },
  },
  data() {
    return {
      loadOptionsAjax: (searchingText) => {
        const request = new GetDataRequest({
          searching: [
            new DataSearching(
              `order_statuses.name${this.locale}name`,
              searchingText,
              "ILIKE"
            ),
            new DataSearching("order_statuses.id", searchingText, "ILIKE"),
          ],
        });
        return api.getOrderStatuses(this.currentWorkShopId, {
          ...request,
        });
      },
      loadOptionsByIds: (ids) => {
        let filteredIds = arrayWrap(ids);

        const request = new GetDataRequest({
          filtering: [
            new DataFiltering("order_statuses.id", filteredIds.join(","), "in"),
          ],
          perPage: filteredIds.length,
        });
        return api.getOrderStatuses(this.currentWorkShopId, {
          ...request,
        });
      },
      loadOptions: async () => {
        let { data } = await api.getOrderStatuses(this.currentWorkShopId, {
          perPage: 1000,
        });
        this.options = data.data;
      },
    };
  },
  methods: {
    init(ids) {
      api.getOrderStatuses(this.currentWorkShopId, {}).then((response) => {
        this.options = response.data.data.map((x) => OrderStatus.parse(x));

        if (this.$isset(ids)) {
          ids = arrayWrap(ids);

          if (this.$isset(ids)) {
            ids = arrayWrap(ids);
            if (this.multiple) {
              this.form[this.name] = this.options.filter((x) =>
                ids.includes(parseInt(x.id))
              );
            } else {
              this.form[this.name] = this.options.find(
                (x) => parseInt(x.id) === parseInt(ids[0])
              );
            }
          }
        } else {
          //init default status
          if (this.initDefault) {
            this.options.forEach((item) => {
              if (item.is_default === 1) {
                this.form[this.name] = item;
              }
            });
          }
        }
      });
    },
  },
};
</script>

<style>
</style>