<template>
  <select-field
    :title="$t('pages.orders.discount_type')"
    label="name"
    :options="options"
    :form="form"
    :name="name"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <template #no-options>{{ $t("fields.no_options") }}</template>
  </select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";
import {
  DISCOUNT_TYPE_FIX_SUM,
  DISCOUNT_TYPE_FIX_SUM_BY_ONE,
  DISCOUNT_TYPE_PERCENT,
} from "../../constants";

export default {
  extends: BaseListField,
  components: {
    SelectField,
  },
  data() {
    return {
      loadOptions: async () => {
        this.options = [
          {
            id: DISCOUNT_TYPE_FIX_SUM,
            name: this.$t("pages.orders.discount_type_fix_sum"),
          },
          {
            id: DISCOUNT_TYPE_FIX_SUM_BY_ONE,
            name: this.$t("pages.orders.discount_type_fix_sum_by_one"),
          },
          {
            id: DISCOUNT_TYPE_PERCENT,
            name: this.$t("pages.orders.discount_type_percent"),
          },
        ];
      },
    };
  },
};
</script>