var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "draggable",
    {
      staticClass: "cont pl-0",
      attrs: { tag: "ul", group: { name: "children" }, handle: ".handle" },
      on: { change: _vm.change },
      model: {
        value: _vm.node.children,
        callback: function ($$v) {
          _vm.$set(_vm.node, "children", $$v)
        },
        expression: "node.children",
      },
    },
    _vm._l(_vm.node.children, function (child) {
      return _c(
        "li",
        {
          key: child.key,
          staticClass: "node",
          staticStyle: { "padding-top": "5px" },
          attrs: { id: child.key },
        },
        [
          _c("div", { staticClass: "row m-0" }, [
            _c(
              "div",
              { staticClass: "col-md-12 pl-0" },
              [
                _vm.showMoveTreeNode
                  ? _c(
                      "a",
                      { staticClass: "mr-1", attrs: { href: "#" } },
                      [
                        _c("Icon", {
                          staticClass: "handle",
                          attrs: {
                            icon: "octicon:grabber-16",
                            width: "24",
                            height: "24",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("start-line", null, { child: child }),
                _vm._v(" "),
                !child.empty
                  ? _c("i", {
                      staticClass: "far fa-lg mr-1",
                      class: {
                        "fa-folder": !child.empty && !child.open,
                        "fa-folder-open": !child.empty && child.open,
                      },
                      on: {
                        click: function ($event) {
                          return child.toggleOpen()
                        },
                      },
                    })
                  : _c("span", [_vm._v("-")]),
                _vm._v(" "),
                _vm._t(
                  "tree-text",
                  function () {
                    return [
                      _c("span", { staticClass: "node-text" }, [
                        _vm._v(_vm._s(child.data.name)),
                      ]),
                      _vm._v(" "),
                      !child.empty
                        ? _c("span", [_vm._v(_vm._s("(" + child.count + ")"))])
                        : _vm._e(),
                    ]
                  },
                  { child: child }
                ),
                _vm._v(" "),
                _vm.showAddTreeNode
                  ? _c(
                      "span",
                      {
                        staticClass: "ml-3",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.addButtonClicked({
                              id: child.key,
                              data: child.data,
                            })
                          },
                        },
                      },
                      [_c("i", { staticClass: "fas fa-plus" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showMenuTreeNode
                  ? _c(
                      "span",
                      {
                        staticClass: "ml-1",
                        attrs: { "data-toggle": "dropdown" },
                      },
                      [_c("i", { staticClass: "fas fa-list" })]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.showMenuTreeNode
                  ? _c(
                      "div",
                      {
                        staticClass: "dropdown-menu",
                        attrs: { id: "dropdown-menu-tree-node-" + child.key },
                      },
                      [
                        _vm._t(
                          "dropdown-menu",
                          function () {
                            return [
                              _vm.showEditTreeNode
                                ? _c("my-edit-drop-down-menu-item", {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.editButtonClicked({
                                          id: child.key,
                                          data: child.data,
                                        })
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showInfoTreeNode
                                ? _c("my-info-drop-down-menu-item", {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.infoButtonClicked({
                                          id: child.key,
                                          data: child.daπta,
                                        })
                                      },
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showRemoveTreeNode
                                ? _c("my-remove-drop-down-menu-item", {
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.removeButtonClicked({
                                          id: child.key,
                                          data: child.data,
                                        })
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          },
                          { child: child }
                        ),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c(
            "vue-my-tree-node",
            _vm._g(
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: child.open || child.empty,
                    expression: "child.open || child.empty",
                  },
                ],
                attrs: {
                  node: child,
                  tree: _vm.tree,
                  "show-move-tree-node": _vm.showMoveTreeNode,
                  "show-info-tree-node": _vm.showInfoTreeNode,
                  "show-menu-tree-node": _vm.showMenuTreeNode,
                  "show-add-tree-node": _vm.showAddTreeNode,
                  "show-edit-tree-node": _vm.showEditTreeNode,
                  "show-remove-tree-node": _vm.showRemoveTreeNode,
                },
                scopedSlots: _vm._u(
                  [
                    _vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
                      return {
                        key: slot,
                        fn: function (scope) {
                          return [_vm._t(slot, null, null, scope)]
                        },
                      }
                    }),
                  ],
                  null,
                  true
                ),
              },
              _vm.$listeners
            ),
            [
              _vm._l(Object.keys(_vm.$slots), function (slot) {
                return _vm._t(slot, null, { slot: slot })
              }),
            ],
            2
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }