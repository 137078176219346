import Vue from "vue";
import Vuex from "vuex";

// import createLogger from 'vuex/dist/logger';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

// Load store modules dynamically.
const requireContext = require.context("./modules", false, /.*\.js$/);
const modules = requireContext
    .keys()
    .map(file => {
        return [file.replace(/(^.\/)|(\.js$)/g, ""), requireContext(file)];
    })
    .reduce((modules, [name, module]) => {
        if (module.namespaced === undefined) {
            module.namespaced = true;
        }

        return { ...modules, [name]: module };
    }, {});

export default new Vuex.Store({
    modules,
    strict: debug
    // plugins: debug ? [createLogger()] : []
});
