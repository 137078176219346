<template>
  <toggle-button
    :value="toggleValue"
    :labels="{checked: $t('states.yes'), unchecked: $t('states.no')}"
    :width="width"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "ToggleCellItem",
  components: {
    ToggleButton
  },
  props: {
    val: {
      type: [String, Number],
      required: true
    },
    width: {
      type: [String, Number],
      default: 50
    }
  },
  computed: {
    toggleValue() {
      return parseInt(this.val) === 1 ? true : false;
    },
  }
};
</script>

<style>
</style>