import axios from "axios";

export default {
    getClients(work_shop_id, { current_page = 1, per_page = 20, data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/clients/${current_page}/${per_page}`,
            {
                params: { ...data },
            }
        );
    },

    getOrderStats(work_shop_id, client_id, year = 0) {
        return axios.get(
            `/api/${work_shop_id}/clients/order-stats/${client_id}/${year}`
        );
    },

    getClientAddresses(
        work_shop_id,
        client_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/clients/address/${client_id}/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },
    getClientAddress(work_shop_id, { clientId, id }) {
        return axios.get(
            `/api/${work_shop_id}/clients/address/${clientId}/show/${id}`
        );
    },
    deleteClientAddress(work_shop_id, { clientId, id }) {
        return axios.delete(
            `/api/${work_shop_id}/clients/address/${clientId}/${id}`
        );
    },

    getClientTypes() {
        return axios.get(`/api/client-types`);
    },

    getClientAdSources() {
        return axios.get(`/api/client-ad-sources`);
    },
    getClient(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/clients/${id}`);
    },
    deleteClient(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/clients/${id}`);
    },
    verifyClient(work_shop_id, { id }) {
        return axios.put(`/api/${work_shop_id}/clients/verify/${id}`);
    },
};
