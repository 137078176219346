export class GetDataRequest {
    /**
     * @constructor
     * @param currentPage
     * @param {number} perPage
     * @param {Array.<DataSearching>} searching
     * @param {Array.<DataSorting>} sorting
     * @param {Array.<DataFiltering>} filtering
     * @param {Array.<DataGrouping>} groupData
     */
    constructor({
        currentPage = 1,
        perPage = 10,
        searching = [],
        sorting = [],
        filtering = [],
        conditional = [],
        group = []
    }) {
        this.current_page = currentPage;
        this.per_page = perPage;
        this.data = {
            searching: [],
            sorting: [],
            filter: [],
            conditional: [],
            group: []
        };
        this.searchingData = searching;
        this.sortingData = sorting;
        this.filterData = filtering;
        this.conditionalData = conditional;
        this.groupData = group;
    }

    /**
     * List of fields for searching
     *
     * @param {Array.<DataSearching>} searching
     */
    set searchingData(searching = []) {
        this.data.searching = searching.map(column => {
            return column.data;
        });
    }

    /**
     * List of fields for filtering
     *
     * @param {Array.<DataFiltering>} filter
     */
    set filterData(filter = []) {
        this.data.filter = filter.map(column => {
            return column.data;
        });
    }

    /**
     * List of fields for group
     *
     * @param {Array.<DataGrouping>} groups
     */
    set groupData(groups = []) {
        this.data.group = groups.map(group => {
            return group.filters.map(filter => {
                return filter.data;
            }).join(',');
        });
    }

    /**
     * List of fields for sorting
     *
     * @param {Array.<DataSorting>} sorting
     */
    set sortingData(sorting = []) {
        this.data.sorting = sorting.map(column => {
            return column.data;
        });
    }

    /**
     * List of fields for conditional filter
     *
     * @param {Array.<DataCondition>} sorting
     */
    set conditionalData(conditional = []) {
        this.data.conditional = conditional.map(column => {
            return column.data;
        });
    }
}

export class DataSorting {
    /**
     *
     * @param {number} order
     * @param {string} key
     * @param {string} direction
     */
    constructor(order, key, direction) {
        this.order = order;
        this.key = key;
        this.direction = direction;
    }

    get data() {
        return `${this.order};${this.key};${this.direction}`;
    }
}

export class DataSearching {
    /**
     *
     * @param {string} key
     * @param {string} value
     * @param {string} [operation=like]
     */
    constructor(key, value, operation = "like") {
        this.key = key;
        this.value = value;
        this.operation = operation;
    }

    get data() {
        return `${this.key};${this.operation};${this.value}`;
    }
}

/**
 * @typedef {"date" | "in" | "notin" | "isnull" | "isnotnull" | "="} FilterOperation
 */

/**
 * @typedef {"and" | "or"} Operator
 */

export class DataFiltering {
    /**
     * @constructor
     * @param {String} key
     * @param {String} value
     * @param {FilterOperation} operation
     * @param {Operator} operator [or|and]
     */
    constructor(key, value, operation = "=", operator = "and") {
        this._key = key;
        this._value = value;
        this._operation = operation;
        this._operator = operator;
    }

    get data() {
        return `${this.key};${this.operation};${this.value};${this.operator}`;
    }

    /**
     * @param {Object} raw
     */
    set showFilterData({ value, operation = "in" }) {
        this._filterValue = value;
        this._filterOperation = operation;
    }

    get key() {
        return this._key;
    }

    /**
     * return {String}
     */
    get value() {
        return this._value;
    }

    get operation() {
        return this._operation;
    }

    get operator() {
        return this._operator;
    }

    get filterValue() {
        return this._filterValue;
    }

    get filterOperation() {
        return this._filterOperation;
    }
}

export class DataGrouping {
    /**
     * @constructor
     * @param {Array.<DataFiltering>} filters
     * @param {Operator} operator [or|and]
     */
    constructor(filters = [], operator = "AND") {
        this.filters = filters;
        this.operator = operator;
    }
}

/**
 * Using for conditional filters
 */
export class DataCondition {
    /**
     * @constructor
     * @param {String} key
     * @param {String} value
     */
    constructor(key, value) {
        this._key = key;
        this._value = value;
    }

    get data() {
        return `${this.key};${this.value}`;
    }

    get key() {
        return this._key;
    }

    /**
     * return {String}
     */
    get value() {
        return this._value;
    }
}
