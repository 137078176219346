<template>
  <draggable
    class="cont pl-0"
    tag="ul"
    v-model="node.children"
    :group="{ name: 'children' }"
    @change="change"
    handle=".handle"
  >
    <!-- <transition-group> -->
    <li
      v-for="child in node.children"
      :key="child.key"
      class="node"
      style="padding-top: 5px"
      :id="child.key"
    >
      <div class="row m-0">
        <div class="col-md-12 pl-0">
          <a href="#" class="mr-1" v-if="showMoveTreeNode">
            <Icon icon="octicon:grabber-16" width="24" height="24" class="handle" />
          </a>
          <slot name="start-line" :child="child"></slot>
          <i
            v-if="!child.empty"
            class="far fa-lg mr-1"
            :class="{
              'fa-folder': !child.empty && !child.open,
              'fa-folder-open': !child.empty && child.open,
            }"
            @click="child.toggleOpen()"
          ></i>
          <span v-else>-</span>

          <slot
            name="tree-text"
            :child="child"
            @click.stop="elementClicked({ id: child.key, data: child.data })"
          >
            <span class="node-text">{{ child.data.name }}</span>
            <span v-if="!child.empty">{{ `(${child.count})` }}</span>
          </slot>

          <span
            class="ml-3"
            @click.stop="addButtonClicked({ id: child.key, data: child.data })"
            v-if="showAddTreeNode"
          >
            <i class="fas fa-plus"></i>
          </span>

          <span data-toggle="dropdown" class="ml-1" v-if="showMenuTreeNode">
            <i class="fas fa-list"></i>
          </span>

          <div
            class="dropdown-menu"
            :id="`dropdown-menu-tree-node-${child.key}`"
            v-if="showMenuTreeNode"
          >
            <slot name="dropdown-menu" :child="child">
              <my-edit-drop-down-menu-item
                @click.stop="
                  editButtonClicked({ id: child.key, data: child.data })
                "
                v-if="showEditTreeNode"
              ></my-edit-drop-down-menu-item>

              <my-info-drop-down-menu-item
                @click.stop="
                  infoButtonClicked({ id: child.key, data: child.daπta })
                "
                v-if="showInfoTreeNode"
              ></my-info-drop-down-menu-item>

              <my-remove-drop-down-menu-item
                @click.stop="
                  removeButtonClicked({ id: child.key, data: child.data })
                "
                v-if="showRemoveTreeNode"
              ></my-remove-drop-down-menu-item>
            </slot>
          </div>
        </div>
      </div>

      <vue-my-tree-node
        v-show="child.open || child.empty"
        :node="child"
        :tree="tree"
        v-on="$listeners"
        :show-move-tree-node="showMoveTreeNode"
        :show-info-tree-node="showInfoTreeNode"
        :show-menu-tree-node="showMenuTreeNode"
        :show-add-tree-node="showAddTreeNode"
        :show-edit-tree-node="showEditTreeNode"
        :show-remove-tree-node="showRemoveTreeNode"
      >
        <!-- Pass on all named slots -->
        <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
        <!-- Pass on all scoped slots -->
        <template
          v-for="slot in Object.keys($scopedSlots)"
          :slot="slot"
          slot-scope="scope"
        >
          <slot :name="slot" v-bind="scope" />
        </template>
      </vue-my-tree-node>
    </li>
    <!-- </transition-group> -->
  </draggable>
</template>

<script>
import { Icon } from "@iconify/vue2";

import { Tree, Node, NodeData } from "./tree-components";
import {
  REMOVE_TREE_NODE_EVENT,
  EDIT_TREE_NODE_EVENT,
  INFO_TREE_NODE_EVENT,
  ADD_TREE_NODE_EVENT,
  CLICK_TREE_NODE_EVENT,
  MOVE_TREE_NODE_EVENT,
} from "./tree-events";

import {
  MyEditDropDownMenuItem,
  MyRemoveDropDownMenuItem,
  MyInfoDropDownMenuItem,
} from "../menu";

import draggable from "vuedraggable";
import { deepCopy } from "../../utils/functions";

export default {
  name: "VueMyTreeNode",
  inheritAttrs: false,
  components: {
    draggable,
    MyEditDropDownMenuItem,
    MyRemoveDropDownMenuItem,
    MyInfoDropDownMenuItem,
    Icon,
  },
  props: {
    node: {
      type: Node,
      required: true,
    },
    tree: {
      type: Tree,
      required: true,
    },
    showMoveTreeNode: {
      type: Boolean,
      default: true,
    },
    showInfoTreeNode: {
      type: Boolean,
      default: true,
    },
    showMenuTreeNode: {
      type: Boolean,
      default: true,
    },
    showAddTreeNode: {
      type: Boolean,
      default: true,
    },
    showEditTreeNode: {
      type: Boolean,
      default: true,
    },
    showRemoveTreeNode: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    removeButtonClicked(args) {
      this.$emit(
        REMOVE_TREE_NODE_EVENT,
        new NodeData(args.id, deepCopy(args.data))
      );
    },
    editButtonClicked(args) {
      this.$emit(
        EDIT_TREE_NODE_EVENT,
        new NodeData(args.id, deepCopy(args.data))
      );
    },
    infoButtonClicked(args) {
      this.$emit(
        INFO_TREE_NODE_EVENT,
        new NodeData(args.id, deepCopy(args.data))
      );
    },
    addButtonClicked(args) {
      this.$emit(
        ADD_TREE_NODE_EVENT,
        new NodeData(args.id, deepCopy(args.data))
      );
    },
    elementClicked(args) {
      this.$emit(
        CLICK_TREE_NODE_EVENT,
        new NodeData(args.id, deepCopy(args.data))
      );
    },
    change(event) {
      //parent changed
      if (event.added) {
        let elementId = event.added.element.key;
        let data = new NodeData(elementId, deepCopy(event.added.element.data));
        data.newIndex = event.added.newIndex;

        const parent = this.tree.parent({
          ofKey: elementId,
          traversal: this.tree.traverseBFS,
        });

        if (parent !== null && parent.key > 0) {
          data.parentId = parent.key;
        }
        this.$emit(MOVE_TREE_NODE_EVENT, data);
      }

      //parent the same
      if (event.moved) {
        let elementId = event.moved.element.key;

        let data = new NodeData(elementId, deepCopy(event.moved.element.data));
        data.newIndex = event.moved.newIndex;
        data.oldIndex = event.moved.oldIndex;

        const parent = this.tree.parent({
          ofKey: elementId,
          traversal: this.tree.traverseBFS,
        });

        if (parent !== null && parent.key > 0) {
          data.parentId = parent.key;
        }

        this.$emit(MOVE_TREE_NODE_EVENT, data);
      }
    },
  },
  computed: {
    showToolColumn() {
      return (
        this.showAddTreeNode ||
        this.showInfoTreeNode ||
        this.showRemoveTreeNode ||
        this.showEditTreeNode
      );
    },
  },
};
</script>

<style scoped>
.node {
  padding-left: 10px;
  margin-bottom: 2px;
}

.folder {
  color: #903307;
}

.node-text {
  font-size: 1.1rem;
}

.cont {
  padding-inline-start: 10px;
}

.cont li {
  list-style-type: none;
}
</style>