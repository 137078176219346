<template>
  <div class="form-group">
    <label :for="id">{{ title }}</label>

    <div class="row">
      <div
        :class="{ 'col-sm-6': showStartDate && showEndDate, 'col-sm-9': showStartDate && !showEndDate, 'col-sm-12': !showStartDate && !showEndDate }"
      >
        <v-select
          :id="id"
          v-model="selected"
          :options="sortOptions"
          :placeholder="$t('fields.date_range.values.any_date')"
          class="mb-1"
        ></v-select>
      </div>
      <div class="col-sm-3" v-if="showStartDate">
        <date-picker
          v-model="startDate"
          :popover="{ visibility: 'click' }"
          :locale="locale"
          :class="['date_range']"
        ></date-picker>
      </div>
      <div class="col-sm-3" v-if="showEndDate">
        <date-picker
          v-model="endDate"
          :popover="{ visibility: 'click' }"
          :locale="locale"
          :class="['date_range']"
        ></date-picker>
      </div>
      <has-error :form="form" :field="secondDateName" v-if="showStartDate" />
      <has-error :form="form" :field="firstDateName" />
    </div>
  </div>
</template>

<script>
import Form, { HasError } from "../index";
import vSelect from "vue-select";

import { DatePicker } from "v-calendar";

import { deepCopy } from "../../../utils/functions";
import { mapGetters } from "vuex";

export default {
  name: "DateRangeField",
  components: {
    HasError,
    DatePicker,
    vSelect
  },
  props: {
    form: {
      type: Form,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    firstDateName: {
      type: String,
      required: true
    },
    secondDateName: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      startDate: this.isDate(this.form[this.firstDateName])
        ? this.form[this.firstDateName]
        : null,
      endDate: this.isDate(this.form[this.secondDateName])
        ? this.form[this.secondDateName]
        : null,
      selected: null,
      sortedOptions: [],
      options: [
        {
          id: 1,
          label: this.$t("fields.date_range.values.exact_date"),
          sortById: 1
        },
        {
          id: 2,
          label: this.$t("fields.date_range.values.custom_range"),
          sortById: 2
        },
        {
          id: 3,
          label: this.$t("fields.date_range.values.today"),
          sortById: 0
        }
      ]
    };
  },
  watch: {
    startDate() {
      this.form[this.firstDateName] = this.startDate;
    },
    endDate() {
      this.form[this.secondDateName] = this.endDate;
    },
    selected() {
      this.startDate = null;
      this.endDate = null;
      if (this.selected !== null) {
        switch (this.selected.id) {
          case 3: //today
            this.startDate = new Date();
            break;
        }
      }
    }
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    sortOptions() {
      return deepCopy(this.options).sort((a, b) =>
        a.sortById > b.sortById ? 1 : -1
      );
    },
    showStartDate() {
      return (
        this.selected !== null &&
        (this.selected.id === 1 || this.selected.id === 2)
      );
    },
    showEndDate() {
      return this.selected !== null && this.selected.id === 2;
    },
    anyDateText() {
      return this.$t("date_range.values.any_date");
    }
  },
  methods: {
    isDate(value) {
      return value !== undefined && value !== null && value instanceof Date;
    },
    reset() {
      this.startDate = null;
      this.endDate = null;
      this.selected = null;
    }
  }
};
</script>

<style>
.date_range > input {
  padding-bottom: 5px !important;
  margin-bottom: 3px;
}
</style>