import { Form } from "../../../components/form";
import { parseDate, isset } from "../../../utils/functions";

export default class ClientDetail extends Form {
    parse(data) {
        this.id = data.id;
        this.name = data.name;
        this.surname = data.surname;
        this.birthday = isset(data.birthday) ? parseDate(data.birthday) : null;
        this.email = data.email;
        this.phone = data.phone;
        this.notes = data.notes;
        this.verified_at = data.verified_at;
        this.client_type = data.client_type?.name ?? "";
        this.client_ad_source = data.client_ad_source?.name ?? "";
        this.discount_card = data.discount_card?.card_number ?? "";
        this.external_code = data.external_code;
        this.country = data.country;
        this.commercial_name = data.commercial_name;
        this.sector = data.sector;
        this.payment_form = data.payment_form;

        return this;
    }

    get isNotVerified() {
        return this.verified_at === null;
    }
}
