import axios from "axios";
import Errors from "./Errors";
import { deepCopy, isset } from "../../utils/functions";

class Form {
    /**
     * Create a new form instance.
     *
     * @param {Object} data
     */
    constructor(data = {}) {
        this.busy = false;
        this.successful = false;
        this.errors = new Errors();
        this.originalData = deepCopy(data);
        this.hash = null;

        Object.assign(this, data);
    }

    setHash(hash, notifier) {
        this.hash = hash;
        this.notifier = notifier;
    }

    /**
     * Fill form data.
     *
     * @param {Object} data
     */
    fill(data) {
        this.keys().forEach((key) => {
            this[key] = data[key];
        });
    }

    /**
     * Get the form data.
     *
     * @return {Object}
     */
    data() {
        return this.keys().reduce((data, key) => {
            /** for this[key].id for v-select element */
            let value = this[key];
            if (value !== null && value !== undefined) {
                if (
                    Array.isArray(value) &&
                    value[0] !== null &&
                    !(value[0] instanceof Date)
                ) {
                    let values = [];
                    for (let el of value) {
                        if (el instanceof Object && isset(el.id))
                            values.push(el.id);
                        else values.push(el);
                    }
                    value = values;
                } else if (
                    Array.isArray(value) &&
                    value[0] instanceof Date &&
                    value[1] instanceof Date
                ) {
                    //for date range
                    value[0] = this.formatDate(value[0]);
                    value[1] = this.formatDate(value[1]);
                } else if (value.id !== undefined) {
                    value = this[key].id;
                } else if (value instanceof Date) {
                    value = this.formatDate(value);
                }
            }

            return { ...data, [key]: value };
        }, {});
    }

    formatDate(value) {
        return (
            value.getFullYear() +
            "-" +
            this.appendLeadingZeroes(value.getMonth() + 1) +
            "-" +
            this.appendLeadingZeroes(value.getDate()) +
            " " +
            this.appendLeadingZeroes(value.getHours()) +
            ":" +
            this.appendLeadingZeroes(value.getMinutes()) +
            ":" +
            this.appendLeadingZeroes(value.getSeconds())
        );
    }

    appendLeadingZeroes(n) {
        if (n <= 9) {
            return "0" + n;
        }
        return n;
    }

    /**
     * Get the form raw data.
     *
     * @return {Object}
     */
    rawData() {
        return this.keys().reduce((data, key) => {
            return { ...data, [key]: this[key] };
        }, {});
    }

    /**
     * Get the form data keys.
     *
     * @return {Array}
     */
    keys() {
        return Object.keys(this).filter((key) => !Form.ignore.includes(key));
    }

    /**
     * Start processing the form.
     */
    startProcessing() {
        this.errors.clear();
        this.busy = true;
        this.successful = false;
    }

    /**
     * Finish processing the form.
     */
    finishProcessing() {
        this.busy = false;
        this.successful = true;
    }

    /**
     * Clear the form errors.
     */
    clear() {
        this.errors.clear();
        this.successful = false;
    }

    /**
     * Reset the form fields.
     */
    reset() {
        Object.keys(this)
            .filter((key) => !Form.ignore.includes(key))
            .forEach((key) => {
                this[key] = deepCopy(this.originalData[key]);
            });
        this.clear();
    }

    /**
     * Submit the form via a GET request.
     *
     * @param  {String} url
     * @param  {Object} sendData data to send
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    get(url, sendData = {}, config = {}) {
        return this.submit("get", url, sendData, config);
    }

    /**
     * Submit the form via a POST request.
     *
     * @param  {String} url
     * @param  {Object} sendData data to send
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    post(url, sendData = {}, config = {}) {
        return this.submit("post", url, sendData, config);
    }

    /**
     * Submit the form via a PATCH request.
     *
     * @param  {String} url
     * @param  {Object} sendData data to send
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    patch(url, sendData = {}, config = {}) {
        return this.submit("patch", url, sendData, config);
    }

    /**
     * Submit the form via a PUT request.
     *
     * @param  {String} url
     * @param  {Object} sendData data to send
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    put(url, sendData = {}, config = {}) {
        return this.submit("put", url, sendData, config);
    }

    /**
     * Submit the form via a DELETE request.
     *
     * @param  {String} url
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    delete(url, config = {}) {
        return this.submit("delete", url, config);
    }

    /**
     * Submit the form data via an HTTP request.
     *
     * @param  {String} method (get, post, patch, put)
     * @param  {String} url
     * @param  {Object} sendData data to send
     * @param  {Object} config (axios config)
     * @return {Promise}
     */
    submit(method, url, sendData = {}, config = {}) {
        this.startProcessing();

        const data = method === "get" ? { params: sendData } : sendData;

        return new Promise((resolve, reject) => {
            (Form.axios || axios)
                .request({ url: this.route(url), method, data, ...config })
                .then((response) => {
                    this.finishProcessing();

                    resolve(response);
                })
                .catch((error) => {
                    this.busy = false;

                    if (error.response) {
                        this.errors.set(this.extractErrors(error.response));
                    }

                    reject(error);
                });
        });
    }

    /**
     * Extract the errors from the response object.
     *
     * @param  {Object} response
     * @return {Object}
     */
    extractErrors(response) {
        if (!response.data || typeof response.data !== "object") {
            return { error: Form.errorMessage };
        }

        if (response.data.errors) {
            if (isset(response.data.errors.hash) && this.notifier) {
                this.notifier();
            }
            return { ...response.data.errors };
        }

        if (response.data.message) {
            return { error: response.data.message };
        }

        return { ...response.data };
    }

    /**
     * Get a named route.
     *
     * @param  {String} name
     * @return {Object} parameters
     * @return {String}
     */
    route(name, parameters = {}) {
        let url = name;

        if (Object.prototype.hasOwnProperty.call(Form.routes, name)) {
            url = decodeURI(Form.routes[name]);
        }

        if (typeof parameters !== "object") {
            parameters = { id: parameters };
        }

        Object.keys(parameters).forEach((key) => {
            url = url.replace(`{${key}}`, parameters[key]);
        });

        return url;
    }

    /**
     * Clear errors on keydown.
     *
     * @param {KeyboardEvent} event
     */
    onKeydown(event) {
        if (event.target.name) {
            this.errors.clear(event.target.name);
        }
    }
}

Form.routes = {};
Form.errorMessage = "Something went wrong. Please try again.";
Form.ignore = ["busy", "successful", "errors", "originalData"];

export default Form;
