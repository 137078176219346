<template>
  <div id="app">
    <loading ref="loading" />

    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
import Loading from "./Loading";

// Load layout components dynamically.
const requireContext = require.context("~/layouts", false, /.*\.vue$/);

const layouts = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});

export default {
  el: "#app",

  components: {
    Loading
  },

  data: () => ({
    layout: null,
    defaultLayout: "BasicLayout"
  }),

  // metaInfo () {
  //     const { appName } = window.config
  //
  //     return {
  //         title: appName,
  //         titleTemplate: `%s · ${appName}`
  //     }
  // },

  mounted() {
    this.$loading = this.$refs.loading;
    this.$el.addEventListener("click", this.onClick);
  },
  beforeDestroy: function() {
    this.$el.removeEventListener("click", this.onClick);
    // document.removeEventListener('click', this.onClick)
  },
  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout;
      }

      this.layout = layouts[layout];
    },
    onClick: function(ev) {
      //click handler
    }
  }
};
</script>
