<template>
  <div class="badge badge-warning">{{ operatorValue }}</div>
</template>

<script>
export default {
  name: "VueMyTableFilterOperator",
  props: {
    operator: {
      type: String,
      default: "and"
    }
  },
  computed: {
    operatorValue() {
      return this.$t(`filters.operator.${this.operator.toLowerCase()}`);
    }
  }
};
</script>

<style>
</style>