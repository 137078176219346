<template>
  <div class="form-group">
    <label>
      {{ $t("pages.orders.service") }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <div :ref="categoriesIds"></div>

    <select-field
      id="manage-service-name"
      :name="name"
      :form="form"
      label="name"
      :options="items"
      @input="onChangeItem"
      v-if="showItems"
    >
      <template #no-options>{{ $t("fields.no_options") }}</template>
    </select-field>

    <has-error :form="form" :field="name" />
  </div>
</template>

<script>
import { isset, deepCopy } from "../../../utils/functions";

import Form, { HasError, SelectField } from "../../../components/form";

import apiTags from "../../../api/tags";

import { GetDataRequest } from "../../../components/table";

import { mapGetters } from "vuex";
import { Tree } from "../../../components/tree";

import Vue from "vue";
import { ON_CHANGE_ORDER_ITEM_EVENT } from "./index";
import CategorySelectField from "./CategorySelectField";

const categorySelectFieldClass = Vue.extend(CategorySelectField);

export default {
  props: {
    form: {
      type: Form,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    typeTag: {
      type: [Number, String],
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HasError,
    SelectField,
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    ...mapGetters("auth", ["currentWorkShopId"]),
    optionLabel() {
      return `${this.locale}name`;
    },
    categoriesIds() {
      return `${this.id}-categories`;
    },
  },
  data() {
    return {
      categoryData: [],
      categoryInstances: [],
      categoryTree: new Tree({ key: 0, data: { name: "Root" } }),
      items: [],
      showItems: false,
    };
  },
  methods: {
    async build(child, value = null) {
      //if categories exists show category filters
      if (!child.empty) {
        let index = this.categoryInstances.length;
        let category = new categorySelectFieldClass({
          propsData: {
            label: "name",
            options: child.children,
            val: value,
          },
        }).$mount();
        this.categoryInstances.push(category);
        category.$on("input", (val) => this.onChangedCategory(val, index));
        this.$refs[this.categoriesIds].appendChild(category.$el);
      } else {
        //should load items of selected category
        this.items = await this.loadItems(child.key);
        this.showItems = true;
      }
    },
    parseCategoryTree(categoryData) {
      this.categoryTree.reset();
      this.categoryTree.parse({
        data: categoryData,
        idKey: "id",
        parentKey: "tag_parent",
        sortKey: "sorting",
      });
    },
    async onChangedCategory(child, index) {
      this.removeCategoriesInstance(index);
      if (isset(child)) {
        this.build(child);
      }
    },
    removeCategoriesInstance(index, resetItem = true) {
      let instancesToDestroy = this.categoryInstances.slice(
        index + 1,
        this.categoryInstances.length
      );

      instancesToDestroy.forEach((instance) => {
        instance.$off("input");
        instance.show = false;
      });

      this.categoryInstances =
        index === 0
          ? this.categoryInstances.slice(0, 1)
          : this.categoryInstances.slice(0, index + 1);

      this.cleanItemInstance(resetItem);
    },
    cleanItemInstance(resetItem) {
      this.showItems = false;
      this.items = [];
      //when edit, should save init value
      if (resetItem) {
        this.form[this.name] = null;
      }
    },
    async init(item) {
      //reset categories, if edit dont reset item value
      this.removeCategoriesInstance(-1, !isset(item));
      this.categoryData = await this.loadTags();
      //convert tags to tree
      this.parseCategoryTree(this.categoryData);

      //item is exists, need to build all categories for this item
      if (isset(item)) {
        //should build categories for items
        let lastCategory = this.categoryTree.find({
          ofKey: item.tag.id,
          traversal: this.categoryTree.traverseBFS,
        });
        let categories = [];
        let node = lastCategory;
        while (isset(node.parent)) {
          categories.unshift({ category: node.parent, value: node });
          node = node.parent;
        }
        categories.forEach((elem) => {
          this.build(elem.category, elem.value);
        });

        //load all items
        this.items = await this.loadItems(lastCategory.key);
        this.showItems = true;
      } else {
        //build first category line
        this.build(this.categoryTree.root);
      }
    },
    async loadTags() {
      const request = new GetDataRequest({
        perPage: 1000,
      });
      const { data } = await apiTags.getTags(this.currentWorkShopId, {
        ...request,
        tag_type: this.typeTag,
      });
      return data.data;
    },
    async loadItems(category_id) {
      console.warn(
        `Methods loadItems is abstract. Should be redefined, category_id: ${category_id}`
      );
    },
    onChangeItem(item) {
      this.$emit(ON_CHANGE_ORDER_ITEM_EVENT, deepCopy(item));
    },
  },
};
</script>

<style>
</style>