<template>
  <a class="dropdown-item pl-0" v-bind="$attrs" v-on="$listeners">
    <i class="nav-icon fas fa-trash-alt p2"></i>
    {{ text }}
  </a>
</template>

<script>
export default {
  name: "MyRemoveDropDownMenuItem",
  props: {
    title: {
      type: [String],
      default: ""
    }
  },
  computed: {
    text() {
      return this.title.length > 0 ? this.title : this.$t("actions.remove");
    }
  }
};
</script>

<style>
.p2 {
  padding-left: 4px !important;
  padding-right: 4px;
}
</style>