import { Form } from "../../../components/form";

export default class ClientAddress extends Form {
    parse(data) {
        this.id = data.id;
        this.address = data.address;
        this.name = data.name;
        this.phonePrimary = data.phonePrimary;
        this.phoneSecondary = data.phoneSecondary;
        this.metroStation = data.metroStation;

        return this;
    }
}
