/* eslint-disable no-irregular-whitespace */
console.log(
    `
    ▀▄   ▄▀
   ▄█▀███▀█▄
  █▀███████▀█
  █ █▀▀▀▀▀█ █
     ▀▀ ▀▀
┌────────────────────────────────────────────────────────────────────────┐
│ Found a security issue? Please report it to app.taskwon@gmail.com  │
├────────────────────────────────────────────────────────────────────────│
│ We're always looking for excellent developers!                         │
└────────────────────────────────────────────────────────────────────────┘
`
);
