import axios from "axios";

export default {
    getOrders(work_shop_id, { current_page = 1, per_page = 20, data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/orders/${current_page}/${per_page}`,
            {
                params: { ...data }
            }
        );
    },

    getOrdersSumStats(work_shop_id, date_group_by, { data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/orders/orders-sum-stats/${date_group_by}`,
            {
                params: { ...data }
            }
        );
    },

    getOrdersForemenSumStats(work_shop_id, date_group_by, { data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/orders/orders-sum-foremen-stats/${date_group_by}`,
            { params: { ...data } }
        );
    },

    getOrdersQuantityCourierStats(
        work_shop_id,
        courier_id,
        date_group_by,
        { data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/orders/orders-quantity-courier-stats/${courier_id}/${date_group_by}`,
            { params: { ...data } }
        );
    },

    getOrder(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/orders/${id}`);
    },

    getOrderStatusHistories(work_shop_id, id) {
        return axios.get(`/api/${work_shop_id}/orders/status-history/${id}`);
    },

    getTypeDeliveries(work_shop_id) {
        return axios.get(`/api/${work_shop_id}/orders/type-deliveries`);
    },

    getOrderLinesByForeman(work_shop_id, id) {
        return axios.get(`/api/${work_shop_id}/orders/lines-by-foreman/${id}`);
    },

    transferLinesToForeman(work_shop_id, order_id, data) {
        return axios.put(
            `/api/${work_shop_id}/orders/transfer-lines-to-foreman/${order_id}`,
            data
        );
    },

    deleteOrder(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/orders/${id}`);
    },

    getOrderStatuses(
        work_shop_id,
        { current_page = 1, per_page = 20, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/order-statuses/${current_page}/${per_page}`,
            {
                params: { ...data }
            }
        );
    },

    getOrderStatus(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/order-statuses/${id}`);
    },

    orderStatusUpdateSorting(work_shop_id, status_id, sorting) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/sorting/${status_id}`,
            {
                sorting
            }
        );
    },

    orderStatusSetDefault(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/default/${status_id}`
        );
    },

    orderStatusSetDraft(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/draft/${status_id}`
        );
    },

    orderStatusSetCanceled(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/canceled/${status_id}`
        );
    },

    orderStatusSetAwaitingCompletion(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/awaiting-completion/${status_id}`
        );
    },

    orderStatusSetWorking(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/working/${status_id}`
        );
    },

    orderStatusSetCompleted(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/completed/${status_id}`
        );
    },

    orderStatusSetHandedOverDelivery(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/handed-over-delivery/${status_id}`
        );
    },

    orderStatusSetDeliveryOnTheWay(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/delivery-on-the-way/${status_id}`
        );
    },

    orderStatusSetDelivered(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/delivered/${status_id}`
        );
    },

    orderStatusSetFinal(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/final/${status_id}`
        );
    },

    orderStatusSetNew(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/new/${status_id}`
        );
    },

    orderStatusSetAwaitingCourier(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/awaiting-courier/${status_id}`
        );
    },

    orderStatusSetCollectOnTheWay(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/collect-on-the-way/${status_id}`
        );
    },

    orderStatusSetCourierHasOrder(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/courier-has-order/${status_id}`
        );
    },

    orderStatusToggleActive(work_shop_id, status_id) {
        return axios.put(
            `/api/${work_shop_id}/order-statuses/active/${status_id}`
        );
    },

    deleteOrderStatus(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/order-statuses/${id}`);
    }
};
