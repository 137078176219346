var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "icheck-primary" }, [
    _c("input", {
      attrs: {
        id: _vm.id || _vm.name,
        name: _vm.id || _vm.name,
        type: "checkbox",
        disabled: _vm.disabled,
      },
      domProps: { checked: _vm.internalValue },
      on: { click: _vm.handleClick },
    }),
    _vm._v(" "),
    _c("label", { attrs: { for: _vm.id || _vm.name } }, [_vm._t("default")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }