var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.title.length > 0
        ? _c("label", { class: _vm.labelClass, attrs: { for: _vm.id } }, [
            _vm._v(_vm._s(_vm.title) + " "),
            _vm.required
              ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "custom-file" }, [
        _c(
          "input",
          _vm._g(
            _vm._b(
              {
                ref: "file",
                staticClass: "custom-file-input",
                attrs: { type: "file", id: _vm.id },
                on: {
                  change: function ($event) {
                    return _vm.handleSelectedFile()
                  },
                },
              },
              "input",
              _vm.$attrs,
              false
            ),
            _vm.$listeners
          )
        ),
        _vm._v(" "),
        _c(
          "label",
          {
            staticClass: "custom-file-label",
            attrs: { for: _vm.id + "_file" },
          },
          [_vm._v(_vm._s(_vm.$t("fields.choose_file")))]
        ),
      ]),
      _vm._v(" "),
      _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }