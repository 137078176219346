import {
    isset,
    deepCopy,
    parseDate,
    formatDateTime,
} from "../../../utils/functions";

import {
    DISCOUNT_TYPE_FIX_SUM,
    DISCOUNT_TYPE_FIX_SUM_BY_ONE,
    DISCOUNT_TYPE_PERCENT,
} from "../../constants";
import Form from "../../../components/form";
import Vue from "vue";

export class Order extends Form {
    constructor() {
        super();

        this.resetSummary();
        this.serviceLines = [];
        this.articleLines = [];

        this.id = null;
        this.createdAt = null;
        this.orderStatus = null;
        this.orderDate = new Date();
        this.estimateTimeTotal = 0;
        this.typeDelivery = null;
        this.notes = null;
        this.foreman = null;
        this.foremen = []; //using in table representation, when have a few foreman by order
        this.courierDelivery = null;
        this.courierCollect = null;
        this.client = null;
        this.clientAddress = null;
        this.deliveryDate = null;
        this.foremanByLine = false;

        this.WorkShopId = null;
        this.totalArticle = null;
        this.totalArticleDiscount = null;
        this.totalArticleWithDiscount = null;
        this.totalService = null;
        this.totalServiceDiscount = null;
        this.totalServiceWithDiscount = null;
        this.totalArticleWithDiscount = null;
        this.total = 0;
        this.totalDiscount = 0;
        this.totalWithDiscount = 0;
    }

    parse(data) {
        this.id = data.id;
        this.createdAt = parseDate(data.created_at);
        this.foremanByLine = data.foreman_by_line;
        this.estimateTimeTotal = data.estimate_time_total;

        this.orderStatus = new OrderStatus({
            id: data.order_status.id,
            runame: data.order_status.runame,
            enname: data.order_status.enname,
            color: data.order_status.color,
            text_color: data.order_status.text_color,
            status_type: data.order_status.status_type,
        });
        this.orderDate = parseDate(data.order_date);

        this.typeDelivery = new TypeDelivery({
            id: data.type_delivery.id,
            runame: data.type_delivery.runame,
            enname: data.type_delivery.enname,
        });
        this.notes = data.notes;

        if (isset(data.foreman)) {
            this.foreman = new Foreman({
                id: data.foreman.id,
                name: data.foreman.name,
                avatar: data.foreman.avatar,
            });
        }

        //can be a few foreman for order
        if (isset(data.foremen) && data.foremen.length > 0) {
            this.foreman = new Foreman({
                id: data.foremen[0].id,
                name: data.foremen[0].name,
                avatar: data.foremen[0].avatar,
            });

            this.foremen = data.foremen.map(
                (x) =>
                    new Foreman({
                        id: x.id,
                        name: x.name,
                        avatar: x.avatar,
                        status: x.status,
                    })
            );
        }

        this.client = new Client({
            id: data.client.id,
            name: data.client.name,
            verified_at: data.client.verified_at,
        });
        this.deliveryDate = parseDate(data.delivery_date);

        this.courierDelivery = isset(data.courier_delivery)
            ? new Courier({
                  id: data.courier_delivery.id,
                  name: data.courier_delivery.name,
              })
            : null;

        this.courierCollect = isset(data.courier_collect)
            ? new Courier({
                  id: data.courier_collect.id,
                  name: data.courier_collect.name,
              })
            : null;

        this.clientAddress = isset(data.client_address)
            ? new ClientAddress({
                  id: data.client_address.id,
                  name: data.client_address.name,
                  phone_primary: data.client_address.phonePrimary,
                  phone_secondary: data.client_address.phoneSecondary,
                  address: data.client_address.address,
                  metro_station: isset(data.client_address.metroStation)
                      ? new MetroStation(
                            data.client_address.metroStation.id,
                            data.client_address.metroStation.name
                        )
                      : null,
              })
            : null;

        this.serviceLines = isset(data.service_lines) ? data.service_lines : [];
        this.articleLines = isset(data.article_lines) ? data.article_lines : [];

        this.WorkShopId = isset(data.work_shop_id) ? data.work_shop_id : null;
        this.totalArticle = isset(data.total_article)
            ? data.total_article
            : null;
        this.totalArticleDiscount = isset(data.total_article_discount)
            ? data.total_article_discount
            : null;
        this.totalArticleWithDiscount = isset(data.total_article_with_discount)
            ? data.total_article_with_discount
            : null;
        this.totalService = isset(data.total_service)
            ? data.total_service
            : null;
        this.totalServiceDiscount = isset(data.total_service_discount)
            ? data.total_service_discount
            : null;
        this.totalServiceWithDiscount = isset(data.total_service_with_discount)
            ? data.total_service_with_discount
            : null;

        this.total = isset(data.total) ? data.total : null;
        this.totalDiscount = isset(data.total_discount)
            ? data.total_discount
            : null;
        this.totalWithDiscount = isset(data.total_with_discount)
            ? data.total_with_discount
            : null;

        this.summary();
        return this;
    }

    get serviceLines() {
        return this._serviceLines;
    }

    getForemanById(foreman_id) {
        for (let orderForeman of this.foremen) {
            if (orderForeman.id === foreman_id) return orderForeman;
        }
        return null;
    }

    set serviceLines(lines) {
        this._serviceLines = [];
        for (let line of lines) {
            this._serviceLines.push(
                new ServiceLine({
                    id: line.id,
                    service: deepCopy(line.service),
                    foreman: deepCopy(line.foreman),
                    is_completed: line.is_completed,
                    notes: line.notes,
                    price: line.price,
                    type_checking: deepCopy(line.type_checking),
                    quantity: line.quantity,
                    discount: line.discount,
                    discount_type: deepCopy(line.discount_type),
                    estimate_time: isset(line.estimate_time)
                        ? parseDate(line.estimate_time)
                        : null,
                    estimate_time_total: line.estimate_time_total,
                    total: line.total,
                    total_discount: line.total_discount,
                    total_with_discount: line.total_with_discount,
                })
            );
        }
    }

    updateOrCreateService(line) {
        const discount = line.discount > 0 ? line.discount : 0;
        let index = _.findIndex(this.serviceLines, (service) => {
            return parseInt(service.id) === parseInt(line.id);
        });

        const total = line.price * line.quantity;

        const total_discount = this.calcDiscount(
            line.quantity,
            line.discount_type,
            total,
            discount
        );
        const total_with_discount = total - total_discount;
        let estimateTimeTotal = isset(line.estimate_time)
            ? line.estimate_time.getHours() * 3600 +
              line.estimate_time.getMinutes() * 60
            : 0;
        const serviceLine = new ServiceLine({
            id: line.id,
            service: line.service,
            notes: line.notes,
            price: line.price,
            foreman: line.foreman,
            type_checking: line.type_checking,
            quantity: line.quantity,
            discount: line.discount,
            discount_type: line.discount_type,
            estimate_time: line.estimate_time,
            estimate_time_total: estimateTimeTotal,
            total: total,
            total_discount: total_discount,
            total_with_discount: total_with_discount,
        });
        //update line
        if (index !== -1) {
            Vue.set(this.serviceLines, index, serviceLine);
        } else {
            //create a new one
            this._serviceLines.push(serviceLine);
        }
        this.summary();
    }

    updateOrCreateArticle(line) {
        const discount = line.discount > 0 ? line.discount : 0;
        let index = _.findIndex(this.articleLines, (article) => {
            return parseInt(article.id) === parseInt(line.id);
        });

        const total = line.price * line.quantity;
        const total_discount = this.calcDiscount(
            line.quantity,
            line.discount_type,
            total,
            discount
        );
        const total_with_discount = total - total_discount;

        const articleLine = new ArticleLine({
            id: line.id,
            article: line.article,
            notes: line.notes,
            price: line.price,
            quantity: line.quantity,
            discount: line.discount,
            discount_type: line.discount_type,
            total: total,
            total_discount: total_discount,
            total_with_discount: total_with_discount,
        });
        //update line
        if (index !== -1) {
            Vue.set(this.articleLines, index, articleLine);
        } else {
            this._articleLines.push(articleLine);
        }
        this.summary();
    }

    calcDiscount(quantity, discount_type, total, discount) {
        if (!isset(discount_type)) return 0;

        switch (discount_type.id) {
            case DISCOUNT_TYPE_FIX_SUM:
                return discount;
            case DISCOUNT_TYPE_FIX_SUM_BY_ONE:
                return discount * quantity;
            case DISCOUNT_TYPE_PERCENT:
                return (total * discount) / 100;
            default:
                return 0;
        }
    }

    removeServiceLine(id) {
        let index = this.serviceLines.findIndex(
            (line) => parseInt(line.id) === parseInt(id)
        );
        if (index !== -1) {
            Vue.delete(this.serviceLines, index);
            this.summary();
            return true;
        }
        return false;
    }

    removeArticleLine(id) {
        let index = this.articleLines.findIndex(
            (line) => parseInt(line.id) === parseInt(id)
        );
        if (index !== -1) {
            Vue.delete(this.articleLines, index);
            this.summary();
            return true;
        }
        return false;
    }

    get articleLines() {
        return this._articleLines;
    }

    set articleLines(lines) {
        this._articleLines = [];
        for (let line of lines) {
            this._articleLines.push(
                new ArticleLine({
                    id: line.id,
                    article: line.article,
                    notes: line.notes,
                    price: line.price,
                    quantity: line.quantity,
                    discount: line.discount,
                    discount_type: deepCopy(line.discount_type),
                    total: line.total,
                    total_discount: line.total_discount,
                    total_with_discount: line.total_with_discount,
                })
            );
        }
    }

    resetSummary() {
        this.total = 0;
        this.totalDiscount = 0;
        this.totalWithDiscount = 0;
    }

    summary() {
        if (this.articleLines.length > 0 || this.serviceLines.length > 0)
            this.resetSummary();

        for (let article of this.articleLines) {
            this.total += parseFloat(article.total);
            this.totalDiscount += parseFloat(article.total_discount);
            this.totalWithDiscount += parseFloat(article.total_with_discount);
        }

        for (let service of this.serviceLines) {
            this.total += parseFloat(service.total);
            this.totalDiscount += parseFloat(service.total_discount);
            this.totalWithDiscount += parseFloat(service.total_with_discount);
        }
    }

    data() {
        let data = super.data();
        let request = {
            clientAddress: data.clientAddress,
            orderStatus: data.orderStatus,
            orderDate: data.orderDate,
            typeDelivery: data.typeDelivery,
            deliveryDate: data.deliveryDate,
            client: data.client,
            courierDelivery: data.courierDelivery,
            courierCollect: data.courierCollect,
            foreman: data.foreman,
            foremanByLine: data.foremanByLine,
            notes: data.notes,
            serviceLines: this.serviceLines.map((row) => {
                let line = {
                    service: row.service.id,
                    notes: row.notes,
                    price: row.price,
                    typeChecking: row.type_checking.id,
                    quantity: row.quantity,
                    discount: row.discount,
                    discount_type: isset(row.discount_type)
                        ? row.discount_type.id
                        : null,
                    estimate_time: isset(row.estimate_time)
                        ? formatDateTime(row.estimate_time)
                        : null,
                };
                if (data.foremanByLine) {
                    line["foreman"] = row.foreman.id;
                }

                return line;
            }),
            articleLines: this.articleLines.map((row) => {
                return {
                    article: row.article.id,
                    notes: row.notes,
                    price: row.price,
                    quantity: row.quantity,
                    discount: row.discount,
                    discount_type: isset(row.discount_type)
                        ? row.discount_type.id
                        : null,
                };
            }),
        };

        return request;
    }

    isEmpty() {
        return this.serviceLines.length === 0 && this.articleLines.length === 0;
    }
}

export class OrderStatus {
    /**
     *
     * @param {object} data - status values
     * @param {String} data.id
     * @param {String} data.runame
     * @param {String} data.enname
     * @param {String} data.name
     * @param {String} data.color
     * @param {String} data.text_color
     * @param {Number} data.status_type
     * @param {Number} data.is_active
     */
    constructor({
        id,
        runame,
        enname,
        name,
        color,
        text_color,
        status_type,
        is_active,
    }) {
        this.id = id;
        this.runame = runame;
        this.enname = enname;
        this.name = name;
        this.color = color;
        this.text_color = text_color;
        this.status_type = status_type;
        this.is_active = is_active;
    }

    static parse(data) {
        return new OrderStatus({
            id: data.id,
            runame: data.runame,
            enname: data.enname,
            name: data.name,
            color: data.color,
            text_color: data.text_color,
            status_type: data.status_type,
            is_active: data.is_active,
        });
    }
}

export class OrderStatusHistory {
    /**
     *
     * @param {object} data - status values
     * @param {String} data.id
     * @param {String} data.order_id
     * @param {OrderStatus} data.status
     * @param {User} data.user
     * @param {String} data.created_at
     */
    constructor({ id, order_id, status, user, created_at }) {
        this.id = id;
        this.order_id = order_id;
        this.status = status;
        this.user = user;
        this.created_at = created_at;
    }
}

export class User {
    /**
     *
     * @param {object} data - User
     * @param {String} data.name
     */
    constructor({ id, name }) {
        this.id = id;
        this.name = name;
    }
}

export class TypeDelivery {
    /**
     *
     * @param {object} data - type delivery values
     * @param {String} data.runame
     * @param {String} data.enname
     */
    constructor({ id, runame, enname }) {
        this.id = id;
        this.runame = runame;
        this.enname = enname;
    }
}

export class Foreman {
    /**
     *
     * @param {object} data - foreman data
     * @param {Number} data.id
     * @param {String} data.name
     * @param {String} data.avatar
     * @param {String} data.status
     */
    constructor({ id, name, avatar = "", status = 0 }) {
        this.id = id;
        this.name = name;
        this.avatar = avatar;
        this.status = status;
    }
}

export class Client {
    /**
     *
     * @param {object} data - client data
     * @param {Number} data.id
     * @param {String} data.name
     * @param {String} data.verified_at
     */
    constructor({ id, name, verified_at }) {
        this.id = id;
        this.name = name;
        this.verified_at = verified_at;
    }

    get isNotVerified() {
        return this.verified_at === null;
    }
}

export class ClientAddress {
    /**
     *
     * @param {object} data - client address data
     * @param {Number} data.id
     * @param {String} data.phone_primary
     * @param {String} data.phone_secondary
     * @param {String} data.address
     * @param {MetroStation} data.metro_station
     */
    constructor({
        id,
        name,
        phone_primary,
        phone_secondary,
        address,
        metro_station,
    }) {
        this.id = id;
        this.name = name;
        this.phonePrimary = phone_primary;
        this.phoneSecondary = phone_secondary;
        this.address = address;
        this.metroStation = metro_station;
    }
}

export class Courier {
    /**
     *
     * @param {object} data - courier data
     * @param {Number} data.id
     * @param {String} data.name
     */
    constructor({ id, name }) {
        this.id = id;
        this.name = name;
    }
}

export class MetroStation {
    /**
     *
     * @param {object} data - metro station data
     * @param {Number} data.id
     * @param {String} data.name
     */
    constructor({ id, name }) {
        this.id = id;
        this.name = name;
    }
}

export class Article {
    /**
     *
     * @param {object} data - article
     * @param {Number} data.id
     * @param {String} data.name
     * @param {String} data.code_article
     * @param {String} data.description
     * @param {Number} data.price
     * @param {Number} data.is_active
     * @param {Object} data.tag
     *
     */
    constructor({
        id,
        name,
        code_article,
        description,
        price,
        is_active,
        tag,
    }) {
        this.id = id;
        this.name = name;
        this.code_article = code_article;
        this.description = description;
        this.price = price;
        this.is_active = is_active;
        this.tag = new Tag({ id: tag.id, name: tag.name });
    }
}

export class Service {
    /**
     *
     * @param {object} data service
     * @param {Number} data.id
     * @param {String} data.name
     * @param {String} data.notes
     * @param {String} data.estimate_time
     * @param {String} data.price
     * @param {Object} data.type_checking
     * @param {Number} data.is_active
     * @param {Object} data.tag
     *
     */
    constructor({
        id,
        name,
        notes,
        estimate_time,
        price,
        type_checking,
        is_active,
        tag,
    }) {
        this.id = id;
        this.name = name;
        this.notes = notes;
        this.estimate_time = estimate_time;
        this.price = price;
        this.type_checking = isset(type_checking)
            ? new TypeChecking({
                  id: type_checking.id,
                  name: type_checking.name,
              })
            : null;
        this.is_active = is_active;
        this.tag = new Tag({ id: tag.id, name: tag.name });
    }
}

export class TypeChecking {
    /**
     *
     * @param {object} data - type delivery values
     * @param {String} data.runame
     * @param {String} data.enname
     */
    constructor({ runame, enname }) {
        this.runame = runame;
        this.enname = enname;
    }
}

export class Tag {
    /**
     *
     * @param {object} data category value
     * @param {String} data.id
     * @param {String} data.name
     */
    constructor({ id, name }) {
        this.id = id;
        this.name = name;
    }
}

export class ServiceLine {
    constructor({
        id,
        service,
        notes,
        price,
        type_checking,
        quantity,
        discount,
        discount_type,
        estimate_time,
        estimate_time_total,
        total,
        total_discount,
        total_with_discount,
        foreman,
        is_completed,
    }) {
        this.id = id;
        this.service = new Service(service);
        this.notes = notes;
        this.price = price;
        this.type_checking = type_checking;
        this.quantity = quantity;
        this.discount = discount;
        this.discount_type = discount_type;
        this.estimate_time = estimate_time;
        this.estimate_time_total = estimate_time_total;
        this.total = total;
        this.foreman = isset(foreman) ? new Foreman(foreman) : null;
        this.total_discount = total_discount;
        this.total_with_discount = total_with_discount;
        this.is_completed = is_completed;
    }
}

export class ArticleLine {
    constructor({
        id,
        article,
        notes,
        price,
        quantity,
        discount,
        discount_type,
        total,
        total_discount,
        total_with_discount,
    }) {
        this.id = id;
        this.article = new Article(article);
        this.notes = notes;
        this.price = price;
        this.quantity = quantity;
        this.discount = discount;
        this.discount_type = discount_type;
        this.total = total;
        this.total_discount = total_discount;
        this.total_with_discount = total_with_discount;
    }
}
