const { showBuyMeCoffee, appVersion } = window.config;

// state
export const state = {
    appVersion: appVersion,
    showBuyMeCoffee
};

// getters
export const getters = {
    appVersion: state => state.appVersion,
    showBuyMeCoffee: state => state.showBuyMeCoffee
};
