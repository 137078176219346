<template>
  <div class="form-group">
    <label :for="id" v-if="title.length > 0" :class="labelClass"
      >{{ title }} <span class="text-danger" v-if="required">*</span></label
    >
    <div class="custom-file">
      <input
        type="file"
        class="custom-file-input"
        :id="id"
        v-bind="$attrs"
        v-on="$listeners"
        ref="file"
        @change="handleSelectedFile()"
      />
      <label class="custom-file-label" :for="`${id}_file`">{{
        $t("fields.choose_file")
      }}</label>
    </div>
    <has-error :form="form" :field="name" />
  </div>
</template>

<script>
import Form, { HasError } from "../index";
import bsCustomFileInput from "bs-custom-file-input";

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    form: {
      type: Form,
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    required: {
      type: Boolean,
      default: false,
    },
    labelClass: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
  },
  components: {
    HasError,
  },
  data() {
    return {
      value: "",
    };
  },
  mounted() {
    this.reinit();
  },
  beforeDestroy() {
    bsCustomFileInput.destroy();
  },
  methods: {
    handleSelectedFile() {
      this.form[this.name] = this.$refs.file.files[0];
    },
    reinit() {
      bsCustomFileInput.init();
      $(`label[for=${this.id}_file]`).html("");
    },
    destroy() {
      bsCustomFileInput.destroy();
    },
  },
};
</script>

<style scoped></style>
