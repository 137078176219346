var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.form.errors.any()
    ? _c(
        "div",
        {
          staticClass: "alert alert-danger alert-dismissible",
          attrs: { role: "alert" },
        },
        [
          _vm.dismissible
            ? _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: { click: _vm.dismiss },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._t("default", function () {
            return [
              _vm.form.errors.has("error")
                ? _c("div", {
                    domProps: {
                      innerHTML: _vm._s(_vm.form.errors.get("error")),
                    },
                  })
                : _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } }),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }