var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
        _vm.required
          ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "treeselect",
        _vm._g(
          _vm._b(
            {
              class: { "is-invalid": _vm.form.errors.has(_vm.name) },
              attrs: {
                options: _vm.options,
                normalizer: _vm.normalizer,
                multiple: _vm.multiple,
                placeholder: _vm.placeholder,
              },
              model: {
                value: _vm.form[_vm.name],
                callback: function ($$v) {
                  _vm.$set(_vm.form, _vm.name, $$v)
                },
                expression: "form[name]",
              },
            },
            "treeselect",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }