<template>
  <a class="dropdown-item pl-0" v-bind="$attrs" v-on="$listeners">
    <i class="nav-icon fa fa-trash p2"></i>
    {{ $t('actions.remove') }}
  </a>
</template>

<script>
export default {
  name: "MyRemoveDropDownMenuItem"
};
</script>

<style>
.p2 {
  padding-left: 4px !important;
  padding-right: 4px;
}
</style>