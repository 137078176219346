var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.$t("filters.field")) +
          ' "' +
          _vm._s(_vm.$t(_vm.column.name)) +
          '"\n  '
      ),
      _c("span", { staticClass: "text-bold" }, [
        _vm._v(_vm._s(_vm.$t("filters.operation." + _vm.filterOperation))),
      ]),
      _vm._v(" "),
      _c("span", [_vm._v(_vm._s('"' + _vm.filterValue + '"'))]),
      _vm._v(" "),
      _vm.showOperator
        ? _c("vue-my-table-filter-operator", {
            attrs: { operator: _vm.filter.filterColumn.operator },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }