<template>
  <li class="nav-item dropdown user-menu">
    <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
      <img
        :src="avatar"
        class="user-image img-circle elevation-2"
        alt="User Image"
      />
      <span class="d-none d-md-inline">{{ userName }}</span>
    </a>
    <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
      <!-- User image -->
      <li class="user-header bg-primary">
        <img :src="avatar" class="img-circle elevation-2" alt="User Image" />

        <div>
          {{ userName }}
          <div v-for="role in roles" :key="role.name">
            <small>{{ role.name }}</small>
          </div>
        </div>
      </li>
      <!-- Menu Body -->
      <!--            <li class="user-body">-->
      <!--                <div class="row">-->
      <!--                    <div class="col-4 text-center">-->
      <!--                        <a href="#">Followers</a>-->
      <!--                    </div>-->
      <!--                    <div class="col-4 text-center">-->
      <!--                        <a href="#">Sales</a>-->
      <!--                    </div>-->
      <!--                    <div class="col-4 text-center">-->
      <!--                        <a href="#">Friends</a>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--                &lt;!&ndash; /.row &ndash;&gt;-->
      <!--            </li>-->
      <!-- Menu Footer-->
      <li class="user-footer">
        <a class="btn btn-default btn-flat" @click="whoAmI">
          <i class="fas fa-sync-alt"></i>
        </a>
        <router-link
          class="btn btn-default btn-flat"
          :to="{ name: 'profile', params: { work_shop_id: currentWorkShopId } }"
          >{{ $t("profile.title") }}</router-link
        >
        <a
          href="#"
          class="btn btn-default btn-flat float-right"
          @click.prevent="logout"
          >{{ $t("profile.sign_out") }}</a
        >
      </li>
    </ul>
  </li>
</template>

<script>
import api from "../api/users";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "NavUserMenu",
  methods: {
    async logout() {
      await this.$store.dispatch("auth/logout");
      this.$router.push({ name: "login" });
    },
    async whoAmI() {
      //get info about user with roles and permissions
      const { data: user } = await api.whoAmI();
      this.saveUser({ user, remember: this.remember });
    },
    ...mapActions("auth", ["saveUser"]),
  },
  computed: {
    ...mapGetters("auth", ["currentWorkShopId"]),
    userName() {
      return this.$isset(this.user) ? this.user.name : "";
    },
    ...mapGetters("auth", ["remember", "user", "roles", "avatar"]),
  },
};
</script>

<style scoped>
</style>
