<template>
  <div class="callout callout-info" v-if="filterForShow.length > 0">
    <button type="button" class="close" @click="resetFilters">&times;</button>
    <h5>
      <i class="icon fas fa-search"></i>
      {{ $t("actions.search") }}
    </h5>

    <template v-for="(filter, index) in filterForShow">
      <vue-my-table-date-range-filter
        :column="getColumnByFilterKey(filter.filterKey)"
        :key="`filter_${Date.now() + index}`"
        :filter="filter"
        v-if="isDateRangeField(filter)"
        :show-operator="index + 1 !== filterForShow.length"
      ></vue-my-table-date-range-filter>

      <vue-my-table-list-filter
        :column="getColumnByFilterKey(filter.filterKey)"
        :key="`filter_${Date.now() + index}`"
        :filter="filter"
        v-if="isListField(filter)"
        :show-operator="index + 1 !== filterForShow.length"
      ></vue-my-table-list-filter>
    </template>
  </div>
</template>

<script>
import {
  Column,
  FilterField,
  DateRangeFilterField,
  ListFilterField,
} from "../index";

import { ON_RESET_FILTERS } from "../table-events";

import VueMyTableDateRangeFilter from "./VueMyTableDateRangeFilter.vue";
import VueMyTableListFilter from "./VueMyTableListFilter.vue";

export default {
  components: {
    VueMyTableDateRangeFilter,
    VueMyTableListFilter,
  },
  props: {
    tableColumns: {
      type: Array,
      validator: function (columns) {
        for (let column of columns) {
          if (!(column instanceof Column)) {
            console.error("tableColumns should include only Column instance");
            return false;
          }
        }
        return true;
      },
      required: true,
    },
    filters: {
      type: Array,
      validator: function (filters) {
        for (let filter of filters) {
          if (!(filter instanceof FilterField)) {
            console.error("filters should include only FilterField instance");
            return false;
          }
        }
        return true;
      },
      default() {
        return [];
      },
    },
  },
  computed: {
    filterForShow() {
      return this.filters.filter((filter) => filter.show);
    },
  },
  methods: {
    getColumnByFilterKey(key) {
      return this.tableColumns.find((item) => item.filterKey === key);
    },
    /**
     * @param {FilterField} filter
     */
    isDateRangeField(filter) {
      return filter instanceof DateRangeFilterField;
    },
    isListField(filter) {
      return filter instanceof ListFilterField;
    },
    resetFilters() {
      this.$emit(ON_RESET_FILTERS);
    },
  },
};
</script>

<style>
</style>