export const GET_TREE_DATA = "get-tree-data";

export const CREATE_BUTTON_TREE_COMPONENT_EVENT = "create-button-tree-component-event";
export const ADD_TREE_COMPONENT_EVENT = "add-tree-component-event";
export const REMOVE_TREE_COMPONENT_EVENT = "remove-tree-component-event";
export const INFO_TREE_COMPONENT_EVENT = "info-tree-component-event";
export const EDIT_TREE_COMPONENT_EVENT = "edit-tree-component-event";
export const CLICK_TREE_COMPONENT_EVENT = "click-tree-component-event";
export const MOVE_TREE_COMPONENT_EVENT = "move-tree-component-event";

export const ADD_TREE_NODE_EVENT = "add-tree-node-event";
export const REMOVE_TREE_NODE_EVENT = "remove-tree-node-event";
export const INFO_TREE_NODE_EVENT = "info-tree-node-event";
export const EDIT_TREE_NODE_EVENT = "edit-tree-node-event";
export const CLICK_TREE_NODE_EVENT = "click-tree-node-event";
export const MOVE_TREE_NODE_EVENT = "move-tree-node-event";