var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-panel mt-3 pb-3 mb-3 d-flex" }, [
    _c("div", { staticClass: "image" }, [
      _c("img", {
        staticClass: "img-circle elevation-2",
        attrs: { src: _vm.avatar, alt: "User Image" },
      }),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "info" },
      [
        _c(
          "router-link",
          {
            staticClass: "d-block",
            attrs: {
              to: {
                name: "profile",
                params: { work_shop_id: _vm.currentWorkShopId },
              },
            },
          },
          [_vm._v(_vm._s(_vm.userName))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }