var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.title
      ? _c("label", [
          _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
          _vm.required
            ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
            : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "editor", class: { "editor-border": _vm.showBorder } },
      [
        _vm.editable
          ? _c("div", [
              _c("div", { staticClass: "menubar" }, [
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("bold") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleBold().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-bold" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("italic") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleItalic().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-italic" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("strike") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleStrike().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-strikethrough" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("underline") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleUnderline().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-underline" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("link") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.setLink.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-link" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("code") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleCode().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-code" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().setHardBreak().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-paragraph" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: {
                      "is-active": _vm.editor.isActive("heading", { level: 1 }),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor
                          .chain()
                          .focus()
                          .toggleHeading({ level: 1 })
                          .run()
                      },
                    },
                  },
                  [_vm._v("\n          H1\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: {
                      "is-active": _vm.editor.isActive("heading", { level: 2 }),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor
                          .chain()
                          .focus()
                          .toggleHeading({ level: 2 })
                          .run()
                      },
                    },
                  },
                  [_vm._v("\n          H2\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: {
                      "is-active": _vm.editor.isActive("heading", { level: 3 }),
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor
                          .chain()
                          .focus()
                          .toggleHeading({ level: 3 })
                          .run()
                      },
                    },
                  },
                  [_vm._v("\n          H3\n        ")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("bulletList") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleBulletList().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-list-ul" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("orderedList") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleOrderedList().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-list-ol" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("taskList") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleTaskList().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-tasks" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("blockquote") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleBlockquote().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-quote-right" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    class: { "is-active": _vm.editor.isActive("codeBlock") },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().toggleCodeBlock().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-terminal" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().setHorizontalRule().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-grip-lines" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    attrs: { disabled: !_vm.editor.can().undo() },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().undo().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-undo" })]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "menubar__button mr-0",
                    attrs: { disabled: !_vm.editor.can().redo() },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.editor.chain().focus().redo().run()
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-redo" })]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.editable ? _c("hr", { staticClass: "menubar-divider" }) : _vm._e(),
        _vm._v(" "),
        _c("editor-content", {
          staticClass: "editor__content",
          attrs: { editor: _vm.editor },
        }),
        _vm._v(" "),
        _vm.editable
          ? _c("has-error", { attrs: { form: _vm.form, field: _vm.name } })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }