var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.pageNumbers.length > 0
    ? _c(
        "ul",
        { staticClass: "pagination pagination-sm m-0 float-right" },
        [
          _vm._l(_vm.pageNumbers, function (page) {
            return [
              page !== "..."
                ? _c(
                    "li",
                    {
                      staticClass: "page-item",
                      class: { active: _vm.currentPage === page },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "page-link",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.actionClick(page)
                            },
                          },
                        },
                        [_vm._v(_vm._s(page))]
                      ),
                    ]
                  )
                : _c("li", { staticClass: "page-item" }, [
                    _c("span", { staticClass: "page-link" }, [
                      _vm._v(_vm._s(page)),
                    ]),
                  ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }