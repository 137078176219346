<template>
  <input type="radio" :checked="checked" :width="width" v-bind="$attrs" v-on="$listeners" />
</template>

<script>
export default {
  name: "RadioCellItem",
  props: {
    val: {
      type: [String, Number],
      required: true
    },
    width: {
      type: [String, Number],
      default: 50
    }
  },
  computed: {
    checked() {
      return parseInt(this.val) === 1 ? true : false;
    }
  }
};
</script>

<style>
</style>