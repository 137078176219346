// import { find } from "lodash";
import { arrayWrap, isset } from "../../../utils/functions";
import Form from "../index";
import { Tree } from "../../../components/tree";

import { mapGetters } from "vuex";

const changed = "changed";

export default {
    props: {
        form: {
            type: Form,
            required: true,
        },
        name: {
            type: String,
            required: true,
        },
        required: {
            type: Boolean,
            default: false,
        },
        initWhenCreated: {
            type: Boolean,
            default: true,
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        valueConsistsOf: {
            type: String,
            default: "BRANCH_PRIORITY", // ALL | BRANCH_PRIORITY | LEAF_PRIORITY | ALL_WITH_INDETERMINATE
        },
    },
    computed: {
        ...mapGetters("auth", ["currentWorkShopId"]),
    },
    data() {
        return {
            idKey: null,
            parentKey: null,
            sortKey: null,
            nameKey: "name",
            sourceData: [],
            tree: new Tree({ key: 0, data: { name: "Root" } }),

            options: [],
            /**
             * Использовать когда поле загружает конечный список
             * без аякс поиска
             */
            loadOptions: () => {
                console.error("loadOptions is not initialized: ");
            },
        };
    },
    async created() {
        if (this.initWhenCreated) {
            await this.loadOptions();
            this._parseSourceData();
        }
    },
    methods: {
        async setOptionIds(ids) {
            if (isset(ids)) {
                ids = arrayWrap(ids);
                if (this.initWhenCreated) {
                    //if list is loaded when created
                    this._sortItems(ids);
                } else {
                    await this.loadOptions();
                    this._parseSourceData();
                    this._sortItems(ids);
                }
            } else if (this.initWhenCreated === false) {
                //when need load field only when show
                await this.loadOptions();
                this._parseSourceData();
            }
        },
        input(id) {
            if (this.$isset(id)) {
                const element = this.tree.find({
                    ofKey: id,
                    traversal: this.tree.traverseBFS,
                });
                this.$emit(changed, element);
            } else {
                this.$emit(changed, null);
            }
        },
        _parseSourceData() {
            this.tree.reset();
            this.tree.parse({
                data: this.sourceData,
                idKey: this.idKey,
                parentKey: this.parentKey,
                sortKey: this.sortKey,
                nameKey: this.nameKey,
            });
        },
        _sortItems(ids) {
            //if list is loaded when created
            var sortedAsIds = [];
            for (let id of ids) {
                const element = this.tree.find({
                    ofKey: id,
                    traversal: this.tree.traverseBFS,
                });
                sortedAsIds.push(element.key);
            }
            if (sortedAsIds.length > 0) {
                this.form[this.name] = this.multiple
                    ? sortedAsIds
                    : sortedAsIds[0];
            }
        },
        getOptionsByIds(ids) {
            let options = [];
            for (let id of ids) {
                options.push(this.sourceData.find((x) => x.id === id));
            }
            return options;
        },
    },
};
