<template>
  <div class="form-group">
    <label :for="id">{{ title }}</label>

    <div class="row">
      <div class="col-sm-6">
        <date-picker
          v-model="dateData"
          :id="id"
          :popover="{ visibility: 'click' }"
          :locale="locale"
        ></date-picker>
      </div>
      <div class="col-sm-6">
        <vue-timepicker
          auto-scroll
          v-model="timeData"
          :format="timeFormat"
        ></vue-timepicker>
      </div>
    </div>

    <has-error :form="form" :field="name" />
  </div>
</template>

<script>
import Form, { HasError } from "../index";

import { DatePicker } from "v-calendar";
import VueTimepicker from "vue2-timepicker";

import { mapGetters } from "vuex";

export default {
  name: "DateTimeField",
  components: {
    HasError,
    DatePicker,
    VueTimepicker,
  },
  props: {
    form: {
      type: Form,
      required: true,
      validator: function (value) {
        return value instanceof Form;
      },
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateData: this.isDate ? this.form[this.name] : null,
      timeFormat: "HH:mm",
      timeData: {
        //bug with number
        HH: this.isDate ? this.getHours(this.form[this.name]) : null, //should be text, if number doesnt work
        mm: this.isDate ? this.getMinutes(this.form[this.name]) : null, //should be text
      },
    };
  },
  watch: {
    dateData() {
      this.updateFormValue();
    },
    timeData() {
      this.updateFormValue();
    },
    inmediate: true,
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    isDate() {
      return (
        this.form[this.name] !== undefined &&
        this.form[this.name] !== null &&
        this.form[this.name] instanceof Date
      );
    },
  },
  methods: {
    initValue(dt) {
      this.reset();
      this.dateData = dt !== null && dt !== undefined ? dt : null;
      this.timeData.HH =
        dt !== null && dt !== undefined ? this.getHours(dt) : null;
      this.timeData.mm =
        dt !== null && dt !== undefined ? this.getMinutes(dt) : null;
    },
    updateFormValue() {
      let dt = null;
      if (
        this.dateData !== null &&
        this.timeData.HH !== null &&
        this.timeData.mm !== null
      ) {
        dt = new Date(
          `${this.getDate(this.dateData)} ${this.timeData.HH}:${
            this.timeData.mm
          }:00`
        );
        this.form[this.name] = dt;
      }
    },
    getDate(dt) {
      return (
        dt.getFullYear() +
        "-" +
        this.appendLeadingZeroes(dt.getMonth() + 1) +
        "-" +
        this.appendLeadingZeroes(dt.getDate())
      );
    },
    getHours(value) {
      return this.appendLeadingZeroes(value.getHours());
    },
    getMinutes(value) {
      return this.appendLeadingZeroes(value.getMinutes());
    },
    appendLeadingZeroes(n) {
      if (n <= 9) {
        return `0${n}`;
      }
      return `${n}`;
    },
    reset() {
      this.dateData = null;
      this.timeData.HH = null;
      this.timeData.mm = null;
    },
  },
};
</script>

<style>
@import "../../../../../node_modules/vue2-timepicker/dist/VueTimepicker.css";
</style>