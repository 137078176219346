var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _vm._t(
        "value",
        function () {
          return [
            _c("div", [_vm._v("\n      " + _vm._s(_vm.value) + "\n    ")]),
          ]
        },
        { value: _vm.value }
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }