<template>
  <div class="form-group">
    <label v-if="title">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <div class="editor" :class="{ 'editor-border': showBorder }">
      <div v-if="editable">
        <div class="menubar">
          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('bold') }"
            @click.prevent="editor.chain().focus().toggleBold().run()"
          >
            <i class="fas fa-bold"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('italic') }"
            @click.prevent="editor.chain().focus().toggleItalic().run()"
          >
            <i class="fas fa-italic"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('strike') }"
            @click.prevent="editor.chain().focus().toggleStrike().run()"
          >
            <i class="fas fa-strikethrough"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('underline') }"
            @click.prevent="editor.chain().focus().toggleUnderline().run()"
          >
            <i class="fas fa-underline"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('link') }"
            @click.prevent="setLink"
          >
            <i class="fas fa-link"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('code') }"
            @click.prevent="editor.chain().focus().toggleCode().run()"
          >
            <i class="fas fa-code"></i>
          </button>

          <button
            class="menubar__button mr-0"
            @click.prevent="editor.chain().focus().setHardBreak().run()"
          >
            <i class="fas fa-paragraph"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
            @click.prevent="
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            "
          >
            H1
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
            @click.prevent="
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            "
          >
            H2
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
            @click.prevent="
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            "
          >
            H3
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('bulletList') }"
            @click.prevent="editor.chain().focus().toggleBulletList().run()"
          >
            <i class="fas fa-list-ul"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('orderedList') }"
            @click.prevent="editor.chain().focus().toggleOrderedList().run()"
          >
            <i class="fas fa-list-ol"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('taskList') }"
            @click.prevent="editor.chain().focus().toggleTaskList().run()"
          >
            <i class="fas fa-tasks"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('blockquote') }"
            @click.prevent="editor.chain().focus().toggleBlockquote().run()"
          >
            <i class="fas fa-quote-right"></i>
          </button>

          <button
            class="menubar__button mr-0"
            :class="{ 'is-active': editor.isActive('codeBlock') }"
            @click.prevent="editor.chain().focus().toggleCodeBlock().run()"
          >
            <i class="fas fa-terminal"></i>
          </button>

          <button
            class="menubar__button mr-0"
            @click.prevent="editor.chain().focus().setHorizontalRule().run()"
          >
            <i class="fas fa-grip-lines"></i>
          </button>

          <button
            class="menubar__button mr-0"
            @click.prevent="editor.chain().focus().undo().run()"
            :disabled="!editor.can().undo()"
          >
            <i class="fas fa-undo"></i>
          </button>

          <button
            class="menubar__button mr-0"
            @click.prevent="editor.chain().focus().redo().run()"
            :disabled="!editor.can().redo()"
          >
            <i class="fas fa-redo"></i>
          </button>
        </div>
      </div>

      <hr class="menubar-divider" v-if="editable" />

      <editor-content class="editor__content" :editor="editor" />
      <has-error :form="form" :field="name" v-if="editable" />
    </div>
  </div>
</template>

<script>
import Form, { HasError } from "../index";
import { mapGetters } from "vuex";

import { Editor, EditorContent } from "@tiptap/vue-2";

import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Blockquote from "@tiptap/extension-blockquote";
import CodeBlock from "@tiptap/extension-code-block";
import Code from "@tiptap/extension-code";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";
import History from "@tiptap/extension-history";

export default {
  components: {
    EditorContent,
    HasError,
  },
  props: {
    form: {
      type: Form,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editor: new Editor({
        onUpdate: ({ editor }) => {
          this.form[this.name] = editor.getHTML();
        },
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          Strike,
          Underline,
          Code,
          HardBreak,
          Heading.configure({
            levels: [1, 2, 3, 4, 5, 6],
          }),
          ListItem,
          BulletList,
          OrderedList,
          TaskList,
          TaskItem,
          Blockquote,
          CodeBlock,
          HorizontalRule,
          Highlight,
          Typography,
          Link.configure({
            openOnClick: false,
          }),
          History.configure({
            depth: 10,
          }),
        ],
      }),
    };
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    clearContent() {
      this.editor.commands.clearContent();
    },
    setContent(content) {
      this.editor.commands.setContent(content);
    },
    setLink() {
      const previousUrl = this.editor.getAttributes("link").href;
      const url = window.prompt("URL", previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === "") {
        this.editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: url })
        .run();
    },
  },
};
</script>