<template>
  <div class="form-group">
    <div>
      <label :for="id">
        {{ title }}
        <span class="text-danger" v-if="required">*</span>
      </label>
    </div>
    <div>
      <v-swatches
        swatches="text-advanced"
        :id="id"
        v-model="form[name]"
        :class="{ 'is-invalid': form.errors.has(name) }"
        show-fallback
        fallback-input-type="color"
        popover-x="right"
        popover-y="bottom"
        :spacing-size="5"
      >
        <div>
          <input slot="trigger" :value="form[name]" readonly />
        </div>
      </v-swatches>
      <has-error :form="form" :field="name" />
    </div>
  </div>
</template>

<script>
import Form, { HasError } from "../index";

import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";

export default {
  name: "ColoPickerField",
  components: {
    VSwatches,
    HasError
  },
  props: {
    form: {
      type: Form,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
</style>