
<template>
  <div class="form-group">
    <label :for="id">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <textarea
      :id="id"
      class="form-control"
      :class="{ 'is-invalid': form.errors.has(name) }"
      :rows="rows"
      v-model="form[name]"
      :readonly="readonly"
    ></textarea>
    <has-error :form="form" :field="name" />
  </div>
</template>

<script>
import Form, { HasError } from "../index";

export default {
  name: "TextAreaField",
  components: {
    HasError
  },
  props: {
    rows: {
      type: [Number, String],
      default: "3"
    },
    form: {
      type: Form,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
</style>