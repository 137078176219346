import axios from "axios";

export default {
    getTags(
        work_shop_id,
        { current_page = 1, per_page = 20, tag_type = 0, data = {} }
    ) {
        return axios.get(
            `/api/${work_shop_id}/tags/${tag_type}/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getTag(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/tags/${id}`);
    },

    getTagsTree(work_shop_id, { tag_type }) {
        return axios.get(`/api/${work_shop_id}/tags/tree/${tag_type}`);
    },

    getTagsTreeByParentId(work_shop_id, { tag_type, parent_id }) {
        return axios.get(
            `/api/${work_shop_id}/tags/tree/${tag_type}/${parent_id}`
        );
    },

    getTagChildren(work_shop_id, tag_id) {
        return axios.get(`/api/${work_shop_id}/tags/children/${tag_id}`);
    },

    deleteTag(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/tags/${id}`);
    },

    updateTag(work_shop_id, { id, data }) {
        return axios.put(`/api/${work_shop_id}/tags/${id}`, { ...data });
    }
};
