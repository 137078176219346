import Big from "big.js";

class Format {
    formatMoney(value, precision = 2) {
        return new Big(value).toFixed(precision);
    }

    formatNumber(value, precision = 2) {
        return new Big(value).toFixed(precision);
    }

    /**
     *
     * @param {Number} value
     * @param {String} sufixMinute
     * @param {String} sufixHour
     * @return {String}
     */
    static convertMinuteToHour(value, sufixMinute = "min", sufixHour = "H") {
        let val = parseInt(value);
        if (val === 0) return "";
        if (val > 60) {
            const minute = val % 60;
            const hour = Math.trunc(val / 60);
            return `${hour}${sufixHour} ${minute}${sufixMinute}`;
        }
        return `${val}${sufixMinute}`;
    }

    /**
     *
     * @param {Number} value
     * @param {String} sufixMinute
     * @param {String} sufixHour
     * @param {String} sufixSecond
     * @return {String}
     */
    static convertSecondsToHour({
        value,
        sufixMinute = "min",
        sufixHour = "h",
        sufixSecond = "sec",
        showZero = false,
        showMinuts = true,
        showSeconds = true
    }) {
        let val = parseInt(value);
        if (val === 0) return showZero ? 0 : "";

        if (val >= 3600) {
            const seconds = val % 60;
            const hour = Math.trunc(val / 3600);
            const minute = Math.trunc((val - hour * 3600) / 60);
            let time = `${hour}${sufixHour}`;
            if (showMinuts && minute > 0) {
                time = `${time} ${minute}${sufixMinute}`;
            }
            if (showSeconds && seconds > 0) {
                time = `${time} ${seconds}${sufixSecond}`;
            }
            return time;
        }

        if (val >= 60) {
            const seconds = val % 60;
            const minute = Math.trunc(val / 60);
            if (seconds > 0) {
                return `${minute}${sufixMinute} ${seconds}${sufixSecond}`;
            } else {
                return `${minute}${sufixMinute}`;
            }
        }

        return `${val}${sufixSecond}`;
    }
}

export default Format;
