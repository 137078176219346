var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-md-12" },
      [
        _c("vue-my-table-filter-component", {
          attrs: { "table-columns": _vm.tableColumns, filters: _vm.filters },
          on: _vm._d({}, [_vm.onResetFilters, _vm.resetFilters]),
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "card", class: { "sk-loading": _vm.showSpinner } },
          [
            _c("div", { staticClass: "card-header" }, [
              _c("h3", { staticClass: "card-title" }, [
                _vm._v(_vm._s(_vm.title)),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "card-tools" },
                [
                  _vm.isQuickSearching
                    ? _c(
                        "div",
                        {
                          staticClass: "input-group input-group-sm",
                          staticStyle: { width: "300px" },
                        },
                        [
                          _vm.advancedSearch
                            ? _c(
                                "div",
                                { staticClass: "input-group-append mr-2" },
                                [
                                  _vm._m(0),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "dropdown-menu dropdown-menu-right",
                                      attrs: { role: "menu" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticStyle: { padding: "5px" } },
                                        [
                                          _c("h5", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "tables.advancedSearching"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("b", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "tables.searchingExampleTitle"
                                                )
                                              )
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "ul",
                                            {
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _c("li", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "tables.searchingExample1"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("li", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "tables.searchingExample2"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("li", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "tables.searchingExample3"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("li", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t(
                                                      "tables.searchingExample4"
                                                    )
                                                  )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.quickSearching,
                                expression: "quickSearching",
                              },
                            ],
                            staticClass: "form-control float-right",
                            attrs: {
                              type: "text",
                              name: "table_search",
                              placeholder: _vm.$t("actions.search"),
                            },
                            domProps: { value: _vm.quickSearching },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.quickSearching = $event.target.value
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm._m(1),
                          _vm._v(" "),
                          _c("div", { staticClass: "btn-group ml-2" }, [
                            _vm._m(2),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "dropdown-menu dropdown-menu-right",
                                attrs: { role: "menu" },
                              },
                              [
                                _c(
                                  "a",
                                  {
                                    staticClass: "dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeAdvancedSearch(
                                          _vm.advancedSearch
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm.advancedSearch
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-check-circle mr-1",
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            "actions.enabled_advanced_search"
                                          )
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._t("table-tools"),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _vm.showPerPageToolbar && _vm.rows.length > 0
              ? _c(
                  "div",
                  { staticClass: "card-footer clearfix" },
                  [
                    _c("my-per-page-component", {
                      attrs: {
                        "per-page": _vm.perPage,
                        total: _vm.paginationOptions.total,
                      },
                      on: _vm._d({}, [
                        _vm.perPageButtonClikedEvent,
                        _vm.perPageClicked,
                      ]),
                    }),
                    _vm._v(" "),
                    _vm.paginationOptions.total > 0
                      ? _c("my-show-table-count-info-component", {
                          attrs: {
                            from: _vm.paginationOptions.from,
                            to: _vm.paginationOptions.to,
                            total: _vm.paginationOptions.total,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("paginator", {
                      attrs: {
                        "current-page": _vm.paginationOptions.currentPage,
                        "last-page": _vm.paginationOptions.lastPage,
                      },
                      on: { "on-page-change": _vm.onLoadNextPage },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "card-body table-responsive p-0" }, [
              _vm.showSpinner
                ? _c(
                    "div",
                    { staticClass: "sk-spinner sk-spinner-double-bounce" },
                    [
                      _c("div", { staticClass: "sk-double-bounce1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "sk-double-bounce2" }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table table-hover" },
                [
                  _c("thead", [
                    _c(
                      "tr",
                      [
                        _vm.draggableEnabled
                          ? _c("th", { staticStyle: { "text-align": "left" } })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showRowTableAction && _vm.tableRows.length > 0
                          ? _c("th", { style: _vm.actionColumnStyle })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.tableColumns, function (column) {
                          return _c(
                            "th",
                            {
                              key: column.id,
                              style: column.styleHeader,
                              on: {
                                click: function ($event) {
                                  return _vm.onSortColumnClicked(column)
                                },
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    column.translate
                                      ? _vm.$t(column.name)
                                      : column.name
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              column.isSortByAsc
                                ? _c("i", {
                                    staticClass: "fas fa-sort-amount-up",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              column.isSortByDesc
                                ? _c("i", {
                                    staticClass: "fas fa-sort-amount-down",
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.rows.length == 0
                    ? _c("tbody", [
                        _c("tr", [
                          _vm.draggableEnabled ? _c("td") : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticClass: "text-center",
                              attrs: { colspan: _vm.tableColumns.length + 1 },
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.emptyRowsMessage) +
                                  "\n              "
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _c(
                        "draggable",
                        {
                          attrs: {
                            disabled: !_vm.draggableEnabled,
                            handle: ".handle",
                            tag: "tbody",
                            list: _vm.rows,
                            move: _vm.handlerMove,
                          },
                          on: { change: _vm.handlerDraggable },
                        },
                        _vm._l(_vm.rows, function (row, index_row) {
                          return _c(
                            "tr",
                            {
                              key:
                                index_row + "_" + new Date().getMilliseconds(),
                            },
                            [
                              _vm.draggableEnabled
                                ? _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        width: "40px",
                                        padding: "5px",
                                      },
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-sm handle pr-0",
                                          attrs: { type: "button" },
                                        },
                                        [
                                          _c("Icon", {
                                            attrs: {
                                              icon: "octicon:grabber-16",
                                              width: "24",
                                              height: "24",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.showRowTableAction
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "td-align-left",
                                      style: {
                                        padding: "5px",
                                        width: _vm.rowTableActionWidth + "px",
                                      },
                                    },
                                    [
                                      _vm._t(
                                        "table-action",
                                        function () {
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "btn-group" },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "btn btn-default",
                                                    attrs: {
                                                      type: "button",
                                                      "data-toggle": "dropdown",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-list",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Toggle Dropdown"
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "dropdown-menu",
                                                        attrs: { role: "menu" },
                                                      },
                                                      [
                                                        _c(
                                                          "my-edit-drop-down-menu-item",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.onEditActionClicked(
                                                                  row,
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          }
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "my-remove-drop-down-menu-item",
                                                          {
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                $event.stopPropagation()
                                                                return _vm.onRemoveActionClicked(
                                                                  row,
                                                                  $event
                                                                )
                                                              },
                                                            },
                                                          }
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm.rowShowButton
                                                  ? _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-default",
                                                        attrs: {
                                                          type: "button",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            return _vm.onShowActionClicked(
                                                              row,
                                                              $event
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-eye",
                                                        }),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        },
                                        { row: row }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm._l(row.getCells, function (cell, index_cell) {
                                return _c(
                                  "td",
                                  {
                                    key: index_cell,
                                    style: cell.getColumn.styleCell,
                                    on: {
                                      click: function ($event) {
                                        return _vm.onRowClicked(row, $event)
                                      },
                                    },
                                  },
                                  [
                                    _vm._t(
                                      "table-row",
                                      function () {
                                        return [
                                          _c("span", [
                                            _vm._v(_vm._s(cell.getValue)),
                                          ]),
                                        ]
                                      },
                                      { cell: cell, row: row }
                                    ),
                                  ],
                                  2
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      ),
                  _vm._v(" "),
                  _vm.showFooter
                    ? _c("tfoot", [
                        _vm.rows.length > 0
                          ? _c(
                              "tr",
                              [
                                _vm.draggableEnabled ? _c("th") : _vm._e(),
                                _vm._v(" "),
                                _vm.showRowTableAction
                                  ? _c("th", { style: _vm.actionColumnStyle })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm._l(_vm.tableColumns, function (column) {
                                  return _c(
                                    "th",
                                    {
                                      key: column.id,
                                      style: column.styleFooter,
                                    },
                                    [
                                      _vm._t(
                                        "table-footer-row",
                                        function () {
                                          return [
                                            column.isCalculateTotal
                                              ? _c(
                                                  "span",
                                                  [
                                                    column.timeTotal
                                                      ? _c(
                                                          "formatted-hour-field",
                                                          {
                                                            attrs: {
                                                              val: column.getTotal,
                                                              showZero:
                                                                column.showTimeTotalZero,
                                                              showSeconds:
                                                                column.showTimeTotalSeconds,
                                                            },
                                                          }
                                                        )
                                                      : _c(
                                                          "formatted-number-field",
                                                          {
                                                            attrs: {
                                                              val: column.getTotal,
                                                              id: column.id,
                                                              money:
                                                                column.isShowMoney,
                                                            },
                                                          }
                                                        ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        },
                                        { column: column }
                                      ),
                                    ],
                                    2
                                  )
                                }),
                              ],
                              2
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.showPerPageToolbar && _vm.rows.length > 0
              ? _c("div", { staticClass: "card-footer" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col-md-12", staticStyle: {} },
                      [
                        _c("my-per-page-component", {
                          attrs: {
                            "per-page": _vm.perPage,
                            total: _vm.paginationOptions.total,
                          },
                          on: _vm._d({}, [
                            _vm.perPageButtonClikedEvent,
                            _vm.perPageClicked,
                          ]),
                        }),
                        _vm._v(" "),
                        _vm.paginationOptions.total > 0
                          ? _c("my-show-table-count-info-component", {
                              attrs: {
                                from: _vm.paginationOptions.from,
                                to: _vm.paginationOptions.to,
                                total: _vm.paginationOptions.total,
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("paginator", {
                          attrs: {
                            "current-page": _vm.paginationOptions.currentPage,
                            "last-page": _vm.paginationOptions.lastPage,
                          },
                          on: { "on-page-change": _vm.onLoadNextPage },
                        }),
                      ],
                      1
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-default",
        attrs: { type: "submit", "data-toggle": "dropdown" },
      },
      [_c("i", { staticClass: "far fa-question-circle" })]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-append" }, [
      _c(
        "button",
        { staticClass: "btn btn-default", attrs: { type: "submit" } },
        [_c("i", { staticClass: "fas fa-search" })]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-secondary btn-sm dropdown-toggle",
        attrs: { type: "button", "data-toggle": "dropdown" },
      },
      [_c("i", { staticClass: "fas fa-cog" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }