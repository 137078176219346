<template>
  <div class="form-group">
    <div v-if="!inline">
      <label>
        {{ title }}
        <span class="text-danger" v-if="required">*</span>
      </label>
    </div>
    <div>
      <toggle-button
        v-model="form[name]"
        :labels="{checked: checkedText ? checkedText : $t('states.active'), unchecked: unCheckedText ? unCheckedText : $t('states.not_active')}"
        :disabled="disabled"
        :sync="true"
        :width="85"
      />
      <label class="label-switch" v-if="inline">{{ title }}</label>
      <has-error :form="form" :field="name" />
    </div>
  </div>
</template>

<script>
import Form, { HasError } from "../index";

import { ToggleButton } from "vue-js-toggle-button";

export default {
  name: "ToogleField",
  components: {
    HasError,
    ToggleButton,
  },
  props: {
    form: {
      type: Form,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checkedText: {
      required: false,
      default: null,
    },
    unCheckedText: {
      required: false,
      default: null,
    },
    inline: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
label.label-switch {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  padding-left: 10px;
}
</style>