import axios from "axios";

export default {
    getUsers(work_shop_id, { current_page = 1, per_page = 20, data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/users/${current_page}/${per_page}`,
            {
                params: { ...data }
            }
        );
    },

    getUser(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/users/${id}`);
    },

    deleteUser(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/users/${id}`);
    },

    changeDefaultWorkShop(work_shop_id, { id }) {
        return axios.put(`/api/${work_shop_id}/users/default_work_shop`, {
            default_work_shop: id
        });
    },

    whoAmI() {
        return axios.get("/api/users/who-am-i");
    },

    getMyWorkShops() {
        return axios.get(`/api/users/my-work-shops`);
    }
};
