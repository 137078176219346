<template>
  <div>
    <label>{{ title }}</label>
    <slot name="value" :value="value">
      <div>
        {{ value }}
      </div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Object, Number, Date, Array],
      required: true,
    },
  },
};
</script>

<style>
</style>