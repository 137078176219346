import Form from "../form";
import {
    Filter,
    FiltersUpdatedTransfer,
    ListFilterField,
    CheckBoxFilterField,
    DateRangeFilterField,
} from "./index";
import {
    ON_RESET_FILTERS,
    ON_RESTORE_FILTERS_FROM_STORAGE,
    ON_UPDATE_FILTERS,
    ON_EXTERNAL_UPDATE_FILTERS,
} from "./table-events";

/**
 * Базовый класс для модальных фильтров
 */
export default {
    extends: Filter,
    data() {
        return {
            storedFilters: [],
            fields: [],
            form: new Form(),
        };
    },
    created() {
        EventBus.listen(ON_RESET_FILTERS, () => {
            this.storedFilters = [];
            this.form.reset();
        });
        EventBus.listen(
            [ON_RESTORE_FILTERS_FROM_STORAGE, ON_EXTERNAL_UPDATE_FILTERS],
            ({ key, filters }) => {
                if (key === this.filterKey) {
                    this.storedFilters = filters;
                }
            }
        );
    },
    beforeDestroy() {
        EventBus.off([
            ON_RESTORE_FILTERS_FROM_STORAGE,
            ON_RESET_FILTERS,
            ON_EXTERNAL_UPDATE_FILTERS,
        ]);
    },
    methods: {
        onSave(data, rawData) {
            const filters = this.getfilters(this.fields, data, rawData);
            EventBus.fire(
                ON_UPDATE_FILTERS,
                new FiltersUpdatedTransfer({
                    key: this.filterKey,
                    filters,
                })
            );
        },
        async initFilterModal() {
            for (let field of this.fields) {
                let filter = _.find(
                    this.storedFilters,
                    (x) => x.dataKey === field.dataKey
                );
                if (field instanceof ListFilterField) {
                    let ids = null;
                    if (this.$isset(filter)) ids = filter.filterColumn.value;

                    if (this.$isset(this.$refs[field.dataKey]))
                        this.$refs[field.dataKey].setOptionIds(ids);
                } else if (field instanceof CheckBoxFilterField) {
                    if (
                        this.$isset(this.form[field.dataKey]) &&
                        this.$isset(filter) &&
                        filter.enabled
                    )
                        this.form[field.dataKey] = filter.filterColumn.value;
                } else if (field instanceof DateRangeFilterField) {
                    if (this.$isset(filter)) {
                        this.form[field.dateKey] = [
                            this.$parseDate(filter.filterColumn.value[0]),
                            this.$parseDate(filter.filterColumn.value[1]),
                        ];
                    }
                }
            }
            this.storedFilters = [];
        },
    },
};
