<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
import { Format } from "../../calc";

export default {
  name: "FormattedDateField",
  props: {
    val: {
      required: true,
    },
    showZero: {
      type: Boolean,
      default: false,
    },
    showSeconds: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedValue() {
      if (!this.$isset(this.val)) return "";
      
      return Format.convertSecondsToHour({
        value: this.val,
        sufixMinute: this.$t("fields.sufix_minute"),
        sufixHour: this.$t("fields.sufix_hour"),
        sufixSecond: this.$t("fields.sufix_seconds"),
        showZero: this.showZero,
        showSeconds: this.showSeconds,
      });
    },
  },
};
</script>