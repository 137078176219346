<template>
  <select-field
    :title="$t('pages.orders.type_delivery')"
    :id="id"
    :form="form"
    :name="name"
    :multiple="multiple"
    :label="optionLabel"
    :options="options"
    v-bind="$attrs"
    v-on="$listeners"
    @search="onSearch"
    :required="required"
  ></select-field>
</template>

<script>
import { SelectField, BaseListField } from "../../../components/form";
import api from "../../../api/orders";

import { mapGetters } from "vuex";

export default {
  extends: BaseListField,
  components: {
    SelectField,
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    optionLabel() {
      return `${this.locale}name`;
    },
  },
  data() {
    return {
      loadOptionsAjax: (searchingText) => {
        console.error("not implemented:" + searchingText);
      },
      loadOptionsByIds: (ids) => {
        console.error("not implemented:" + ids);
      },
      loadOptions: async () => {
        let { data } = await api.getTypeDeliveries(this.currentWorkShopId);
        this.options = data;
      },
    };
  },
};
</script>

<style>
</style>