var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.total > _vm.pageNumberOptionsVisible[0]
    ? _c(
        "div",
        { staticClass: "btn-group pull-left" },
        [
          _vm._l(_vm.pageNumberOptionsVisible, function (number) {
            return _c(
              "button",
              {
                key: "my-vue-table-page-" + number,
                staticClass: "btn btn-sm",
                class: {
                  "btn-default": parseInt(_vm.perPage) !== parseInt(number),
                  "btn-primary": parseInt(_vm.perPage) === parseInt(number),
                },
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.perPageClicked(number)
                  },
                },
              },
              [_vm._v(_vm._s(number))]
            )
          }),
          _vm._v(" "),
          _c("div", { staticClass: "btn-group" }, [
            _c("button", {
              staticClass:
                "btn btn-default btn-sm dropdown-toggle dropdown-icon",
              attrs: { type: "button", "data-toggle": "dropdown" },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "dropdown-menu" },
              _vm._l(_vm.pageNumberOptionsDropDown, function (number) {
                return _c(
                  "a",
                  {
                    key: "my-vue-table-page-" + number,
                    staticClass: "dropdown-item",
                    on: {
                      click: function ($event) {
                        return _vm.perPageClicked(number)
                      },
                    },
                  },
                  [
                    parseInt(_vm.perPage) === parseInt(number)
                      ? _c("i", { staticClass: "far fa-check-circle" })
                      : _vm._e(),
                    _vm._v("\n        " + _vm._s(number) + "\n      "),
                  ]
                )
              }),
              0
            ),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }