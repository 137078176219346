var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
        _vm.required
          ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.form[_vm.name],
            expression: "form[name]",
          },
        ],
        staticClass: "form-control",
        class: { "is-invalid": _vm.form.errors.has(_vm.name) },
        attrs: { id: _vm.id, rows: _vm.rows, readonly: _vm.readonly },
        domProps: { value: _vm.form[_vm.name] },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.$set(_vm.form, _vm.name, $event.target.value)
          },
        },
      }),
      _vm._v(" "),
      _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }