export default class LocalRepository {

    setData(key, data, serialize = false) {
        if (serialize) {
            data = JSON.stringify(data);
        }
        localStorage.setItem(key, data);
    }

    getData(key, unserialize = false) {
        if (localStorage.getItem(key)) {
            let data = localStorage.getItem(key);
            return unserialize ? JSON.parse(data) : data;
        }
        return null;
    }

    removeData(key) {
        localStorage.removeItem(key);
    }

}