<template>
  <router-link
    :to="{ name: name, params: params }"
    class="nav-link pl-0 pr-0 dark"
  >
    <i class="nav-icon fa fa-edit p2"></i>{{ text }}
  </router-link>
</template>

<script>
export default {
  name: "OrderDropDownMenuItem",
  props: {
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: [String],
      default: "",
    },
  },
  computed: {
    text() {
      return this.title.length > 0 ? this.title : this.$t("actions.edit");
    },
  },
};
</script>

<style>
.dark {
  color: black;
}
.p2 {
  padding-left: 4px !important;
  padding-right: 4px;
}
</style>