<template>
  <div class="card" :class="{ 'sk-loading': showSpinner }">
    <div class="sk-spinner sk-spinner-double-bounce" v-if="showSpinner">
      <div class="sk-double-bounce1"></div>
      <div class="sk-double-bounce2"></div>
    </div>

    <div class="card-body">
      <foreman-by-line-toogle-field
        :form="form"
        name="foremanByLine"
      ></foreman-by-line-toogle-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Form } from "../../../components/form";
import { ForemanByLineToogleField } from "../fields";
import { ORDER_CONTROL_SIDEBAR_CHANGED_FOREMAN } from "../../events";

export default {
  props: {
    foremanByLine: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    ForemanByLineToogleField,
  },
  data() {
    return {
      showSpinner: false,
      form: new Form({
        foremanByLine: this.foremanByLine,
      }),
    };
  },
  watch: {
    "form.foremanByLine"(newVal) {
      EventBus.fire(ORDER_CONTROL_SIDEBAR_CHANGED_FOREMAN, {
        foremanByLine: newVal,
      });
    },
  },
  computed: {
    ...mapGetters("auth", ["hasPermission"]),
  },
};
</script>