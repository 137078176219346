var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    !_vm.inline
      ? _c("div", [
          _c("label", [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
            _vm.required
              ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("toggle-button", {
          attrs: {
            labels: {
              checked: _vm.checkedText
                ? _vm.checkedText
                : _vm.$t("states.active"),
              unchecked: _vm.unCheckedText
                ? _vm.unCheckedText
                : _vm.$t("states.not_active"),
            },
            disabled: _vm.disabled,
            sync: true,
            width: 85,
          },
          model: {
            value: _vm.form[_vm.name],
            callback: function ($$v) {
              _vm.$set(_vm.form, _vm.name, $$v)
            },
            expression: "form[name]",
          },
        }),
        _vm._v(" "),
        _vm.inline
          ? _c("label", { staticClass: "label-switch" }, [
              _vm._v(_vm._s(_vm.title)),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }