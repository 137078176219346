// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
import "firebase/analytics";

if (window.config.firebase.apiKey) {
    const firebaseConfig = {
        apiKey: window.config.firebase.apiKey,
        authDomain: window.config.firebase.authDomain,
        projectId: window.config.firebase.projectId,
        storageBucket: window.config.firebase.storageBucket,
        messagingSenderId: window.config.firebase.messagingSenderId,
        appId: window.config.firebase.appId,
        measurementId: window.config.firebase.measurementId
    };
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
}
