<template>
  <div class="row">
    <div class="col-md-12">
      <vue-my-table-filter-component
        @[onResetFilters]="resetFilters"
        :table-columns="tableColumns"
        :filters="filters"
      ></vue-my-table-filter-component>

      <div class="card" :class="{ 'sk-loading': showSpinner }">
        <div class="card-header">
          <h3 class="card-title">{{ title }}</h3>

          <div class="card-tools">
            <div
              class="input-group input-group-sm"
              style="width: 300px"
              v-if="isQuickSearching"
            >
              <div class="input-group-append mr-2" v-if="advancedSearch">
                <button
                  type="submit"
                  class="btn btn-default"
                  data-toggle="dropdown"
                >
                  <i class="far fa-question-circle"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" role="menu">
                  <div style="padding: 5px">
                    <h5>{{ $t("tables.advancedSearching") }}</h5>
                    <b>{{ $t("tables.searchingExampleTitle") }}</b>
                    <ul style="font-size: 14px">
                      <li>{{ $t("tables.searchingExample1") }}</li>
                      <li>{{ $t("tables.searchingExample2") }}</li>
                      <li>{{ $t("tables.searchingExample3") }}</li>
                      <li>{{ $t("tables.searchingExample4") }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <input
                type="text"
                name="table_search"
                class="form-control float-right"
                v-model="quickSearching"
                :placeholder="$t('actions.search')"
              />

              <div class="input-group-append">
                <button type="submit" class="btn btn-default">
                  <i class="fas fa-search"></i>
                </button>
              </div>

              <div class="btn-group ml-2">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm dropdown-toggle"
                  data-toggle="dropdown"
                >
                  <i class="fas fa-cog"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right" role="menu">
                  <a
                    class="dropdown-item"
                    @click="changeAdvancedSearch(advancedSearch)"
                  >
                    <i
                      class="fas fa-check-circle mr-1"
                      v-if="advancedSearch"
                    ></i>
                    {{ $t("actions.enabled_advanced_search") }}
                  </a>
                </div>
              </div>
            </div>
            <slot name="table-tools"></slot>
          </div>
        </div>

        <div
          class="card-footer clearfix"
          v-if="showPerPageToolbar && rows.length > 0"
        >
          <my-per-page-component
            :per-page="perPage"
            :total="paginationOptions.total"
            @[perPageButtonClikedEvent]="perPageClicked"
          ></my-per-page-component>

          <my-show-table-count-info-component
            v-if="paginationOptions.total > 0"
            :from="paginationOptions.from"
            :to="paginationOptions.to"
            :total="paginationOptions.total"
          ></my-show-table-count-info-component>

          <paginator
            :current-page="paginationOptions.currentPage"
            :last-page="paginationOptions.lastPage"
            @on-page-change="onLoadNextPage"
          ></paginator>
        </div>

        <!-- /.card-header -->
        <div class="card-body table-responsive p-0">
          <div class="sk-spinner sk-spinner-double-bounce" v-if="showSpinner">
            <div class="sk-double-bounce1"></div>
            <div class="sk-double-bounce2"></div>
          </div>

          <table class="table table-hover">
            <thead>
              <tr>
                <th v-if="draggableEnabled" style="text-align: left"></th>
                <th
                  :style="actionColumnStyle"
                  v-if="showRowTableAction && tableRows.length > 0"
                ></th>
                <th
                  v-for="column in tableColumns"
                  :key="column.id"
                  @click="onSortColumnClicked(column)"
                  :style="column.styleHeader"
                >
                  <span>{{
                    column.translate ? $t(column.name) : column.name
                  }}</span>
                  <i
                    class="fas fa-sort-amount-up"
                    v-if="column.isSortByAsc"
                  ></i>
                  <i
                    class="fas fa-sort-amount-down"
                    v-if="column.isSortByDesc"
                  ></i>
                </th>
              </tr>
            </thead>
            <tbody v-if="rows.length == 0">
              <tr>
                <td v-if="draggableEnabled"></td>
                <td :colspan="tableColumns.length + 1" class="text-center">
                  {{ emptyRowsMessage }}
                </td>
              </tr>
            </tbody>

            <draggable
              :disabled="!draggableEnabled"
              handle=".handle"
              tag="tbody"
              :list="rows"
              @change="handlerDraggable"
              :move="handlerMove"
              v-else
            >
              <tr
                v-for="(row, index_row) in rows"
                :key="`${index_row}_${new Date().getMilliseconds()}`"
              >
                <td v-if="draggableEnabled" style="width: 40px; padding: 5px">
                  <button type="button" class="btn btn-sm handle pr-0">
                    <Icon icon="octicon:grabber-16" width="24" height="24" />
                  </button>
                </td>
                <td
                  class="td-align-left"
                  v-if="showRowTableAction"
                  :style="{ padding: '5px', width: `${rowTableActionWidth}px` }"
                >
                  <slot name="table-action" :row="row">
                    <div class="btn-group">
                      <button
                        type="button"
                        class="btn btn-default"
                        data-toggle="dropdown"
                      >
                        <i class="fas fa-list"></i>
                        <span class="sr-only">Toggle Dropdown</span>
                        <div class="dropdown-menu" role="menu">
                          <my-edit-drop-down-menu-item
                            @click.stop="onEditActionClicked(row, $event)"
                          ></my-edit-drop-down-menu-item>

                          <my-remove-drop-down-menu-item
                            @click.stop="onRemoveActionClicked(row, $event)"
                          ></my-remove-drop-down-menu-item>
                        </div>
                      </button>
                      <button
                        type="button"
                        class="btn btn-default"
                        @click.stop="onShowActionClicked(row, $event)"
                        v-if="rowShowButton"
                      >
                        <i class="fas fa-eye"></i>
                      </button>
                    </div>
                  </slot>
                </td>

                <td
                  v-for="(cell, index_cell) in row.getCells"
                  :key="index_cell"
                  @click="onRowClicked(row, $event)"
                  :style="cell.getColumn.styleCell"
                >
                  <slot name="table-row" :cell="cell" :row="row">
                    <span>{{ cell.getValue }}</span>
                  </slot>
                </td>
              </tr>
            </draggable>
            <tfoot v-if="showFooter">
              <tr v-if="rows.length > 0">
                <th v-if="draggableEnabled"></th>
                <th :style="actionColumnStyle" v-if="showRowTableAction"></th>
                <th
                  v-for="column in tableColumns"
                  :key="column.id"
                  :style="column.styleFooter"
                >
                  <slot name="table-footer-row" :column="column">
                    <span v-if="column.isCalculateTotal">
                      <formatted-hour-field
                        v-if="column.timeTotal"
                        :val="column.getTotal"
                        :showZero="column.showTimeTotalZero"
                        :showSeconds="column.showTimeTotalSeconds"
                      />

                      <formatted-number-field
                        v-else
                        :val="column.getTotal"
                        :id="column.id"
                        :money="column.isShowMoney"
                      />
                    </span>
                  </slot>
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="card-footer" v-if="showPerPageToolbar && rows.length > 0">
          <div class="row">
            <div class="col-md-12" style="text-align">
              <my-per-page-component
                :per-page="perPage"
                :total="paginationOptions.total"
                @[perPageButtonClikedEvent]="perPageClicked"
              ></my-per-page-component>

              <my-show-table-count-info-component
                v-if="paginationOptions.total > 0"
                :from="paginationOptions.from"
                :to="paginationOptions.to"
                :total="paginationOptions.total"
              ></my-show-table-count-info-component>

              <paginator
                :current-page="paginationOptions.currentPage"
                :last-page="paginationOptions.lastPage"
                @on-page-change="onLoadNextPage"
              ></paginator>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GetDataRequest,
  Column,
  Cell,
  Row,
  FiltersUpdatedTransfer,
  SortingColumn,
  SearchingColumn,
  FilterField,
  VueMyTableFilterComponent,
  SortingStoredManager,
  SearchingStoredManager,
  FilteringStoredManager,
} from "./index";

import Paginator from "../Paginator";
import MyShowTableCountInfoComponent from "../table/MyShowTableCountInfoComponent";
import MyRemoveDropDownMenuItem from "../table/MyRemoveDropDownMenuItem";
import MyEditDropDownMenuItem from "../table/MyEditDropDownMenuItem";
import MyPerPageComponent from "../table/MyPerPageComponent";
import * as events from "./table-events";
import {
  FormattedNumberField,
  FormattedHourField,
} from "../../components/form";

import draggable from "vuedraggable";
import { Icon } from "@iconify/vue2";
/**
 * Table component with pagination, sorting and searching functionality
 * @displayName VueMyTable
 *
 * @property {Array.<FilterField>} filters
 */
export default {
  name: "VueMyTable",
  components: {
    draggable,
    Paginator,
    MyShowTableCountInfoComponent,
    MyPerPageComponent,
    VueMyTableFilterComponent,
    MyRemoveDropDownMenuItem,
    MyEditDropDownMenuItem,
    FormattedNumberField,
    FormattedHourField,
    Icon,
  },
  props: {
    saveStorageKey: {
      type: String,
      required: false,
    },
    saveSortingToStore: {
      type: Boolean,
      default: true,
    },
    saveFilteringToStore: {
      type: Boolean,
      default: true,
    },
    tableColumns: {
      type: Array,
      validator: function (columns) {
        for (let column of columns) {
          if (!(column instanceof Column)) {
            console.error("tableColumns should include only Column instance");
            return false;
          }
        }
        return true;
      },
      required: true,
    },
    /*
     * Фильтры, которые должны быть при старте,
     * если нет других сохранненых.
     * Эти фильтры сохраняються в локал сторедж
     * перед восстановлением
     */
    initFilters: {
      type: Array,
      validator: function (filters) {
        for (let filter of filters) {
          if (!(filter instanceof FilterField)) {
            console.error(
              "initFilters should include only FilterField instance"
            );
            return false;
          }
        }
        return true;
      },
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: "",
    },
    tableRows: {
      type: Array,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    showFooter: {
      type: Boolean,
      default: false,
    },
    showPerPageToolbar: {
      type: Boolean,
      default: true,
    },
    showRowTableAction: {
      type: Boolean,
      default: true,
    },
    rowTableActionWidth: {
      type: [Number, String],
      default: 85,
    },

    paginationOptions: {
      type: Object,
      default() {
        return {
          currentPage: 1,
          lastPage: 1,
          perPage: 10,
          total: 0,
          from: 0,
          to: 0,
        };
      },
    },
    rowShowButton: {
      type: Boolean,
      default: true,
    },
    rowEditButton: {
      type: Boolean,
      default: true,
    },
    isQuickSearching: {
      type: Boolean,
      default: true,
    },
    emptyRowsMessage: {
      type: String,
      default: "The table does not contain data",
    },
    actionColumnStyle: {
      type: Object,
      default() {
        return {};
      },
    },
    draggableEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      advancedSearch: false,
      /**
       * row linked to current drag operation
       */
      relatedDraggableRow: null,
      error: null,
      /** @param {Array.<FilterField>} filters */
      filters: [],
      /** @param {Array.<Row>} rows */
      rows: [],
      quickSearching: "",
      isPreparing: false,
      perPage: 20,
      //storage manager to get/save sorting
      sortingDataManager: this.saveSortingToStore
        ? new SortingStoredManager({
            key: this.saveStorageKey,
          })
        : null,
      filteringDataManager: this.saveFilteringToStore
        ? new FilteringStoredManager({
            key: this.saveStorageKey,
          })
        : null,
      searchingDataManager: this.isQuickSearching
        ? new SearchingStoredManager({
            key: this.saveStorageKey,
          })
        : null,
    };
  },
  computed: {
    showSpinner() {
      return this.isLoading || this.isPreparing;
    },
    perPageButtonClikedEvent() {
      return events.PER_PAGE_BUTTON_CLICKED;
    },
    onResetFilters() {
      return events.ON_RESET_FILTERS;
    },
  },
  watch: {
    tableRows: {
      handler() {
        _.forEach(this.tableColumns, (column) => column.resetTotal());
        this.onPrepareData();
      },
      immediate: true,
    },
    tableColumns: {
      handler() {
        _.forEach(this.tableColumns, (column) => column.resetTotal());
        this.onPrepareData();
      },
    },
    perPage: {
      handler() {
        this.getData(this.getRequest(this.paginationOptions.currentPage));
      },
    },
  },
  created() {
    const vm = this;
    EventBus.listen(
      [events.ON_UPDATE_FILTERS, events.ON_EXTERNAL_UPDATE_FILTERS],
      ({ key, filters, needReset, fromExternalFilters }) => {
        // handle filters only if for this vue-my-table instance
        if (key !== vm.saveStorageKey) return;
        //run resetting filters
        if (needReset) {
          this.resetFilters();
          return;
        }

        //check if filters is instance of FilterField
        for (let filter of filters) {
          if (!(filter instanceof FilterField)) {
            console.error("filters should include only FilterField instance");
            return false;
          }
        }
        if (filters.length > 0 && fromExternalFilters) {
          //merge filters with current one
          for (let filter of vm.filters) {
            let index = filters.findIndex((x) => x.dataKey === filter.dataKey);
            if (index === -1) filters.push(filter);
          }
        }
        vm.filters = filters;
        vm.filteringDataManager.setFilteringColumns(filters);
      }
    );

    //load saved filtering from locale storage if exist
    if (this.saveFilteringToStore) this.getFilteringColumnFromSavedStorage();

    //load saved sorting from local storage if exist
    if (this.saveSortingToStore) this.getSortingColumnFromSavedStorage();

    //load saved searching data
    if (this.isQuickSearching) {
      this.getSearchingFromSavedStorage();
    }

    EventBus.listen(events.ON_TABLE_ROW_UPDATED, () => {
      this.getData(this.getRequest(this.paginationOptions.currentPage));
    });
  },
  mounted() {
    this.$watch(
      (vm) => [vm.advancedSearch, vm.quickSearching, vm.filters],
      () => {
        this.getData(this.getRequest(1));
      },
      {
        immediate: true, // run immediately
        deep: true, // detects changes inside objects. not needed here, but maybe in other cases
      }
    );
  },
  beforeDestroy() {
    EventBus.off([
      events.ON_TABLE_ROW_UPDATED,
      events.ON_UPDATE_FILTERS,
      events.ON_EXTERNAL_UPDATE_FILTERS,
    ]);
  },
  methods: {
    perPageClicked(value) {
      this.perPage = value;
    },
    /**
     * @param {GetDataRequest} request
     */
    getData(request) {
      EventBus.fire(events.ON_REQUEST_DATA_UPDATED, {
        key: this.saveStorageKey,
        request,
      });
      this.$emit(events.GET_TABLE_DATA, request);
    },
    resetFilters() {
      //disalbed filters is always in storage
      this.filters = this.filters.filter((x) => x.alwaysInStorage === true);
      EventBus.fire(
        events.ON_RESET_FILTERS,
        new FiltersUpdatedTransfer({
          key: this.saveStorageKey,
          filters: this.filters,
        })
      );
      if (this.saveFilteringToStore) {
        this.filteringDataManager.setFilteringColumns(this.filters);
      }
    },
    convertSearching({ text, advancedSearch = false }) {
      return advancedSearch ? text : `%${text}%`;
    },
    changeAdvancedSearch(value) {
      this.advancedSearch = !value;
      if (this.isQuickSearching) {
        this.searchingDataManager.setSearchingData({
          searching: this.quickSearching,
          advancedSearch: this.advancedSearch,
        });
      }
    },
    getRequest(page) {
      let request = new GetDataRequest({
        currentPage: page,
        perPage: this.perPage,
        sorting: this.sortingColumn(),
      });

      if (this.quickSearching.length > 0) {
        let searchText = this.convertSearching({
          text: this.quickSearching,
          advancedSearch: this.advancedSearch,
        });
        request.searchingData = this.searchingColumn(searchText);
      }
      if (this.isQuickSearching) {
        //save searching to storage
        this.searchingDataManager.setSearchingData({
          searching: this.quickSearching,
          advancedSearch: this.advancedSearch,
        });
      }

      if (this.filters.length > 0) {
        //set filters
        request.filterData = this.filters
          .filter((filter) => !filter.conditional && filter.enabled)
          .map((filter) => {
            return filter.filterColumn;
          });

        //set conditional filters
        request.conditionalData = this.filters
          .filter((filter) => filter.conditional && filter.enabled)
          .map((filter) => {
            return filter.filterColumn;
          });
      }
      return request;
    },
    onLoadNextPage({ clicked_page }) {
      this.getData(this.getRequest(clicked_page));
    },
    onPrepareData() {
      this.isPreparing = true;

      let data = [];
      for (const row of this.tableRows) {
        let tableRow = new Row({ raw: row });
        /** @param {Column} column */
        for (const column of this.tableColumns) {
          tableRow.addCell(new Cell({ column, value: row }));
          //tableRow.raw[column.id] = row[column.id];
        }
        data.push(tableRow);
      }
      this.rows = data;
      this.isPreparing = false;
    },
    /**
     * @param {Row} row
     * @param event
     */
    onRowClicked(row, event) {
      this.$emit(events.ON_TABLE_ROW_CLICKED, { ...row.raw, event });
    },
    /**
     * @param {Row} row
     * @param event
     */
    onShowActionClicked(row, event) {
      this.$emit(events.ON_TABLE_SHOW_ACTION_CLICKED, { ...row.raw, event });
    },
    /**
     * @param {Row} row
     * @param event
     */
    onEditActionClicked(row, event) {
      this.$emit(events.ON_TABLE_EDIT_ACTION_CLICKED, { ...row.raw, event });
    },
    /**
     * @param {Row} row
     * @param event
     */
    onRemoveActionClicked(row, event) {
      this.$emit(events.ON_TABLE_REMOVE_ACTION_CLICKED, { ...row.raw, event });
    },
    /**
     * @param {Column} column
     */
    onSortColumnClicked(column) {
      if (column.canSort) {
        column.getNextSorting();
        this.getData(this.getRequest(this.paginationOptions.currentPage));
      }
    },
    searchingColumn(value) {
      let columns = [];
      if (!this.$isset(value) || value.length === 0) return columns;
      this.tableColumns.forEach((column) => {
        if (column.isSearching) {
          columns.push(new SearchingColumn(column.key, value));
        }
      });
      return columns;
    },
    getSearchingFromSavedStorage() {
      let searchingData = this.searchingDataManager.getSearchingData();
      if (this.$isset(searchingData)) {
        this.quickSearching = searchingData.searching;
        this.advancedSearch = searchingData.advancedSearch;
        return true;
      }
      return false;
    },
    getSortingColumnFromSavedStorage() {
      let sortingColumns = this.sortingDataManager.getSortingColumns();
      for (let sortingColumn of sortingColumns) {
        let column = this.tableColumns.find((x) => x.key === sortingColumn.key);
        if (this.$isset(column)) {
          column.setSorting(sortingColumn.direction);
        }
      }
    },
    getFilteringColumnFromSavedStorage() {
      let filteringColumns = this.filteringDataManager.getFilteringColumns();
      if (filteringColumns.length === 0 && this.initFilters.length > 0) {
        //save initialFilters
        this.filteringDataManager.setFilteringColumns(this.initFilters);
        filteringColumns = this.initFilters.map((x) => x.clone);
      }

      if (filteringColumns.length > 0) {
        this.filters = filteringColumns;
        EventBus.fire(
          events.ON_RESTORE_FILTERS_FROM_STORAGE,
          new FiltersUpdatedTransfer({
            key: this.saveStorageKey,
            filters: filteringColumns,
          })
        );
      }
    },
    sortingColumn() {
      let columns = [];

      this.tableColumns.forEach((column, index) => {
        if (!column.isSortDisable)
          columns.push(new SortingColumn(index, column.key, column.sort));
      });
      if (this.saveSortingToStore) {
        //save sorting information to storage
        let columnsForSave = columns.map((x) => x.forJson);
        this.sortingDataManager.setSortingColumns(columnsForSave);
      }

      return columns;
    },
    onErrorData(error) {
      console.error("onErrorData", error);
    },
    handlerDraggable(event) {
      if (event.moved) {
        this.$emit(events.ROW_DRAGGABLE_MOVED, {
          replacedData: this.relatedDraggableRow.getRaw,
          data: event.moved.element.getRaw,
          oldIndex: event.moved.oldIndex,
          newIndex: event.moved.newIndex,
          sorting: event.moved.newIndex,
        });
      }
    },
    handlerMove(event) {
      let sorting = event.draggedContext.element.raw?.sorting ?? -1;
      console.log(sorting);
      if (sorting !== -1) {
        this.relatedDraggableRow = this.rows[event.relatedContext.index];
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.td-align-right {
  text-align: right;
}

.handle {
  color: lightgray;
}
</style>
