var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("select-field", {
    attrs: {
      title: _vm.$t("pages.orders.order_status"),
      id: _vm.id,
      name: _vm.name,
      form: _vm.form,
      label: _vm.optionLabel,
      options: _vm.options,
      multiple: _vm.multiple,
      required: _vm.required,
      selectable: function (option) {
        return parseInt(option.is_active) == 1
      },
    },
    scopedSlots: _vm._u([
      {
        key: "no-options",
        fn: function (ref) {
          var search = ref.search
          var searching = ref.searching
          return [
            searching
              ? [
                  _vm._v(
                    _vm._s(_vm.$t("pages.orders.statuses.no_option_founded"))
                  ),
                ]
              : _c("em", { staticStyle: { opacity: "0.5" } }, [
                  _vm._v(_vm._s(_vm.$t("pages.orders.statuses.status_search"))),
                ]),
          ]
        },
      },
      {
        key: "option",
        fn: function (option) {
          return [
            _c(
              "div",
              { staticStyle: { margin: "2px" } },
              [
                _c("order-status-formatted-field", {
                  attrs: { status: option, showTitle: false },
                }),
                _vm._v(" "),
                _c("span", { staticStyle: { "margin-left": "5px" } }, [
                  _vm._v(_vm._s(option["name"])),
                ]),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }