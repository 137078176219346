<template>
  <div>
    {{ $t('filters.field') }} "{{ $t(column.name) }}"
    <span
      class="text-bold"
    >{{ $t(`filters.operation.${filterOperation}`) }}</span>
    <span>{{ `"${filterValue}"` }}</span>

    <vue-my-table-filter-operator :operator="filter.filterColumn.operator" v-if="showOperator"></vue-my-table-filter-operator>
  </div>
</template>

<script>
import { ListFilterField, Column } from "../index";
import VueMyTableFilterOperator from "./VueMyTableFilterOperator";

export default {
  props: {
    column: {
      type: Column,
      required: true
    },
    /**
     * @property {ListFilterField} filter
     */
    filter: {
      type: ListFilterField,
      required: true
    },
    showOperator: {
      type: Boolean,
      default: true
    }
  },
  components: {
    VueMyTableFilterOperator
  },
  computed: {
    value() {
      return this.filter.filterColumn.value;
    },
    filterOperation() {
      return this.filter.filterColumn.filterOperation;
    },
    filterValue() {
      return this.filter.filterColumn.filterValue.join(", ");
    }
  }
};
</script>

<style>
</style>