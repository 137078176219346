<template>
  <div class="card vh-100" :class="{ 'sk-loading': showSpinner }" :style="cardStyles">
    <div class="sk-spinner sk-spinner-double-bounce" v-if="showSpinner">
      <div class="sk-double-bounce1"></div>
      <div class="sk-double-bounce2"></div>
    </div>

    <div class="card-header" v-if="showHeader">
      <button
        type="button"
        class="btn btn-sm btn-primary"
        @click="createButtonClicked"
        v-if="showCreateButton"
      >
        <i class="fa fa-lg fa-plus"></i>
        {{ createButtonTitle }}
      </button>

      <div class="card-tools">
        <a href="#" v-if="showHideButton">
          <i
            class="far mr-1"
            :class="{ 'fa-eye': !showBody, 'fa-eye-slash': showBody }"
            @click="showBody = !showBody"
          ></i>
        </a>
        <span v-if="showTitle">{{ title }}</span>
      </div>
    </div>

    <div class="card-body table-responsive p-0 mb-3" v-if="showBody">
      <template v-if="!treeData.empty">
        <vue-my-tree-node
          :node="treeData.root"
          :tree="treeData"
          :showMoveTreeNode="showMoveButton"
          :showMenuTreeNode="showMenuButton"
          :showAddTreeNode="showCreateButton"
          :showEditTreeNode="showEditButton"
          :showRemoveTreeNode="showRemoveButton"
          @[onRemoveTreeNodeEvent]="removeTreeNode"
          @[onAddTreeNodeEvent]="addTreeNode"
          @[onEditTreeNodeEvent]="editTreeNode"
          @[onInfoTreeNodeEvent]="infoTreeNode"
          @[onMoveTreeNodeEvent]="moveTreeNode"
          v-bind="$attrs"
          class="mb-3"
        >
          <!-- Pass on all named slots -->
          <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
          <!-- Pass on all scoped slots -->
          <template
            v-for="slot in Object.keys($scopedSlots)"
            :slot="slot"
            slot-scope="scope"
          >
            <slot :name="slot" v-bind="scope" />
          </template>
        </vue-my-tree-node>
      </template>
      <template v-else>
        <div class="text-center m-4" v-if="!showSpinner">{{ emptyMessage }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import { Tree } from "./tree-components";
import VueMyTreeNode from "./VueMyTreeNode.vue";

import * as events from "./tree-events";

/**
 * :show-add-tree-node=false|true
 * :show-edit-tree-node=false|true
 * :show-info-tree-node=false|true
 * :show-remove-tree-node=false|true
 */
export default {
  name: "TreeComponent",
  props: {
    title: {
      type: String,
      default: "",
    },
    showTitle: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },

    treeData: {
      type: Tree,
      required: true,
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    showHideButton: {
      type: Boolean,
      default: false,
    },
    showMoveButton: {
      type: Boolean,
      default: true,
    },
    showMenuButton: {
      type: Boolean,
      default: true,
    },
    showCreateButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    showRemoveButton: {
      type: Boolean,
      default: true,
    },
    createButtonTitle: {
      type: String,
      default: "Add",
    },
    emptyMessage: {
      type: String,
      default: "No data",
    },
    minHeight: {
      type: [Number, String],
      default: 250,
    },
  },
  components: {
    VueMyTreeNode,
  },
  data() {
    return {
      showBody: true,
      list: {
        root: {
          name: "root",
          children: [
            {
              key: 1,
              data: { name: "task 1" },
              children: [],
            },
            {
              key: 2,
              data: { name: "task 3" },
              children: [
                {
                  key: 3,
                  data: { name: "task 5" },
                  children: [],
                },
              ],
            },
          ],
        },
      },
    };
  },
  computed: {
    onRemoveTreeNodeEvent() {
      return events.REMOVE_TREE_NODE_EVENT;
    },
    onAddTreeNodeEvent() {
      return events.ADD_TREE_NODE_EVENT;
    },
    onEditTreeNodeEvent() {
      return events.EDIT_TREE_NODE_EVENT;
    },
    onInfoTreeNodeEvent() {
      return events.INFO_TREE_NODE_EVENT;
    },
    onMoveTreeNodeEvent() {
      return events.MOVE_TREE_NODE_EVENT;
    },
    cardStyles() {
      return this.showBody ? { "min-height": `${this.minHeight}px` } : {};
    },
  },
  methods: {
    createButtonClicked() {
      this.$emit(events.CREATE_BUTTON_TREE_COMPONENT_EVENT);
    },
    /**
     * @param {NodeData} data
     */
    removeTreeNode(data) {
      this.$emit(events.REMOVE_TREE_COMPONENT_EVENT, data);
    },
    /**
     * @param {NodeData} data
     */
    editTreeNode(data) {
      this.$emit(events.EDIT_TREE_COMPONENT_EVENT, data);
    },
    /**
     * @param {NodeData} data
     */
    addTreeNode(data) {
      this.$emit(events.ADD_TREE_COMPONENT_EVENT, data);
    },
    infoTreeNode(data) {
      this.$emit(events.INFO_TREE_COMPONENT_EVENT, data);
    },
    moveTreeNode(data) {
      this.$emit(events.MOVE_TREE_COMPONENT_EVENT, data);
    },
  },
};
</script>

<style scoped>
.list-icon-none {
  list-style-type: none;
}
</style>