var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("date-picker", {
        attrs: {
          id: _vm.id,
          popover: { visibility: "click" },
          locale: _vm.locale,
        },
        model: {
          value: _vm.form[_vm.name],
          callback: function ($$v) {
            _vm.$set(_vm.form, _vm.name, $$v)
          },
          expression: "form[name]",
        },
      }),
      _vm._v(" "),
      _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }