var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    _vm._g(
      _vm._b({ staticClass: "dropdown-item pl-0" }, "a", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c("i", { staticClass: "nav-icon fa fa-trash p2" }),
      _vm._v("\n  " + _vm._s(_vm.$t("actions.remove")) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }