// work-shops
export const WORK_SHOP_SHOW_CREATE_MODAL = "show-create-modal-work-shop";
export const WORK_SHOP_SHOW_EDIT_MODAL = "show-edit-modal-work-shop";
export const WORK_SHOP_SHOW_REMOVE_MODAL = "show-remove-modal-work-shop";
export const WORK_SHOP_INFO_UPDATED = "on-work-shop-info-updated";
export const WORK_SHOP_SHOW_FILTERS_MODAL = "show-work-shop-filters-model";

// discount-cards
export const DISCOUNT_CARD_SHOW_CREATE_MODAL =
    "discount-card-show-create-modal";
export const DISCOUNT_CARD_SHOW_EDIT_MODAL = "discount-card-show-edit-modal";
export const DISCOUNT_CARD_SHOW_REMOVE_MODAL =
    "discount-card-show-remove-modal";
export const DISCOUNT_CARD_CREATED = "on-discount-card-created";
export const DISCOUNT_CARD_INFO_UPDATED = "on-discount-card-info-updated";

export const DELIVERY_DATE_SHOW_CREATE_MODAL =
    "delivery-date-show-create-modal";
export const DELIVERY_DATE_SHOW_EDIT_MODAL = "delivery-date-show-edit-modal";
export const DELIVERY_DATE_SHOW_REMOVE_MODAL =
    "delivery-date-show-remove-modal";
export const DELIVERY_DATE_INFO_UPDATED = "on-delivery-date-info-updated";

//clients
export const CLIENT_SHOW_CREATE_MODAL = "client-show-create-modal";
export const CLIENT_SHOW_EDIT_MODAL = "client-show-edit-modal";
export const CLIENT_SHOW_CLIENT_INFO_MODAL = "client-show-client-info-modal";
export const CLIENT_SHOW_REMOVE_MODAL = "client-show-remove-modal";
export const CLIENT_CREATED = "on-client-created";
export const CLIENT_INFO_UPDATED = "on-client-info-updated";
export const SHOW_CLIENT_FILTERS_MODAL = "show-client-filters-model";
export const CLIENT_ADDRESS_SHOW_CREATE_MODAL =
    "client-address-show-create-modal";
export const CLIENT_ADDRESS_SHOW_EDIT_MODAL = "client-address-show-edit-modal";
export const CLIENT_ADDRESS_SHOW_REMOVE_MODAL =
    "client-address-show-remove-modal";
export const CLIENT_ADDRESS_CREATED = "on-client-address-created";
export const CLIENT_ADDRESS_INFO_UPDATED = "client-address-info-updated";

//foremen
export const FOREMAN_SHOW_CREATE_MODAL = "foreman-show-create-modal";
export const FOREMAN_SHOW_EDIT_MODAL = "foreman-show-edit-modal";
export const FOREMAN_SHOW_INFO_MODAL = "foreman-show-info-modal";
export const FOREMAN_INFO_UPDATED = "on-foreman-info-updated";
export const FOREMAN_SHOW_REMOVE_MODAL = "foreman-show-remove-modal";
export const FOREMAN_SHOW_ADD_SERVICES_MODAL =
    "foreman-show-add-services-modal";
export const FOREMAN_INFO_SERVICES_UPDATED = "on-foreman-info-services-updated";
export const FOREMAN_SERVICES_SHOW_REMOVE_MODAL =
    "foreman-services-show-remove-modal";

export const FOREMAN_SHOW_ADD_MY_SERVICES_MODAL =
    "foreman-show-add-my-services-modal";
export const FOREMAN_INFO_MY_SERVICES_UPDATED =
    "on-foreman-info-my-services-updated";
export const FOREMAN_MY_SERVICES_SHOW_REMOVE_MODAL =
    "foreman-my-services-show-remove-modal";

export const FOREMAN_FOR_CLIENT_SHOW_SERVICES_MODAL =
    "foreman-for-client-show-services-modal";

//couriers
export const COURIER_SHOW_CREATE_MODAL = "courier-show-create-modal";
export const COURIER_SHOW_EDIT_MODAL = "courier-show-edit-modal";
export const COURIER_SHOW_INFO_MODAL = "courier-show-info-modal";
export const COURIER_SHOW_REMOVE_MODAL = "courier-show-remove-modal";
export const COURIER_INFO_UPDATED = "on-courier-info-updated";

//services
export const SERVICE_SHOW_CREATE_MODAL = "service-show-create-modal";
export const SERVICE_SHOW_EDIT_MODAL = "service-show-edit-modal";
export const SERVICE_SHOW_REMOVE_MODAL = "service-show-remove-modal";
export const SERVICE_INFO_UPDATED = "on-services-info-updated";
export const SHOW_SERVICE_FILTERS_MODAL = "on-show-service-filters-modal";
export const SERVICE_SHOW_FILTERS_MODAL = "service-show-filters-model";

//articles
export const ARTICLE_SHOW_CREATE_MODAL = "article-show-create-modal";
export const ARTICLE_SHOW_EDIT_MODAL = "article-show-edit-modal";
export const ARTICLE_SHOW_REMOVE_MODAL = "article-show-remove-modal";
export const ARTICLE_INFO_UPDATED = "on-articles-info-updated";
export const ARTICLE_SHOW_FILTERS_MODAL = "article-show-filters-model";

//users
export const USER_SHOW_CREATE_MODAL = "user-show-create-modal";
export const USER_SHOW_EDIT_MODAL = "user-show-edit-modal";
export const USER_SHOW_REMOVE_MODAL = "user-show-remove-modal";
export const USER_INFO_UPDATED = "on-user-info-updated";
export const USER_SHOW_FILTERS_MODAL = "user-show-filters-model";

//tags
export const TAG_SHOW_CREATE_MODAL = "tag-show-create-modal";
export const TAG_SHOW_EDIT_MODAL = "tag-show-edit-modal";
export const TAG_SHOW_REMOVE_MODAL = "tag-show-remove-modal";
export const TAG_SHOW_CREATE_ITEM_MODAL = "tag-show-create-item-modal";
export const TAG_INFO_UPDATED = "on-tags-info-updated";

//towns
export const TOWN_SHOW_CREATE_MODAL = "town-show-create-modal";
export const TOWN_SHOW_EDIT_MODAL = "town-show-edit-modal";
export const TOWN_SHOW_REMOVE_MODAL = "town-show-remove-modal";
export const TOWN_INFO_UPDATED = "on-towns-info-updated";

//metro stations
export const METRO_STATION_SHOW_CREATE_MODAL =
    "metro-station-show-create-modal";
export const METRO_STATION_SHOW_EDIT_MODAL = "metro-station-show-edit-modal";
export const METRO_STATION_SHOW_REMOVE_MODAL =
    "metro-station-show-remove-modal";
export const METRO_STATION_INFO_UPDATED = "on-metro-stations-info-updated";

// orders
export const SHOW_ORDER_SERVICE_LINE_MODAL = "show-order-service-line-model";
export const ORDER_SERVICE_LINE_UPDATED = "order-service-line-updated";
export const SHOW_ORDER_ARTICLE_LINE_MODAL = "show-order-article-line-model";
export const SHOW_ORDER_FILTERS_MODAL = "show-order-filters-model";
export const SHOW_ORDER_TRANSFER_SERVICE_TO_FOREMAN =
    "show-order-transfer-service-to-foreman";
export const ORDER_ARTICLE_LINE_UPDATED = "order-article-line-updated";
export const ORDER_SHOW_REMOVE_MODAL = "order-show-remove-modal";
export const ORDER_INFO_UPDATED = "order-info-updated";
export const ORDER_STATUS_SHOW_CREATE_MODAL = "order-status-show-create-modal";
export const ORDER_STATUS_SHOW_EDIT_MODAL = "order-status-show-edit-modal";
export const ORDER_STATUS_SHOW_REMOVE_MODAL = "order-status-show-remove-modal";
export const ORDER_STATUS_INFO_UPDATED = "on-order-status-info-updated";
export const ORDER_STATUS_SHOW_HISTORY_MODAL = "town-show-create-modal";
export const ORDER_CREATE_CONTROL_SIDEBAR_OPEN =
    "order-create-control-sidebar-open";
export const ORDER_CONTROL_SIDEBAR_CHANGED_FOREMAN =
    "order-control-sidebar-changed-foreman";

// control sidebar
export const CONTROL_SIDEBAR_CLOSE = "control-sidebar-close";
