var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", [
        _vm._v("\n    " + _vm._s(_vm.$t("pages.orders.service")) + "\n    "),
        _vm.required
          ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { ref: _vm.categoriesIds }),
      _vm._v(" "),
      _vm.showItems
        ? _c("select-field", {
            attrs: {
              id: "manage-service-name",
              name: _vm.name,
              form: _vm.form,
              label: "name",
              options: _vm.items,
            },
            on: { input: _vm.onChangeItem },
            scopedSlots: _vm._u(
              [
                {
                  key: "no-options",
                  fn: function () {
                    return [_vm._v(_vm._s(_vm.$t("fields.no_options")))]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2012668492
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }