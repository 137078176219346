var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "toogle-field",
    _vm._g(
      _vm._b(
        {
          attrs: {
            title: _vm.$t("pages.orders.use_foreman_by_line"),
            inline: true,
          },
        },
        "toogle-field",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }