var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("loading", { ref: "loading" }),
      _vm._v(" "),
      _c(
        "transition",
        { attrs: { name: "page", mode: "out-in" } },
        [_vm.layout ? _c(_vm.layout, { tag: "component" }) : _vm._e()],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }