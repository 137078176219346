<template>
  <div>
    {{ $t("filters.field") }} "{{ $t(column.name) }}"
    <template v-if="date2 === null">
      <span class="text-bold">{{ $t(`filters.operation.=`) }}</span>
      <formatted-date-field :val="date1"></formatted-date-field>
    </template>

    <template v-if="date1 !== null && date2 !== null">
      <span class="text-bold">{{ $t("filters.operation.from") }}</span>
      <formatted-date-field :val="date1"></formatted-date-field>
      <span class="text-bold">{{ $t("filters.operation.to") }}</span>
      <formatted-date-field :val="date2"></formatted-date-field>
    </template>

    <vue-my-table-filter-operator
      :operator="filter.filterColumn.operator"
      v-if="showOperator"
    ></vue-my-table-filter-operator>
  </div>
</template>

<script>
import { DateRangeFilterField, Column } from "../index";
import { FormattedDateField } from "../../form";
import VueMyTableFilterOperator from "./VueMyTableFilterOperator";

export default {
  props: {
    column: {
      type: Column,
      required: true,
    },
    filter: {
      type: DateRangeFilterField,
      required: true,
    },
    showOperator: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    FormattedDateField,
    VueMyTableFilterOperator,
  },
  computed: {
    date1() {
      let data = this.getData();
      return data.length > 0 ? data[0] : null;
    },
    date2() {
      let data = this.getData();
      return data.length === 2 ? data[1] : null;
    },
  },
  methods: {
    getData() {
      return this.filter.filterColumn.value;
    },
  },
};
</script>

<style>
</style>