var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _c("label", { attrs: { for: _vm.id } }, [_vm._v(_vm._s(_vm.title))]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("date-picker", {
              attrs: {
                id: _vm.id,
                popover: { visibility: "click" },
                locale: _vm.locale,
              },
              model: {
                value: _vm.dateData,
                callback: function ($$v) {
                  _vm.dateData = $$v
                },
                expression: "dateData",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-6" },
          [
            _c("vue-timepicker", {
              attrs: { "auto-scroll": "", format: _vm.timeFormat },
              model: {
                value: _vm.timeData,
                callback: function ($$v) {
                  _vm.timeData = $$v
                },
                expression: "timeData",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }