<template>
  <div v-if="showFastMenu" class="dropdown">
    <a
      class="nav-link"
      id="dropDownFastMenu"
      data-toggle="dropdown"
      aria-expanded="false"
    >
      <i class="fas fa-plus-square"></i>
    </a>
    <div class="dropdown-menu" aria-labelledby="dropDownFastMenu">
      <div v-if="showOrder">
        <router-link
          :to="{
            name: 'orders-create',
            params: { work_shop_id: currentWorkShopId },
          }"
          class="dropdown-item"
        >
          <span>
            <i class="nav-icon fas fa-folder"></i>
            {{ $t("pages.orders.create_order") }}
          </span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("auth", ["hasRole", "hasPermission", "ordersEnabled"]),
    ...mapGetters("auth", ["currentWorkShopId"]),
    showFastMenu() {
      return true;
    },
    showOrder() {
      return this.ordersEnabled && this.hasPermission("create_order");
    },
  },
};
</script>

<style>
</style>