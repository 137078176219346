import Cookies from "js-cookie";
import { TOGGLE_SIDEBAR } from "../mutation-types";

// state
export const state = {
    sidebar:
        Cookies.get("sidebar") !== null && Cookies.get("sidebar") !== undefined
            ? JSON.parse(Cookies.get("sidebar"))
            : true
};

// getters
export const getters = {
    sidebar: state => state.sidebar,
    isShowSidebar: state => {
        return state.sidebar;
    }
};

// mutations
export const mutations = {
    [TOGGLE_SIDEBAR](state, { value }) {
        state.sidebar = value;
        Cookies.set("sidebar", state.sidebar, { expires: 365 });
    }
};

//actions
export const actions = {
    saveToggleSideBar({ commit }, payload) {
        commit(TOGGLE_SIDEBAR, payload);
    }
};
