import apiAuth from "~/api/auth";
import apiWorkShop from "~/api/workshops";

import { isset } from "../../utils/functions";
import LocalRepository from "../repository/local-repository";

const repository = new LocalRepository();

// state
export const state = {
    user: repository.getData("user", true),
    currentWorkShop: null,
    defaultWorkShop: repository.getData("defaultWorkShop", true),
    remember: repository.getData("remember"),
    token: repository.getData("token"),
};

// getters
export const getters = {
    user: (state) => state.user,
    userId: (state) => (isset(state.user) ? state.user.id : null),
    remember: (state) => state.remember,
    check: (state) => isset(state.user),
    token: (state) => state.token,
    currentWorkShop: (state) => state.currentWorkShop,
    currentWorkShopId: (state) => {
        return state.currentWorkShop.id;
    },
    defaultWorkshopId: (state) => state.defaultWorkShop.id,
    hasPermission: (state) => (permission) => {
        if (isset(state.user)) {
            for (let perm of state.user.permissions) {
                if (perm === permission) return true;
            }
        }
        return false;
    },
    foremanId: (state) => {
        return state?.user?.foreman_id;
    },
    hasRole: (state) => (role) => {
        if (isset(state.user)) {
            return state.user.roles.slug === role;
        }
        return false;
    },
    roles: (state) => {
        if (isset(state.user)) {
            return state.user.roles;
        }
        return [];
    },
    workShops: (state) => {
        if (isset(state.user)) {
            return state.user.work_shops;
        }
        return 0;
    },
    currency: (state) => {
        if (isset(state.user)) {
            return state.currentWorkShop.currency;
        }
        return null;
    },
    workShop: (state) => {
        return state.currentWorkShop;
    },
    ordersEnabled: (state) => {
        return state.currentWorkShop.orders === 1;
    },
    discountCardsEnabled: (state) => {
        return state.currentWorkShop.discount_cards === 1;
    },
    townsEnabled: (state) => {
        return state.currentWorkShop.towns === 1;
    },

    isCurrentWorkShop: (state) => (work_shop_id) => {
        return isset(state.currentWorkShop)
            ? parseInt(state.currentWorkShop.id) === parseInt(work_shop_id)
            : false;
    },
    isSuperAdmin: (state, getters) => {
        return getters.roles.map((x) => x.slug).includes("super_admin");
    },
    isAdmin: (state, getters) => {
        return getters.roles.map((x) => x.slug).includes("admin");
    },
    isCollaborator: (state, getters) => {
        return getters.roles.map((x) => x.slug).includes("collaborator");
    },
    isForeman: (state, getters) => {
        return getters.roles.map((x) => x.slug).includes("foreman");
    },
    isCourier: (state, getters) => {
        return getters.roles.map((x) => x.slug).includes("courier");
    },
    isClient: (state, getters) => {
        return getters.roles.map((x) => x.slug).includes("client");
    },
    isProjectManager: (state, getters) => {
        return getters.roles.map((x) => x.slug).includes("project_manager");
    },
    avatar: (state) => {
        if (isset(state.user)) {
            return state.user.avatar.length > 0
                ? state.user.avatar
                : "/img/avatar5.png";
        }
        return "";
    },
    generateSortingKey: (_, getters) => (key) => {
        return `${key}_${getters.user.id}_${getters.currentWorkShopId}`;
    },
};

// mutations
export const mutations = {
    UPDATE_USER(state, { user, remember }) {
        state.user = user;
        state.defaultWorkShop = user.default_work_shop;
        repository.setData("user", user, true);
        repository.setData("remember", remember);
        repository.setData("defaultWorkShop", user.default_work_shop, true);
    },

    LOGOUT(state) {
        state.user = null;
        state.remember = null;

        repository.removeData("token");
        repository.removeData("remember");
        repository.removeData("user");
        repository.removeData("defaultWorkShop");
    },
    SAVE_AUTH_DATA(state, { token }) {
        state.token = token;
        repository.setData("token", token);
    },
    SET_CURRENT_WORK_SHOP(state, work_shop) {
        state.currentWorkShop = work_shop;
    },
};

// actions
export const actions = {
    saveAuthData({ commit }, payload) {
        commit("SAVE_AUTH_DATA", payload);
    },

    saveUser({ commit }, payload) {
        commit("UPDATE_USER", payload);
    },

    async logout({ commit }) {
        await apiAuth.logout();
        commit("LOGOUT");
    },

    async getWorkShop({ commit }, { work_shop_id }) {
        const { data } = await apiWorkShop.getWorkshop({ id: work_shop_id });

        commit("SET_CURRENT_WORK_SHOP", data);
    },
};
