var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "editor m-0 p-0",
      class: { "editor-border": _vm.showBorder },
    },
    [
      _c("editor-content", {
        staticClass: "editor__content m-0 p-0",
        attrs: { editor: _vm.editor },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }