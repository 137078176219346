var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "toggle-button",
    _vm._g(
      _vm._b(
        {
          attrs: {
            value: _vm.toggleValue,
            labels: {
              checked: _vm.$t("states.yes"),
              unchecked: _vm.$t("states.no"),
            },
            width: _vm.width,
          },
        },
        "toggle-button",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }