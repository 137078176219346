<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
import { format } from "date-fns";
// eslint-disable-next-line no-unused-vars
import { enUS, ru } from "date-fns/locale";

import { mapGetters } from "vuex";

export default {
  name: "FormattedDateField",
  props: {
    val: {
      required: true
    },
    withTime: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("lang", ["dateFormat"]),
    getTimeFormat() {
      return this.withTime ? " HH:mm" : "";
    },
    formattedValue() {
      return this.$isset(this.val)
        ? format(
            this.val instanceof Date ? this.val : this.$parseDate(this.val),
            `${this.dateFormat}${this.getTimeFormat}`
          )
        : "";
    }
  }
};
</script>

<style>
</style>