/* eslint-disable no-undef */
import Vue from "vue";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import store from "./store";

if (process.env.MIX_SENTRY_FRONT_DSN) {
    Sentry.init({
        Vue,
        environment: process.env.NODE_ENV,
        release: `${store.getters["app/appVersion"]}`,
        dsn: process.env.MIX_SENTRY_FRONT_DSN,
        integrations: [new Integrations.BrowserTracing()],
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        debug:
            process.env.NODE_ENV !== "production" &&
            process.env.NODE_ENV !== "staged",
        tracesSampleRate: 1.0
    });
}
