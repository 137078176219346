<template>
  <span>{{ deliveryName }}</span>
</template>

<script>
import { mapGetters } from "vuex";
import { TypeDelivery } from "./index";

import { isset } from "../../../utils/functions";

export default {
  props: {
    typeDelivery: {
      type: TypeDelivery,
      required: true
    }
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    deliveryName() {
      return isset(this.typeDelivery)
        ? this.typeDelivery[`${this.locale}name`]
        : "";
    }
  }
};
</script>