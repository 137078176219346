<template>
  <div class="form-group">
    <label :for="id">{{ title }}</label>

    <date-picker v-model="form[name]" :id="id" :popover="{ visibility: 'click' }" :locale="locale"></date-picker>

    <has-error :form="form" :field="name" />
  </div>
</template>

<script>
import Form, { HasError } from "../index";

import { DatePicker } from "v-calendar";
import { mapGetters } from "vuex";

export default {
  name: "DateField",
  components: {
    HasError,
    DatePicker
  },
  props: {
    form: {
      type: Form,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("lang", ["locale"])
  }
};
</script>

<style>
</style>