import Cookies from 'js-cookie'
import { TOGGLE_SERVICE_TAG } from '../mutation-types'

// state
export const state = {
    serviceTag: Cookies.get('serviceTag') !== null && Cookies.get('serviceTag') !== undefined ? JSON.parse(Cookies.get('serviceTag')) : [],
}

// getters
export const getters = {
    serviceTag: state => state.serviceTag,
    isShowServiceTag: state => work_shop_id => {
        let service = state.serviceTag.find(item => parseInt(item.k) === parseInt(work_shop_id));
        if (service !== undefined) {
            return service.v;
        }
        return true;
    }
}

// mutations
export const mutations = {
    [TOGGLE_SERVICE_TAG](state, { work_shop_id, value }) {
        let service = state.serviceTag.find(item => parseInt(item.k) === parseInt(work_shop_id));
        if (service !== undefined) {
            service.v = value;
        } else {
            state.serviceTag.push({ k: work_shop_id, v: value });
        }
        Cookies.set('serviceTag', state.serviceTag, { expires: 365 });
    },
}

export const actions = {
    saveToggleTag({ commit }, payload) {
        commit(TOGGLE_SERVICE_TAG, payload);
    },

};