var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "main-footer" }, [
    _c("div", { staticClass: "float-right d-none d-sm-block" }, [
      _c("b", [_vm._v("v.")]),
      _vm._v(_vm._s(_vm.appVersion)),
      _c("sub", [_vm._v("Beta")]),
      _vm._v(" "),
      _vm.showBuyMeCoffee
        ? _c(
            "a",
            {
              attrs: {
                href: "https://www.buymeacoffee.com/wondertalik",
                target: "_blank",
              },
            },
            [
              _c("img", {
                staticStyle: { height: "35px" },
                attrs: {
                  src: "https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=wondertalik&button_colour=5F7FFF&font_colour=ffffff&font_family=Comic&outline_colour=000000",
                },
              }),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c("strong", [_vm._v("Copyright © " + _vm._s(new Date().getFullYear()))]),
    _vm._v("\n  All rights reserved.\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }