<template>
  <div>
    <nav class="main-header navbar navbar-expand navbar-white navbar-light">
      <!-- Left navbar links -->
      <ul class="navbar-nav">
        <li class="nav-item">
          <a
            class="nav-link"
            data-widget="pushmenu"
            href="#"
            @click="toggleSideBar"
          >
            <i class="fas fa-bars"></i>
          </a>
        </li>
        <li class="nav-item">
          <nav-fast-creation-menu class="ml-2" />
        </li>
        <!-- <li class="nav-item d-none d-sm-inline-block">
          <a href="#" class="nav-link">Contact</a>
        </li> -->
      </ul>

      <!-- SEARCH FORM -->
      <!-- <form class="form-inline ml-3">
        <div class="input-group input-group-sm">
          <input
            class="form-control form-control-navbar"
            type="search"
            placeholder="Search"
            aria-label="Search"
          />
          <div class="input-group-append">
            <button class="btn btn-navbar" type="submit">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </form>-->

      <!-- Right navbar links -->
      <ul class="navbar-nav ml-auto">
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            data-toggle="dropdown"
            @click="showModalWorkShopList"
          >
            <i class="fas fa-store-alt"></i>
            <span class="d-none d-md-inline">{{ workShopName }}</span>
          </a>
        </li>
        <!-- Notifications Dropdown Menu -->
        <!--            <li class="nav-item dropdown">-->
        <!--                <a class="nav-link" data-toggle="dropdown" href="#">-->
        <!--                    <i class="far fa-bell"></i>-->
        <!--                    <span class="badge badge-warning navbar-badge">15</span>-->
        <!--                </a>-->
        <!--                <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">-->
        <!--                    <span class="dropdown-item dropdown-header">15 Notifications</span>-->
        <!--                    <div class="dropdown-divider"></div>-->
        <!--                    <a href="#" class="dropdown-item">-->
        <!--                        <i class="fas fa-envelope mr-2"></i> 4 new messages-->
        <!--                        <span class="float-right text-muted text-sm">3 mins</span>-->
        <!--                    </a>-->
        <!--                    <div class="dropdown-divider"></div>-->
        <!--                    <a href="#" class="dropdown-item">-->
        <!--                        <i class="fas fa-users mr-2"></i> 8 friend requests-->
        <!--                        <span class="float-right text-muted text-sm">12 hours</span>-->
        <!--                    </a>-->
        <!--                    <div class="dropdown-divider"></div>-->
        <!--                    <a href="#" class="dropdown-item">-->
        <!--                        <i class="fas fa-file mr-2"></i> 3 new reports-->
        <!--                        <span class="float-right text-muted text-sm">2 days</span>-->
        <!--                    </a>-->
        <!--                    <div class="dropdown-divider"></div>-->
        <!--                    <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>-->
        <!--                </div>-->
        <!--            </li>-->

        <!-- Language Dropdown Menu -->
        <li class="nav-item dropdown">
          <a class="nav-link" data-toggle="dropdown" href="#">
            <i class="flag-icon" :class="activeIconClassLocale"></i>
          </a>

          <div class="dropdown-menu dropdown-menu-right p-0">
            <a
              href="#"
              class="dropdown-item"
              :class="{ active: lang === locale }"
              v-for="lang of locales"
              @click.prevent="changeLang(lang)"
              :key="lang"
            >
              <i class="flag-icon mr-2" :class="iconClassLocale(lang)"></i>
              {{ $t(`locales.${lang}`) }}
            </a>
          </div>
        </li>

        <nav-user-menu></nav-user-menu>

        <!-- <li class="nav-item">
          <a class="nav-link" data-widget="control-sidebar" data-slide="true" href="#">
            <i class="fas fa-th-large"></i>
          </a>
        </li>-->
      </ul>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { changeLocale } from "../plugins/i18n";
import NavUserMenu from "./NavUserMenu";
import NavFastCreationMenu from "./NavFastCreationMenu.vue";

import { mapActions } from "vuex";

export default {
  name: "NavBar",
  components: {
    NavUserMenu,
    NavFastCreationMenu,
  },
  data() {
    return {};
  },
  computed: {
    activeIconClassLocale() {
      return this.iconClassLocale(this.locale);
    },
    workShopName() {
      return this.workShop !== null ? this.workShop.name : "";
    },
    ...mapGetters("lang", ["locales", "locale"]),
    ...mapGetters("auth", ["workShop", "workShops"]),
    ...mapGetters("settings", ["isShowSidebar"]),
    ...mapGetters("auth", ["currentWorkShopId"]),
  },
  methods: {
    ...mapActions("settings", ["saveToggleSideBar"]),
    toggleSideBar() {
      this.saveToggleSideBar({
        value: !this.isShowSidebar,
      });
    },
    showModalWorkShopList() {
      if (this.workShops > 1 && this.$route.name !== "work-shops") {
        this.$router.push({
          name: "work-shops",
          params: { work_shop_id: this.currentWorkShopId },
        });
      }
    },
    iconClassLocale(lang) {
      const acronym = lang === "en" ? "us" : "ru";
      return `flag-icon-${acronym}`;
    },
    async changeLang(locale) {
      this.$store.dispatch("lang/setLocale", { locale: locale });
      await changeLocale(locale);
    },
  },
};
</script>

<style scoped>
</style>
