import axios from "axios";
import store from "~/store";
import router from "~/router";

import { LOGOUT } from "../store/mutation-types";
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.put["Accept"] = "application/json";
axios.defaults.headers.delete["Accept"] = "application/json";
axios.defaults.headers.get["Accept"] = "application/json";

// Request interceptor
axios.interceptors.request.use(request => {
    const token = store.getters["auth/token"];
    if (token) {
        request.headers.common["Authorization"] = `Bearer ${token}`;
    }

    const locale = store.getters["lang/locale"];
    if (locale) {
        request.headers.common["Accept-Language"] = locale;
    }

    return request;
});

axios.interceptors.response.use(
    response => response,
    error => {
        const { data, status } = error.response;

        // if (status === 404) {
        //     console.error(data.message);
        //     alert(404);
        //     router.push({ name: "404-not-found" });
        // }
        //else if (status === 500) {
        //     console.error(data.message);
        //     router.push({ name: "500-error" });
        // }
        //else
        if (status === 401) {
            console.error(data.message);
            store.commit("auth/" + LOGOUT);
            router.push({ name: "login" });
        }

        return Promise.reject(error);
    }
);
