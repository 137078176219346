import Vue from "vue";
import { arrayWrap } from "../utils/functions";

export default class EventBus {
    constructor() {
        this.vue = new Vue();
    }

    fire(events, data = null) {
        events = arrayWrap(events);
        for (let event of events) {
            this.vue.$emit(event, data);
        }
    }

    listen(events, callback) {
        this.vue.$on(events, callback);
    }

    off(events, callback) {
        this.vue.$off(events, callback);
    }
}
