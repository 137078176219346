import OrderStatusField from "./OrderStatusField.vue";
import TypeDeliveryField from "./TypeDeliveryField.vue";
import OrderStatusFormattedField from "./OrderStatusFormattedField.vue";
import OrderTypeDeliveryFormattedField from "./OrderTypeDeliveryFormattedField.vue";
import CategoryServiceField from "./CategoryServiceField.vue";
import CategoryArticleField from "./CategoryArticleField.vue";
import PriceField from "./PriceField.vue";
import QuantityField from "./QuantityField.vue";
import DiscountField from "./DiscountField.vue";
import OrderDateField from "./OrderDateField.vue";
import OrderDeliveryDateField from "./OrderDeliveryDateField.vue";
import ForemanByLineToogleField from "./ForemanByLineToogleField.vue";
import DiscountTypeField from "./DiscountTypeField.vue";

import {
    OrderStatus,
    Order,
    TypeDelivery,
    Foreman,
    Courier,
    User,
    OrderStatusHistory,
} from "./fields-components";

const ON_CHANGE_ORDER_ITEM_EVENT = "on-change-order-item-event";

export {
    OrderStatusField,
    TypeDeliveryField,
    OrderStatusFormattedField,
    OrderTypeDeliveryFormattedField,
    PriceField,
    QuantityField,
    DiscountField,
    CategoryServiceField,
    CategoryArticleField,
    OrderDateField,
    OrderDeliveryDateField,
    ForemanByLineToogleField,
    DiscountTypeField,
    Order,
    TypeDelivery,
    OrderStatus,
    Foreman,
    Courier,
    User,
    OrderStatusHistory,
    ON_CHANGE_ORDER_ITEM_EVENT
};
