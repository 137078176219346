require("./bootstrap");
require("./firebase");

import Vue from "vue";
import VueMeta from "vue-meta";
import store from "~/store";
import router from "~/router";
import i18n from "~/plugins/i18n";
import App from "~/components/App";
import LoadScript from "vue-plugin-load-script";

import "~/components";
import "~/plugins";

import EventBus from "./components/EventBus";

//register global function
import { isset, deepCopy, parseDate } from "./utils/functions";
Vue.prototype.$isset = function (value) {
    return isset(value);
};
Vue.prototype.$deepCopy = function (value) {
    return deepCopy(value);
};
Vue.prototype.$parseDate = function (value) {
    return parseDate(value);
};

window.EventBus = new EventBus();

require("./sentry");
require("./hello.js");

Vue.config.productionTip = false;

import { mapGetters } from "vuex";

Vue.use(VueMeta);

new Vue({
    i18n,
    store,
    router,
    ...App,
    computed: {
        ...mapGetters("settings", ["isShowSidebar"]),
    },
    created() {
        if (!this.isShowSidebar) {
            document.body.classList.add("sidebar-collapse");
        }
    },
});

Vue.use(LoadScript);
