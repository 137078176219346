// global

// auth.js
export const LOGOUT = "LOGOUT";
export const SAVE_AUTH_DATA = "SAVE_AUTH_DATA";
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAILURE = "FETCH_USER_FAILURE";
export const UPDATE_USER = "UPDATE_USER";
export const CHECK_PERMISSION = "CHECK_PERMISSION";
export const CHECK_ROLE = "CHECK_ROLE";

// lang.js
export const SET_LOCALE = "SET_LOCALE";

// clients.js
export const ADD_CLIENT_TYPE = "ADD_CLIENT_TYPE";
export const ADD_CLIENT_AD_SOURCE = "ADD_CLIENT_AD_SOURCE";
export const ADD_CLIENT_WORKSHOP = "ADD_CLIENT_WORKSHOP";
export const SET_CLIENT_INFO = "SET_CLIENT_INFO";

//project.js
export const TOGGLE_PROJECT_STAGE = "TOGGLE_PROJECT_STAGE";

//services.js
export const TOGGLE_SERVICE_TAG = "TOGGLE_SERVICE_TAG";

//articles.js
export const TOGGLE_ARTICLE_TAG = "TOGGLE_ARTICLE_TAG";

//accounts.js
export const TOGGLE_ACCOUNT_TAG = "TOGGLE_ACCOUNT_TAG";

//settings.js
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR";
