var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      staticClass: "nav-link pl-0 pr-0 dark",
      attrs: { to: { name: _vm.name, params: _vm.params } },
    },
    [
      _c("i", { staticClass: "nav-icon fa fa-edit p2" }),
      _vm._v(_vm._s(_vm.text) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }