var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    _vm._g(
      _vm._b({ staticClass: "dropdown-item pl-0" }, "a", _vm.$attrs, false),
      _vm.$listeners
    ),
    [
      _c(
        "span",
        { staticClass: "p2" },
        [
          _vm._t("icon", function () {
            return [_c("i", { staticClass: "fas fa-bell" })]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm._t("", function () {
        return [_vm._v(_vm._s(_vm.title))]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }