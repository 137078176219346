<template>
  <!-- Sidebar user panel (optional) -->
  <div class="user-panel mt-3 pb-3 mb-3 d-flex">
    <div class="image">
      <img :src="avatar" class="img-circle elevation-2" alt="User Image" />
    </div>
    <div class="info">
      <router-link
        class="d-block"
        :to="{ name: 'profile', params: { work_shop_id: currentWorkShopId } }"
        >{{ userName }}</router-link
      >
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SideBarUserPanel",
  computed: {
    userName() {
      return this.user !== null ? this.user.name : "";
    },
    ...mapGetters("auth", ["remember", "user", "roles", "avatar"]),
    ...mapGetters("auth", ["currentWorkShopId"]),
  },
};
</script>

<style>
</style>