var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("v-select", {
        staticClass: "mb-1",
        attrs: {
          label: _vm.label,
          options: _vm.options,
          getOptionKey: _vm.getOptionKey,
        },
        on: { input: _vm.input },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }