<template>
  <footer class="main-footer">
    <div class="float-right d-none d-sm-block">
      <b>v.</b>{{ appVersion }}<sub>Beta</sub>
      <a
        href="https://www.buymeacoffee.com/wondertalik"
        target="_blank"
        v-if="showBuyMeCoffee"
        ><img
          style="height: 35px"
          src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=&slug=wondertalik&button_colour=5F7FFF&font_colour=ffffff&font_family=Comic&outline_colour=000000"
      /></a>
    </div>
    <strong>Copyright &copy; {{ new Date().getFullYear() }}</strong>
    All rights reserved.
  </footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("app", ["appVersion", "showBuyMeCoffee"]),
  },
};
</script>

<style scoped>
</style>
