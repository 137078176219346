var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.$t("filters.field")) +
          ' "' +
          _vm._s(_vm.$t(_vm.column.name)) +
          '"\n  '
      ),
      _vm.date2 === null
        ? [
            _c("span", { staticClass: "text-bold" }, [
              _vm._v(_vm._s(_vm.$t("filters.operation.="))),
            ]),
            _vm._v(" "),
            _c("formatted-date-field", { attrs: { val: _vm.date1 } }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.date1 !== null && _vm.date2 !== null
        ? [
            _c("span", { staticClass: "text-bold" }, [
              _vm._v(_vm._s(_vm.$t("filters.operation.from"))),
            ]),
            _vm._v(" "),
            _c("formatted-date-field", { attrs: { val: _vm.date1 } }),
            _vm._v(" "),
            _c("span", { staticClass: "text-bold" }, [
              _vm._v(_vm._s(_vm.$t("filters.operation.to"))),
            ]),
            _vm._v(" "),
            _c("formatted-date-field", { attrs: { val: _vm.date2 } }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.showOperator
        ? _c("vue-my-table-filter-operator", {
            attrs: { operator: _vm.filter.filterColumn.operator },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }