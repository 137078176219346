<template>
  <a class="dropdown-item" v-bind="$attrs" v-on="$listeners">
    <i class="nav-icon fa fa-eye"></i>
    {{ text }}
  </a>
</template>

<script>
export default {
  name: "MyInfoDropDownMenuItem",
  props: {
    title: {
      type: [String],
      default: ""
    }
  },
  computed: {
    text() {
      return this.title.length > 0 ? this.title : this.$t("actions.info");
    }
  }
};
</script>

<style>
</style>