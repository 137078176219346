var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-group" },
    [
      _vm.title.length > 0
        ? _c("label", [
            _vm._v("\n    " + _vm._s(_vm.title) + "\n    "),
            _vm.required
              ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "v-select",
        _vm._g(
          _vm._b(
            {
              scopedSlots: _vm._u(
                [
                  _vm._l(Object.keys(_vm.$scopedSlots), function (slot) {
                    return {
                      key: slot,
                      fn: function (scope) {
                        return [_vm._t(slot, null, null, scope)]
                      },
                    }
                  }),
                ],
                null,
                true
              ),
              model: {
                value: _vm.form[_vm.name],
                callback: function ($$v) {
                  _vm.$set(_vm.form, _vm.name, $$v)
                },
                expression: "form[name]",
              },
            },
            "v-select",
            _vm.$attrs,
            false
          ),
          _vm.$listeners
        ),
        [
          _vm._l(Object.keys(_vm.$slots), function (slot) {
            return _vm._t(slot, null, { slot: slot })
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }