<template>
  <div class="icheck-primary">
    <input
      :id="id || name"
      :name="id || name"
      :checked="internalValue"
      type="checkbox"
      @click="handleClick"
      :disabled="disabled"
    />
    <label :for="id || name">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: "CheckBox",

  props: {
    id: {
      type: String,
      default: null,
    },
    name: {
      type: String,
      default: "checkbox",
    },
    value: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    internalValue: false,
  }),

  watch: {
    value(val) {
      this.internalValue = val;
    },

    checked(val) {
      this.internalValue = val;
    },

    internalValue(val, oldVal) {
      if (val !== oldVal) {
        this.$emit("input", val);
      }
    },
  },

  created() {
    this.internalValue = this.value;

    if ("checked" in this.$options.propsData) {
      this.internalValue = this.checked;
    }
  },

  methods: {
    handleClick(e) {
      this.$emit("click", e);

      if (!e.isPropagationStopped) {
        this.internalValue = e.target.checked;
      }
    },
  },
};
</script>
