import { arrayWrap } from "../../utils/functions";
import {
    DateRangeFilterField,
    ListFilterField,
    CheckBoxFilterField,
    FilterColumn
} from "./index";
import { ConditionalFilterColumn } from "./table-components";

/**
 *
 */
export default {
    methods: {
        /**
         * Get filters
         *
         * @param {Array.<FilterField>} fields
         * @param {Object} data
         * @param {Object} rawData
         */
        getfilters(fields, data, rawData) {
            let filters = [];
            fields.forEach(field => {
                const filter = this._getFilter(field, data, rawData);
                if (filter !== null) filters.push(filter);
            });
            return filters;
        },
        /**
         * Prepare filter by type
         *
         * @param {FilterField} field
         * @param {Object} data
         * @param {Object} rawData
         */
        _getFilter(field, data, rawData) {
            if (field instanceof DateRangeFilterField) {
                return this._getDateRangeFilter({ data, field });
            }
            if (field instanceof ListFilterField) {
                return this._getListFilter({ data, rawData, field });
            }
            if (field instanceof CheckBoxFilterField) {
                return this._getCheckBoxFilter({ data, rawData, field });
            }
            return null;
        },
        /**
         * Get DateRangeFilter
         *
         * @param {Object} data
         * @param {DateRangeFilterField} field
         */
        _getDateRangeFilter({ data, field }) {
            if (data[field.dateKey] !== null) {
                const date1 = data[field.dateKey][0];
                const date2 = data[field.dateKey][1];

                let values = [];
                if (date1 !== null) {
                    values.push(date1);
                }

                if (date2 !== null) {
                    values.push(date2);
                }

                if (values.length > 0) {
                    field.filterColumn = new FilterColumn(
                        field.searchKey,
                        values,
                        field.type,
                        this.operator
                    );
                    return field;
                }
            }

            return null;
        },
        /**
         *
         * @param {Object} data
         * @param {CheckBoxFilterField} field
         */
        _getCheckBoxFilter({ data, field }) {
            if (field.tristate ||  data[field.dataKey] !== null) {
                console.log(2);
                field.setValue(data[field.dataKey]);
                return field;
            }

            return null;
        },
        /**
         * Get list filter
         *
         * @param {Object} data
         * @param {Object} rawData
         * @param {ListFilterField} field
         */
        _getListFilter({ data, rawData, field }) {
            if (data[field.dataKey] !== null) {
                data[field.dataKey] = arrayWrap(data[field.dataKey]);
                rawData[field.dataKey] = arrayWrap(rawData[field.dataKey]);

                if (data[field.dataKey].length > 0) {
                    let value = rawData[field.dataKey].map(
                        item => item[field.label]
                    );
                    let operation = value.length > 1 ? "in" : "=";
                    field.filterColumn = field.conditional
                        ? new ConditionalFilterColumn(
                              field.searchKey,
                              data[field.dataKey], //array of ids
                              "in"
                          )
                        : new FilterColumn(
                              field.searchKey,
                              data[field.dataKey], //array of ids
                              "in"
                          );
                    field.filterColumn.showFilterData = {
                        value: value,
                        operation
                    };
                    return field;
                }
            }
            return null;
        }
    }
};
