<template>
  <div class="form-group">
    <div class="row" v-if="title.length > 0">
      <div class="col-md-12">
        <label :for="id">{{ title }}</label>
        <span class="text-danger" v-if="required">*</span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <date-picker
          v-model="form[name]"
          type="time"
          :placeholder="formatPlaceHolder"
          @close="handleOpenChange"
          input-class="form-control"
          :lang="language"
          :format="format"
          :confirm="confirm"
        >
        </date-picker>

        <has-error :form="form" :field="name" />
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { mapGetters } from "vuex";

import Form, { HasError } from "../index";

export default {
  name: "VueMyTimeField",
  props: {
    form: {
      type: Form,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    required: {
      type: Boolean,
      default: false,
    },
    confirm: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    DatePicker,
    HasError,
  },
  data() {
    return {
      showTimePanel: false,
      showTimeRangePanel: false,

      lang: {
        en: {
          placeholders: {
            YYYY: "YYYY",
            MM: "MM",
            DD: "DD",
            HH: "HH",
            mm: "mm",
          },
          // the locale of formatting and parsing function
          formatLocale: {
            // MMMM
            months: [
              "January",
              "February",
              "March",
              "April",
              "May",
              "June",
              "July",
              "August",
              "September",
              "October",
              "November",
              "December",
            ],
            // MMM
            monthsShort: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            // dddd
            weekdays: [
              "Sunday",
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
            ],
            // ddd
            weekdaysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
            // dd
            weekdaysMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
            // first day of week
            firstDayOfWeek: 1,
            // first week contains January 1st.
            firstWeekContainsDate: 1,
            // format 'a', 'A'
          },
          // the calendar title of year
          yearFormat: "YYYY",
          // the calendar title of month
          monthFormat: "MMM",
          // the calendar title of month before year
          monthBeforeYear: true,
        },
        ru: {
          placeholders: {
            YYYY: "ГГГГ",
            MM: "ММ",
            DD: "ДД",
            HH: "ЧЧ",
            mm: "мм",
          },
          // the locale of formatting and parsing function
          formatLocale: {
            // MMMM
            months: [
              "Январь",
              "Февраль",
              "Март",
              "Апрель",
              "Май",
              "Июнь",
              "Июль",
              "Август",
              "Сентябрь",
              "Октябрь",
              "Ноябрь",
              "Декабрь",
            ],
            // MMM
            monthsShort: [
              "Янв.",
              "Февр.",
              "Март",
              "Апр.",
              "Май",
              "Июнь",
              "Июль",
              "Авг.",
              "Сент.",
              "Окт.",
              "Нояб.",
              "Дек.",
            ],
            // dddd
            weekdays: [
              "Воскресенье",
              "Понедельник",
              "Вторник",
              "Среда",
              "Четверг",
              "Пятница",
              "Суббота",
            ],
            // ddd
            weekdaysShort: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            // dd
            weekdaysMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
            // first day of week
            firstDayOfWeek: 1,
            // first week contains January 1st.
            firstWeekContainsDate: 1,
            // format 'a', 'A'
          },
          // the calendar title of year
          yearFormat: "YYYY",
          // the calendar title of month
          monthFormat: "MMM",
          // the calendar title of month before year
          monthBeforeYear: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    language() {
      return this.lang[this.locale];
    },
    format() {
      return "hh:mm";
    },
    formatPlaceHolder() {
      let format = this.format;
      Object.keys(this.lang[this.locale]["placeholders"]).forEach((key) => {
        format = format.replace(
          key,
          this.lang[this.locale]["placeholders"][key]
        );
      });
      return format;
    },
  },
  methods: {
    toggleTimePanel() {
      this.showTimePanel = !this.showTimePanel;
    },
    toggleTimeRangePanel() {
      this.showTimeRangePanel = !this.showTimeRangePanel;
    },
    handleOpenChange() {
      this.showTimePanel = false;
    },
    handleRangeClose() {
      this.showTimeRangePanel = false;
    },
  },
};
</script>

<style>
.mx-table-date .today {
  border: 1px solid #1284e7;
  color: #2a90e9;
}
.mx-datepicker {
  min-width: 200px;
  width: 100%;
}
</style>