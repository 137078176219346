import Big from 'big.js';

class Calculation {

    calculateServiceLineTotal(price, quantity) {
        return new Big(price).times(quantity).toFixed(2);
    }

    calculateServiceLineDiscount(total, discount) {
        return new Big(total).times(discount).div(100).toFixed(2);
    }

    calculateServiceLineTotalWithDiscount(total, totalDiscount) {
        return new Big(total).minus(totalDiscount).toFixed(2);
    }

    toFixed(number, precision = 2) {
        return new Big(number).toFixed(precision);
    }
}

export default Calculation;