import {
    ADD_CLIENT_TYPE,
    ADD_CLIENT_AD_SOURCE,
    ADD_CLIENT_WORKSHOP,
    SET_CLIENT_INFO,
} from "../mutation-types";

import apiClient from "../../api/clients";
import { ClientDetail } from "../../pages/clients/models";

export class Item {
    constructor(id, name) {
        this.id = id;
        this.name = name;
    }
}

// state
export const state = {
    /** @type {Array.<Item>} */
    clientsTypes: [],
    /** @type {Array.<Item>} */
    workShops: [],
    /** @type {Array.<Item>} */
    clientAdSources: [],
    /** @type ClientDetail */
    client: null,
};

// getters
export const getters = {
    clientsTypes: (state) => state.clientsTypes,
    workShops: (state) => state.workShops,
    clientAdSources: (state) => state.clientAdSources,
    client: (state) => state.client,
};

// mutations
export const mutations = {
    [ADD_CLIENT_TYPE](state, { id, name }) {
        state.clientsTypes.push(new Item(id, name));
    },
    [ADD_CLIENT_AD_SOURCE](state, { id, name }) {
        state.clientAdSources.push(new Item(id, name));
    },
    [ADD_CLIENT_WORKSHOP](state, { id, name }) {
        state.workShops.push(new Item(id, name));
    },
    [SET_CLIENT_INFO](state, { data }) {
        state.client = new ClientDetail().parse(data);
    },
};

//actions
export const actions = {
    getClient({ commit }, { workShopId, clientId }) {
        return apiClient
            .getClient(workShopId, { id: clientId })
            .then((response) => {
                commit(SET_CLIENT_INFO, response);
            });
    },
    verifyClient(_, { workShopId, clientId }) {
        return apiClient.verifyClient(workShopId, { id: clientId });
    },
};
