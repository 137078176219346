var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showFastMenu
    ? _c("div", { staticClass: "dropdown" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "dropdown-menu",
            attrs: { "aria-labelledby": "dropDownFastMenu" },
          },
          [
            _vm.showOrder
              ? _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "dropdown-item",
                        attrs: {
                          to: {
                            name: "orders-create",
                            params: { work_shop_id: _vm.currentWorkShopId },
                          },
                        },
                      },
                      [
                        _c("span", [
                          _c("i", { staticClass: "nav-icon fas fa-folder" }),
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("pages.orders.create_order")) +
                              "\n        "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        staticClass: "nav-link",
        attrs: {
          id: "dropDownFastMenu",
          "data-toggle": "dropdown",
          "aria-expanded": "false",
        },
      },
      [_c("i", { staticClass: "fas fa-plus-square" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }