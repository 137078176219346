var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "aside",
    {
      staticClass: "control-sidebar control-sidebar-light",
      style: { width: _vm.width + "px" },
      attrs: { id: "control-sidebar" },
    },
    [
      _c(
        "div",
        { staticStyle: { padding: "16px", height: "100%", width: "100%" } },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("hr", { staticClass: "mb-2" }),
          _vm._v(" "),
          _c("div", { ref: "sidebarpanel" }),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-default",
        attrs: { type: "button", "data-widget": "control-sidebar" },
      },
      [_c("i", { staticClass: "fas fa-times" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }