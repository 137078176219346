var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.title.length > 0
      ? _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-md-12" }, [
            _c("label", { attrs: { for: _vm.id } }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
            _vm._v(" "),
            _vm.required
              ? _c("span", { staticClass: "text-danger" }, [_vm._v("*")])
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-md-12" },
        [
          _c("date-picker", {
            attrs: {
              type: "time",
              placeholder: _vm.formatPlaceHolder,
              "input-class": "form-control",
              lang: _vm.language,
              format: _vm.format,
              confirm: _vm.confirm,
            },
            on: { close: _vm.handleOpenChange },
            model: {
              value: _vm.form[_vm.name],
              callback: function ($$v) {
                _vm.$set(_vm.form, _vm.name, $$v)
              },
              expression: "form[name]",
            },
          }),
          _vm._v(" "),
          _c("has-error", { attrs: { form: _vm.form, field: _vm.name } }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }