import axios from "axios";

export default {
    getServices(work_shop_id, { current_page = 1, per_page = 20, data = {} }) {
        return axios.get(
            `/api/${work_shop_id}/services/${current_page}/${per_page}`,
            { params: { ...data } }
        );
    },

    getService(work_shop_id, { id }) {
        return axios.get(`/api/${work_shop_id}/services/${id}`);
    },

    deleteService(work_shop_id, { id }) {
        return axios.delete(`/api/${work_shop_id}/services/${id}`);
    }
};
