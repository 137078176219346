import axios from 'axios';

export default {

    getWorkshops({ current_page = 1, per_page = 20, data = {} }) {
        return axios.get(`/api/work-shops/${current_page}/${per_page}`, { params: { ...data } })
    },

    getWorkshop({ id }) {
        return axios.get(`/api/work-shops/${id}`);
    },
}
