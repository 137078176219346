<template>
  <div class="editor m-0 p-0" :class="{ 'editor-border': showBorder }">
    <editor-content class="editor__content m-0 p-0" :editor="editor" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { Editor, EditorContent } from "@tiptap/vue-2";

import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Blockquote from "@tiptap/extension-blockquote";
import CodeBlock from "@tiptap/extension-code-block";
import Code from "@tiptap/extension-code";
import HardBreak from "@tiptap/extension-hard-break";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import OrderedList from "@tiptap/extension-ordered-list";
import BulletList from "@tiptap/extension-bullet-list";
import ListItem from "@tiptap/extension-list-item";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";
import Typography from "@tiptap/extension-typography";
import Link from "@tiptap/extension-link";

export default {
  components: {
    EditorContent,
  },
  props: {
    showBorder: {
      type: Boolean,
      default: false,
    },
    content: {
      type: [String],
      required: false,
    },
  },
  data() {
    return {
      editor: new Editor({
        editable: false,
        content: this.content,
        extensions: [
          Document,
          Paragraph,
          Text,
          Bold,
          Italic,
          Strike,
          Underline,
          Code,
          HardBreak,
          Heading.configure({
            levels: [1, 2, 3, 4, 5, 6],
          }),
          ListItem,
          BulletList,
          OrderedList,
          TaskList,
          TaskItem,
          Blockquote,
          CodeBlock,
          HorizontalRule,
          Highlight,
          Typography,
          Link,
        ],
      }),
    };
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
  },
  watch: {
    content(val) {
      this.setContent(val);
    },
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    clearContent() {
      this.editor.commands.clearContent();
    },
    setContent(content) {
      this.editor.commands.setContent(content);
    },
  },
};
</script>