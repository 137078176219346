var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "basic-layout d-flex align-items-center justify-content-center m-0 bg-white",
    },
    [_c("child")],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }