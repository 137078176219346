<template>
  <div class="form-group">
    <label :for="id">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <treeselect
      v-model="form[name]"
      :options="options"
      :normalizer="normalizer"
      :multiple="multiple"
      :placeholder="placeholder"
      :class="{ 'is-invalid': form.errors.has(name) }"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <has-error :form="form" :field="name" />
  </div>
</template>

<script>
import Form, { HasError } from "../index";
// import the component
import Treeselect from "@riophae/vue-treeselect";
// import the styles
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { Node } from "../../tree";

export default {
  name: "VueMyTreeSelectField",
  components: {
    HasError,
    Treeselect,
  },
  props: {
    options: {
      type: Array,
      validator: function (options) {
        for (let option of options) {
          if (!(option instanceof Node)) {
            console.error("options should include only Node instance");
            return false;
          }
        }
        return true;
      },
      required: true,
    },
    form: {
      type: Form,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    normalizer(node) {
      let element = {
        id: node.key,
        label: node.name,
        data: node.data,
      };
      if (!node.empty > 0) {
        Object.assign(element, { children: node.children });
      }
      return element;
    },
  },
};
</script>

<style>
</style>