<template>
  <a class="dropdown-item pl-0" v-bind="$attrs" v-on="$listeners">
    <span class="p2">
      <slot name="icon">
        <i class="fas fa-bell"></i>
      </slot>
    </span>
    <slot name>{{ title }}</slot>
  </a>
</template>

<script>
export default {
  name: "MyDropDownMenuItem",
  props: {
    title: {
      type: [String],
      default: "",
    },
  },
};
</script>

<style>
.p2 {
  padding-left: 4px !important;
  padding-right: 4px;
}
</style>