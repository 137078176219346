<template>
  <div class="form-group">
    <label v-if="title.length > 0">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>

    <v-select v-bind="$attrs" v-on="$listeners" v-model="form[name]">
      <!-- Pass on all named slots -->
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot" />
      <!-- Pass on all scoped slots -->
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope" />
      </template>
    </v-select>
    <has-error :form="form" :field="name" />
  </div>
</template>

<script>
import Form, { HasError } from "../index";
import vSelect from "vue-select";

export default {
  name: "SelectField",
  components: {
    HasError,
    vSelect
  },
  props: {
    form: {
      type: Form,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      required: true
    },
    required: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style>
</style>