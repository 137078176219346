<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <!-- Brand Logo -->
    <router-link
      :to="{ name: 'dashboard', params: { work_shop_id: currentWorkShopId } }"
      class="brand-link"
    >
      <img
        :src="logo"
        alt="AdminLTE Logo"
        class="brand-image"
        style="opacity: 0.8; margin-left: 0.4rem; min-height: 45px"
      />
      <span class="brand-text font-weight-light">{{ workShopName }}</span>
    </router-link>

    <!-- Sidebar -->
    <div class="sidebar">
      <side-bar-user-panel></side-bar-user-panel>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul
          class="nav nav-pills nav-sidebar flex-column"
          data-widget="treeview"
          role="menu"
          data-accordion="false"
        >
          <li class="nav-item">
            <router-link
              :to="{
                name: 'dashboard',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
              exact
            >
              <i class="nav-icon fas fa-home"></i>
              <p>{{ $t("sidebar.home") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="ordersEnabled && hasPermission('read_order')"
          >
            <router-link
              :to="{
                name: 'orders',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-folder"></i>
              <p>{{ $t("sidebar.orders") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="ordersEnabled && hasPermission('read_service')"
          >
            <router-link
              :to="{
                name: 'services',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-clipboard-list"></i>
              <p>{{ $t("sidebar.services") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              ordersEnabled && hasPermission('read_my_services') && isForeman
            "
          >
            <router-link
              :to="{
                name: 'foreman-my-services',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon far fa-address-card"></i>
              <p>{{ $t("sidebar.my-services") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="ordersEnabled && hasPermission('read_article')"
          >
            <router-link
              :to="{
                name: 'articles',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-book-open"></i>
              <p>{{ $t("sidebar.articles") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="ordersEnabled && hasPermission('read_client')"
          >
            <router-link
              :to="{
                name: 'clients',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-user-tie"></i>
              <p>{{ $t("sidebar.clients") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="discountCardsEnabled && hasPermission('read_discount_card')"
          >
            <router-link
              :to="{
                name: 'discount-cards',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fa fa-id-card"></i>
              <p>{{ $t("sidebar.discount-cards") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              ordersEnabled &&
              hasPermission('read_foreman') &&
              (isSuperAdmin || isAdmin || isCollaborator)
            "
          >
            <router-link
              :to="{
                name: 'foremen',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-briefcase"></i>
              <p>{{ $t("sidebar.foremen") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="ordersEnabled && hasPermission('read_foreman') && isClient"
          >
            <router-link
              :to="{
                name: 'foremen-for-clients',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-briefcase"></i>
              <p>{{ $t("sidebar.foremen") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="ordersEnabled && hasPermission('read_courier')"
          >
            <router-link
              :to="{
                name: 'couriers',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-bus"></i>
              <p>{{ $t("sidebar.couriers") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="ordersEnabled && hasPermission('read_delivery_date')"
          >
            <router-link
              :to="{
                name: 'delivery-dates',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon far fa-calendar-alt"></i>
              <p>{{ $t("sidebar.delivery_dates") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="
              hasPermission('read_super_user') || hasPermission('read_user')
            "
          >
            <router-link
              :to="{
                name: 'users',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-users-cog"></i>
              <p>{{ $t("sidebar.users") }}</p>
            </router-link>
          </li>

          <li
            class="nav-item"
            v-if="townsEnabled && hasPermission('read_town')"
          >
            <router-link
              :to="{
                name: 'towns',
                params: { work_shop_id: currentWorkShopId },
              }"
              class="nav-link"
            >
              <i class="nav-icon fas fa-building"></i>
              <p>{{ $t("sidebar.towns") }}</p>
            </router-link>
          </li>

          <li class="nav-item">&nbsp;</li>

          <!-- Add icons to the links using the .nav-icon class
          with font-awesome or any other icon font library-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-tachometer-alt"></i>-->
          <!--              <p>-->
          <!--                Dashboard-->
          <!--                <i class="right fas fa-angle-left"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="index.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Dashboard v1</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="index2.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Dashboard v2</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="index3.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Dashboard v3</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="../widgets.html" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-th"></i>-->
          <!--              <p>-->
          <!--                Widgets-->
          <!--                <span class="right badge badge-danger">New</span>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-copy"></i>-->
          <!--              <p>-->
          <!--                Layout Options-->
          <!--                <i class="fas fa-angle-left right"></i>-->
          <!--                <span class="badge badge-info right">6</span>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../layout/top-nav.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Top Navigation</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../layout/top-nav-sidebar.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Top Navigation + Sidebar</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../layout/boxed.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Boxed</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../layout/fixed-sidebar.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Fixed Sidebar</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../layout/fixed-topnav.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Fixed Navbar</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../layout/fixed-footer.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Fixed Footer</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../layout/collapsed-sidebar.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Collapsed Sidebar</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-chart-pie"></i>-->
          <!--              <p>-->
          <!--                Charts-->
          <!--                <i class="right fas fa-angle-left"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../charts/chartjs.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>ChartJS</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../charts/flot.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Flot</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../charts/inline.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Inline</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-tree"></i>-->
          <!--              <p>-->
          <!--                UI Elements-->
          <!--                <i class="fas fa-angle-left right"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../UI/general.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>General</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../UI/icons.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Icons</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../UI/buttons.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Buttons</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../UI/sliders.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Sliders</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../UI/modals.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Modals & Alerts</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../UI/navbar.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Navbar & Tabs</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../UI/timeline.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Timeline</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../UI/ribbons.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Ribbons</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-edit"></i>-->
          <!--              <p>-->
          <!--                Forms-->
          <!--                <i class="fas fa-angle-left right"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../forms/general.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>General Elements</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../forms/advanced.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Advanced Elements</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../forms/editors.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Editors</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../forms/validation.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Validation</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-table"></i>-->
          <!--              <p>-->
          <!--                Tables-->
          <!--                <i class="fas fa-angle-left right"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../tables/simple.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Simple Tables</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../tables/data.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>DataTables</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../tables/jsgrid.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>jsGrid</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-header">EXAMPLES</li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="../calendar.html" class="nav-link">-->
          <!--              <i class="nav-icon far fa-calendar-alt"></i>-->
          <!--              <p>-->
          <!--                Calendar-->
          <!--                <span class="badge badge-info right">2</span>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="../gallery.html" class="nav-link">-->
          <!--              <i class="nav-icon far fa-image"></i>-->
          <!--              <p>Gallery</p>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon far fa-envelope"></i>-->
          <!--              <p>-->
          <!--                Mailbox-->
          <!--                <i class="fas fa-angle-left right"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../mailbox/mailbox.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Inbox</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../mailbox/compose.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Compose</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../mailbox/read-mail.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Read</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-book"></i>-->
          <!--              <p>-->
          <!--                Pages-->
          <!--                <i class="fas fa-angle-left right"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/invoice.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Invoice</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/profile.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Profile</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/e_commerce.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>E-commerce</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/projects.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Projects</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/project_add.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Project Add</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/project_edit.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Project Edit</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/project_detail.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Project Detail</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/contacts.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Contacts</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview menu-open">-->
          <!--            <a href="#" class="nav-link active">-->
          <!--              <i class="nav-icon far fa-plus-square"></i>-->
          <!--              <p>-->
          <!--                Extras-->
          <!--                <i class="fas fa-angle-left right"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/login.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Login</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/register.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Register</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/forgot-password.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Forgot Password</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/recover-password.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Recover Password</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/lockscreen.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Lockscreen</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/legacy-user-menu.html" class="nav-link active">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Legacy User Menu</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/language-menu.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Language Menu</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/404.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Error 404</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/500.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Error 500</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/pace.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Pace</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="../examples/blank.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Blank Page</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="starter.html" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Starter Page</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-header">MISCELLANEOUS</li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="https://adminlte.io/docs/3.0" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-file"></i>-->
          <!--              <p>Documentation</p>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-header">MULTI LEVEL EXAMPLE</li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="fas fa-circle nav-icon"></i>-->
          <!--              <p>Level 1</p>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item has-treeview">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon fas fa-circle"></i>-->
          <!--              <p>-->
          <!--                Level 1-->
          <!--                <i class="right fas fa-angle-left"></i>-->
          <!--              </p>-->
          <!--            </a>-->
          <!--            <ul class="nav nav-treeview">-->
          <!--              <li class="nav-item">-->
          <!--                <a href="#" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Level 2</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--              <li class="nav-item has-treeview">-->
          <!--                <a href="#" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>-->
          <!--                    Level 2-->
          <!--                    <i class="right fas fa-angle-left"></i>-->
          <!--                  </p>-->
          <!--                </a>-->
          <!--                <ul class="nav nav-treeview">-->
          <!--                  <li class="nav-item">-->
          <!--                    <a href="#" class="nav-link">-->
          <!--                      <i class="far fa-dot-circle nav-icon"></i>-->
          <!--                      <p>Level 3</p>-->
          <!--                    </a>-->
          <!--                  </li>-->
          <!--                  <li class="nav-item">-->
          <!--                    <a href="#" class="nav-link">-->
          <!--                      <i class="far fa-dot-circle nav-icon"></i>-->
          <!--                      <p>Level 3</p>-->
          <!--                    </a>-->
          <!--                  </li>-->
          <!--                  <li class="nav-item">-->
          <!--                    <a href="#" class="nav-link">-->
          <!--                      <i class="far fa-dot-circle nav-icon"></i>-->
          <!--                      <p>Level 3</p>-->
          <!--                    </a>-->
          <!--                  </li>-->
          <!--                </ul>-->
          <!--              </li>-->
          <!--              <li class="nav-item">-->
          <!--                <a href="#" class="nav-link">-->
          <!--                  <i class="far fa-circle nav-icon"></i>-->
          <!--                  <p>Level 2</p>-->
          <!--                </a>-->
          <!--              </li>-->
          <!--            </ul>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="fas fa-circle nav-icon"></i>-->
          <!--              <p>Level 1</p>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-header">LABELS</li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon far fa-circle text-danger"></i>-->
          <!--              <p class="text">Important</p>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon far fa-circle text-warning"></i>-->
          <!--              <p>Warning</p>-->
          <!--            </a>-->
          <!--          </li>-->
          <!--          <li class="nav-item">-->
          <!--            <a href="#" class="nav-link">-->
          <!--              <i class="nav-icon far fa-circle text-info"></i>-->
          <!--              <p>Informational</p>-->
          <!--            </a>-->
          <!--          </li>-->
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>
</template>

<script>
import { mapGetters } from "vuex";

import SideBarUserPanel from "./SideBarUserPanel";

export default {
  name: "SideBar",
  components: {
    SideBarUserPanel,
  },
  computed: {
    ...mapGetters("auth", [
      "hasRole",
      "hasPermission",
      "workShop",
      "isForeman",
      "isClient",
      "isSuperAdmin",
      "isAdmin",
      "isCollaborator",
      "isCourier",
      "townsEnabled",
      "ordersEnabled",
      "discountCardsEnabled",
    ]),
    ...mapGetters("auth", ["currentWorkShopId"]),
    workShopName() {
      return this.$isset(this.workShop) ? this.workShop.name : "";
    },
    logo() {
      return "/assets/logo.png";
    },
  },
};
</script>
