import { deepCopy, isset } from "../../utils/functions";
import { LocalRepository } from "../../store/repository";

export class Column {
    /**
     * Represent a column of VuMyTable
     * @constructor
     * @param {object} column - Column settings
     * @param {string} column.id
     * @param {string} column.name
     * @param {Object} column.styleCell
     * @param {Object} column.styleHeader
     * @param {Object} column.styleFooter
     * @param {string|null} [column.key=null]
     * @param {string|null} [column.filterKey=null] using for
     * @param {boolean} [column.canSort=true] If column.key is null, will be false
     * @param {boolean} [column.canSearch=true] If column.key is null, will be false
     * @param {boolean} [column.calculateTotal=false]
     * @param {boolean} [column.money=false]
     * @param {boolean} [column.timeTotal=false]
     * @param {boolean} [column.showTimeTotalZero=false]
     * @param {boolean} [column.showTimeTotalSeconds=false]
     * @param {boolean} [column.translate=true]
     */
    constructor({
        id,
        name,
        key = null,
        filterKey = null,
        styleCell = {},
        styleHeader = {},
        styleFooter = {},
        canSort = true,
        canSearch = true,
        calculateTotal = false,
        money = false,
        timeTotal = false,
        showTimeTotalZero = false,
        showTimeTotalSeconds = false,
        translate = true,
    }) {
        this.id = id;
        this.name = name;
        this.key = key;
        this.filterKey = filterKey;
        this.styleCell = styleCell;
        this.styleHeader = styleHeader;
        this.styleFooter = styleFooter;
        this.canSort = key !== null ? canSort : false;
        this.canSearch = key !== null ? canSearch : false;
        this.sortingValues = ["asc", "desc", "disable"];
        this.calculateTotal = calculateTotal;
        this.money = money;
        this.timeTotal = timeTotal;
        this.showTimeTotalZero = showTimeTotalZero;
        this.showTimeTotalSeconds = showTimeTotalSeconds;
        this.total = 0;
        this.translate = translate;
        this.sortDisable();
    }

    setSorting(sorting) {
        this.sorting = sorting;
    }

    getNextSorting() {
        if (this.canSort) {
            switch (this.sort) {
                case "asc":
                    this.sortDisable();
                    break;
                case "desc":
                    this.sortByAsc();
                    break;
                case "disable":
                    this.sortByDesc();
                    break;
            }
        }
    }

    resetTotal() {
        this.total = 0.0;
    }

    plusTotal(value) {
        this.total += parseFloat(value);
    }

    get getTotal() {
        return this.total;
    }

    sortByAsc() {
        if (this.canSort) this.sorting = this.sortingValues[0];
    }

    sortByDesc() {
        if (this.canSort) this.sorting = this.sortingValues[1];
    }

    sortDisable() {
        this.sorting = this.sortingValues[2];
    }

    get isSortByAsc() {
        return this.sort === this.sortingValues[0];
    }

    get isSortByDesc() {
        return this.sort === this.sortingValues[1];
    }

    get isSortDisable() {
        return this.sort === this.sortingValues[2];
    }

    get isSearching() {
        return this.canSearch;
    }

    get sort() {
        return this.sorting;
    }

    get isCalculateTotal() {
        return this.calculateTotal;
    }

    get isShowMoney() {
        return this.money;
    }
}

export class Row {
    /**
     *
     * @param {Array.<Cell>} cells
     * @param raw
     */
    constructor({ cells = [], raw = {} }) {
        this.cells = cells;
        this.raw = raw;
    }

    addCell(cell) {
        if (cell.getColumn.isCalculateTotal) {
            cell.getColumn.plusTotal(cell.getValue);
        }
        this.cells.push(cell);
    }

    get getCells() {
        return this.cells;
    }

    get getRaw() {
        return deepCopy(this.raw);
    }
}

export class Cell {
    /**
     * @param {Column} column
     * @param {string} value
     * @param {string} key
     */
    constructor({ column, value = "" }) {
        this.column = column;
        this.value = value;
    }

    get getColumn() {
        return this.column;
    }

    get getValue() {
        return _.get(this.value, this.column.id);
    }
}

export class GetDataRequest {
    /**
     * @constructor
     * @param currentPage
     * @param {number} perPage
     * @param {Array.<SearchingColumn>} searching
     * @param {Array.<SortingColumn>} sorting
     */
    constructor({
        currentPage = 1,
        perPage = 10,
        searching = [],
        sorting = [],
        conditional = [],
    }) {
        this.current_page = currentPage;
        this.per_page = perPage;
        this.data = {
            searching: [],
            sorting: [],
            filter: [],
            conditional: [],
        };
        this.searchingData = searching;
        this.sortingData = sorting;
        this.conditionalData = conditional;

        this.searchingDataSource = [];
        this.filteringDataSource = [];
        this.conditionalDataSource = [];
    }

    /**
     * List of fields for searching
     *
     * @param {Array.<SearchingColumn>} searching
     */
    set searchingData(searching = []) {
        this.searchingDataSource = searching;
        this.data.searching = searching.map((column) => {
            return column.data;
        });
    }

    /**
     * List of fields for filtering
     *
     * @param {Array.<FilterColumn>} filter
     */
    set filterData(filter = []) {
        this.filteringDataSource = filter;
        this.data.filter = filter.map((column) => {
            return column.data;
        });
    }

    /**
     * List of fields for sorting
     *
     * @param {Array.<SortingColumn>} sorting
     */
    set sortingData(sorting = []) {
        this.data.sorting = sorting.map((column) => {
            return column.data;
        });
    }

    /**
     * List of fields for conditional filter
     *
     * @param {Array.<DataCondition>} conditional
     */
    set conditionalData(conditional = []) {
        this.conditionalDataSource = conditional;
        this.data.conditional = conditional.map((column) => {
            return column.data;
        });
    }
}

/**
 * @property {number} order
 * @property {string} key
 * @property {string} direction
 */
export class SortingColumn {
    constructor(order, key, direction) {
        this.order = order;
        this.key = key;
        this.direction = direction;
    }

    get data() {
        return `${this.order};${this.key};${this.direction}`;
    }

    /**
     * Prepare to json serialization
     */
    get forJson() {
        return {
            key: this.key,
            order: this.order,
            direction: this.direction,
        };
    }

    static fromJson(data) {
        return new SortingColumn(data.order, data.key, data.direction);
    }
}

export class FilterColumn {
    constructor(
        key,
        value,
        operation = "like",
        operator = "and",
        type = "normal" //normal || conditional
    ) {
        this.key = key;
        this.value = value;
        this.operation = operation;
        this.operator = operator;
        this.type = type;
    }

    get data() {
        return `${this.key};${this.operation};${this.value};${this.operator}`;
    }

    /**
     * @param {Object} raw
     */
    set showFilterData({ value, operation = "in" }) {
        this.filterValue = value;
        this.filterOperation = operation;
    }

    /**
     * Prepare to json serialization
     */
    get forJson() {
        return {
            type: this.type,
            key: this.key,
            value: this.value,
            operation: this.operation,
            operator: this.operator,
            filterValue: this.filterValue,
            filterOperation: this.filterOperation,
        };
    }

    /**
     * Parse filter column from "forJson" format
     * @param {*} data
     * @return FilterColumn
     */
    static parse(data) {
        let filterColumn = new FilterColumn(
            data.key,
            data.value,
            data.operation,
            data.operator,
            data.type
        );
        if (data.filterValue && data.filterOperation) {
            filterColumn.showFilterData = {
                value: data.filterValue,
                operation: data.filterOperation,
            };
        }
        return filterColumn;
    }
}

export class ConditionalFilterColumn extends FilterColumn {
    constructor(
        key,
        value,
        operation = "in",
        operator = "and",
        type = "conditional"
    ) {
        super(key, value, operation, operator, type);
    }

    get data() {
        return `${this.key};${this.value}`;
    }

    /**
     * Parse filter column from "forJson" format
     * @param {*} data
     * @return ConditionalFilterColumn
     */
    static parse(data) {
        let filterColumn = new ConditionalFilterColumn(
            data.key,
            data.value,
            data.operation,
            data.operator,
            data.type
        );
        if (data.filterValue && data.filterOperation) {
            filterColumn.showFilterData = {
                value: data.filterValue,
                operation: data.filterOperation,
            };
        }
        return filterColumn;
    }
}

export class SearchingColumn {
    constructor(key, value, operation = "ilike") {
        this.key = key;
        this.value = value;
        this.operation = operation;
    }

    get data() {
        return `${this.key};${this.operation};${this.value}`;
    }
}

/**
 * Класс для трасфера измененных фильтров по EventBus
 *
 * @property {String} key значение saveStorageKey VueMyTable
 * @property  {Array.<FilterField>} filters измененные фильтры
 * @property  {Boolean} needReset рестарт всех фильтров принудительно
 * @property  {Boolean} fromExternalFilters если обновление от external фильтров. Типа открытые, закрытые задачи в проектах
 */
export class FiltersUpdatedTransfer {
    constructor({
        key,
        filters = [],
        needReset = false,
        fromExternalFilters = false,
    }) {
        this.key = key;
        this.filters = filters;
        this.needReset = needReset;
        this.fromExternalFilters = fromExternalFilters;
    }
}

/**
 * Базовый класс фильтрации
 *
 * @property {String} searchKey Поле по которому идет поиск в бд
 * @property {String} type Тип операции при фильтрации
 * @property {String} filterKey По этому ключу определяется столбец таблицы, должен быть указан в конфигурации таблицы
 * @property {String} filterColumn Данные фильтра
 */
export class FilterField {
    /**
     * @param {object} data - filter settings
     * @param {String} data.filterKey по этому ключу определяется столбец таблицы, должен быть указан в конфигурации таблицы
     * @param {String} data.type тип операции при фильтрации
     * @param {String} data.searchKey поле, по которому идет поиск в бд
     * @param {String} data.conditional поле является специальным фильтром
     * @param {Boolean} data.show показывать поле при фильтрации, может использоваться для доп фильтрации по полю, которое не показывается в компоненте таблицы, но используется для формирование запроса на сервер
     * @param {Boolean} data.enabled фильтр включен или нет.
     * @param {Boolean} data.alwaysInStorage всегда хранить фильтр в сторедж
     */
    constructor({
        filterKey,
        searchKey,
        type,
        conditional = false,
        show = true,
        enabled = true,
        alwaysInStorage = false,
    }) {
        this.type = type;
        this.filterKey = filterKey;
        this.searchKey = searchKey;
        this.conditional = conditional;
        this.show = show;
        this.enabled = enabled;
        this.alwaysInStorage = alwaysInStorage;
    }

    /**
     * @param {FilterColumn} column
     */
    set filterColumn(column) {
        this._filterColumn = column;
    }

    /**
     * @return {FilterColumn}
     */
    get filterColumn() {
        return this._filterColumn;
    }

    /**
     * Prepare to json serialization
     */
    get forJson() {
        return {
            type: this.type,
            filterKey: this.filterKey,
            searchKey: this.searchKey,
            conditional: this.conditional,
            show: this.show,
            enabled: this.enabled,
            alwaysInStorage: this.alwaysInStorage,
            filterColumn: isset(this.filterColumn)
                ? this.filterColumn.forJson
                : null,
        };
    }
}

/**
 * Фильтрация по дате
 *
 * @property {String} data.dateKey Поле в Form, которое содержит значение
 */
export class DateRangeFilterField extends FilterField {
    /**
     *
     * @constructor
     *
     * @param {object} data - filter settings
     * @param {String} data.filterKey по этому ключу определяется столбец таблицы, должен быть указан в конфигурации таблицы
     * @param {String} data.searchKey поле, по которому идет поиск в бд
     * @param {String|null} data.dateKey поле в Form, которое содержит значение
     * @param {Boolean} data.show показывать поле при фильтрации, может использоваться для доп фильтрации по полю, которое не показывается в компоненте таблицы, но используется для формирование запроса на сервер
     */
    constructor({
        filterKey,
        searchKey,
        dateKey,
        show = true,
        enabled = true,
        alwaysInStorage = false,
    }) {
        super({
            filterKey,
            searchKey,
            type: "date",
            show,
            enabled,
            alwaysInStorage,
        });
        this.dateKey = dateKey;
    }

    get clone() {
        return DateRangeFilterField.parse(this.forJson);
    }

    /**
     * Prepare to json serialization
     */
    get forJson() {
        let data = super.forJson;
        data["dateKey"] = this.dateKey;
        return data;
    }

    /**
     * Parse filter column from "forJson" format
     * @param {*} data
     * @return DateRangeFilterField
     */
    static parse(data) {
        let filterField = new DateRangeFilterField({
            filterKey: data.filterKey,
            searchKey: data.searchKey,
            dateKey: data.dateKey,
            show: data.show,
            enabled: data.enabled,
            alwaysInStorage: data.alwaysInStorage,
        });
        if (isset(data.filterColumn)) {
            filterField.filterColumn = FilterColumn.parse(data.filterColumn);
        }
        return filterField;
    }
}

export class CheckBoxFilterField extends FilterField {
    /**
     *
     * @constructor
     *
     * @param {object} data - filter settings
     * @param {String} data.filterKey по этому ключу определяется столбец таблицы, должен быть указан в конфигурации таблицы
     * @param {String} data.searchKey поле, по которому идет поиск в бд
     * @param {String|null} data.dataKey поле в Form, которое содержит значение.
     * @param {String} data.conditional  поле является специальным фильтром поле при фильтрации, может использоваться дл
     * @param {Boolean} data.show показывать поле при фильтрации, может использоваться для доп фильтрации по полю, которое не показывается в компоненте таблицы, но используется для формирование запроса на сервер
     * @param {Boolean} data.tristate если включен тогда может быть true, false, null и не удаляется из стореджа фильтров.
     */
    constructor({
        filterKey,
        searchKey,
        dataKey,
        conditional = false,
        show = true,
        tristate = false,
        enabled = true,
        alwaysInStorage = false,
    }) {
        super({
            filterKey,
            searchKey,
            type: "checkbox",
            conditional,
            show,
            enabled,
            alwaysInStorage,
        });
        this.dataKey = dataKey;
        this.tristate = tristate;
    }

    setValue(value) {
        if (value !== null && value !== true && value !== false) {
            console.error("value should be true, false, null");
            return;
        }

        if (value !== null) {
            this.filterColumn = this.conditional
                ? new ConditionalFilterColumn(
                      this.searchKey,
                      value, // true or false
                      "="
                  )
                : new FilterColumn(
                      this.searchKey,
                      value, // true or false
                      "="
                  );
            this.enabled = true;
        } else {
            this.filterColumn = null;
            this.enabled = false;
        }
    }

    get isTrue() {
        return (
            this.enabled &&
            isset(this.filterColumn) &&
            this.filterColumn.value === true
        );
    }

    get isFalse() {
        return (
            this.enabled &&
            isset(this.filterColumn) &&
            this.filterColumn.value === false
        );
    }

    get isNull() {
        return this.filterColumn === null || this.filterColumn === undefined;
    }

    get clone() {
        return CheckBoxFilterField.parse(this.forJson);
    }

    /**
     * Prepare to json serialization
     */
    get forJson() {
        let data = super.forJson;
        data["dataKey"] = this.dataKey;
        data["tristate"] = this.tristate;
        return data;
    }

    /**
     * Parse filter column from "forJson" format
     * @param {*} data
     * @return DateRangeFilterField
     */
    static parse(data) {
        let filterField = new CheckBoxFilterField({
            filterKey: data.filterKey,
            searchKey: data.searchKey,
            dataKey: data.dataKey,
            show: data.show,
            tristate: data.tristate,
            conditional: data.conditional,
            enabled: data.enabled,
            alwaysInStorage: data.alwaysInStorage,
        });
        if (isset(data.filterColumn)) {
            filterField.filterColumn = data.conditional
                ? ConditionalFilterColumn.parse(data.filterColumn)
                : FilterColumn.parse(data.filterColumn);
        }
        return filterField;
    }
}

/**
 * Фильтрация по списку
 *
 * @property {String} data.dataKey Поле в Form, которое содержит значение
 * @property {String} data.label Ключ где, хранчится значение элемента в списке, которое используется для отображения в фильтрах
 */
export class ListFilterField extends FilterField {
    /**
     *
     * @param {object} data - filter settings
     * @param {String} data.filterKey по этому ключу определяется столбец таблицы, должен быть указан в конфигурации таблицы
     * @param {String} data.searchKey поле, по которому идет поиск в бд
     * @param {String|null} data.dataKey поле в Form, которое содержит значение
     * @param {String} data.label Ключ где, хранчится значение элемента в списке, которое используется для отображения в фильтрах
     * @param {String} data.conditional  поле является специальным фильтром поле при фильтрации, может использоваться дл
     * @param {Boolean} data.show показывать поле при фильтрации, может использоваться для доп фильтрации по полю, которое не показывается в компоненте таблицы, но используется для формирование запроса на сервер
     */
    constructor({
        filterKey,
        searchKey,
        dataKey,
        label = "name",
        conditional = false,
        show = true,
        enabled = true,
        alwaysInStorage = false,
    }) {
        super({
            filterKey,
            searchKey,
            type: "list",
            conditional,
            show,
            enabled,
            alwaysInStorage,
        });
        this.dataKey = dataKey;
        this.label = label;
    }

    get clone() {
        return ListFilterField.parse(this.forJson);
    }

    /**
     * Prepare to json serialization
     */
    get forJson() {
        let data = super.forJson;
        data["dataKey"] = this.dataKey;
        data["label"] = this.label;
        return data;
    }

    /**
     * Parse filter column from "forJson" format
     * @param {*} data
     * @return ListFilterField
     */
    static parse(data) {
        let filterField = new ListFilterField({
            filterKey: data.filterKey,
            searchKey: data.searchKey,
            dataKey: data.dataKey,
            label: data.label,
            conditional: data.conditional,
            show: data.show,
            enabled: data.enabled,
            alwaysInStorage: data.alwaysInStorage,
        });
        if (isset(data.filterColumn)) {
            filterField.filterColumn = data.conditional
                ? ConditionalFilterColumn.parse(data.filterColumn)
                : FilterColumn.parse(data.filterColumn);
        }
        return filterField;
    }
}

export class TextFilterField extends FilterField {}

/**
 * @property {LocalRepository} repository
 */
export class SortingStoredManager {
    /**
     *
     * @param {String} key
     */
    constructor({ key }) {
        if (!isset(key)) console.error("sorting key is empty");

        this.repository = new LocalRepository();
        this.key = `sortCol_${key}`;
    }

    /**
     *
     * @param {Array.<SortingColumn>} cells
     */
    setSortingColumns(data) {
        if (data.length > 0) {
            this.repository.setData(this.key, data, true);
        } else {
            this.repository.removeData(this.key);
        }
    }

    getSortingColumns() {
        let data = this.repository.getData(this.key, true) || [];
        return data.map((x) => SortingColumn.fromJson(x));
    }
}

/**
 * @property {LocalRepository} repository
 */
export class SearchingStoredManager {
    /**
     *
     * @param {String} key
     */
    constructor({ key }) {
        if (!isset(key)) console.error("searching key is empty");

        this.repository = new LocalRepository();
        this.key = `searchCol_${key}`;
    }

    /**
     *
     * @param {Array.<SortingColumn>} cells
     */
    setSearchingData({ searching, advancedSearch = false }) {
        if ((isset(searching) && searching !== "") || advancedSearch) {
            this.repository.setData(
                this.key,
                { searching, advancedSearch },
                true
            );
        } else {
            this.repository.removeData(this.key);
        }
    }

    getSearchingData() {
        return this.repository.getData(this.key, true);
    }
}

export class FilteringStoredManager {
    /**
     *
     * @param {String} key
     */
    constructor({ key }) {
        if (!isset(key)) console.error("filtering key is empty");

        this.repository = new LocalRepository();
        this.key = `filterCol_${key}`;
    }

    /**
     *
     * @param {Array.<FilterField>} filters
     */
    setFilteringColumns(filters) {
        if (filters.length > 0) {
            let filtersFforSave = filters.map((filter) => filter.forJson);
            this.repository.setData(this.key, filtersFforSave, true);
        } else {
            this.repository.removeData(this.key);
        }
    }

    getFilteringColumns() {
        let data = this.repository.getData(this.key, true) || [];

        let parsedFilters = [];
        for (let rawFilter of data) {
            switch (rawFilter.type) {
                case "list":
                    parsedFilters.push(ListFilterField.parse(rawFilter));
                    break;
                case "date":
                    parsedFilters.push(DateRangeFilterField.parse(rawFilter));
                    break;
                case "checkbox":
                    parsedFilters.push(CheckBoxFilterField.parse(rawFilter));
                    break;
                default:
                    console.error(`Cant recognize filter ${rawFilter.type}`);
            }
        }
        return parsedFilters;
    }
}

export class ExternalUpdateFilterData {
    constructor() {
        this.data = {};
        this.rawData = {};
    }

    addFullData({ key, data, rawData }) {
        this._addData(key, data);
        this._addRawData(key, rawData);
    }

    _addData(key, data) {
        this.data[key] = data;
    }

    _addRawData(key, rawData) {
        this.rawData[key] = rawData;
    }
}
