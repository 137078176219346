var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "nav",
      {
        staticClass:
          "main-header navbar navbar-expand navbar-white navbar-light",
      },
      [
        _c("ul", { staticClass: "navbar-nav" }, [
          _c("li", { staticClass: "nav-item" }, [
            _c(
              "a",
              {
                staticClass: "nav-link",
                attrs: { "data-widget": "pushmenu", href: "#" },
                on: { click: _vm.toggleSideBar },
              },
              [_c("i", { staticClass: "fas fa-bars" })]
            ),
          ]),
          _vm._v(" "),
          _c(
            "li",
            { staticClass: "nav-item" },
            [_c("nav-fast-creation-menu", { staticClass: "ml-2" })],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "navbar-nav ml-auto" },
          [
            _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: { "data-toggle": "dropdown" },
                  on: { click: _vm.showModalWorkShopList },
                },
                [
                  _c("i", { staticClass: "fas fa-store-alt" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "d-none d-md-inline" }, [
                    _vm._v(_vm._s(_vm.workShopName)),
                  ]),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("li", { staticClass: "nav-item dropdown" }, [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  attrs: { "data-toggle": "dropdown", href: "#" },
                },
                [
                  _c("i", {
                    staticClass: "flag-icon",
                    class: _vm.activeIconClassLocale,
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "dropdown-menu dropdown-menu-right p-0" },
                _vm._l(_vm.locales, function (lang) {
                  return _c(
                    "a",
                    {
                      key: lang,
                      staticClass: "dropdown-item",
                      class: { active: lang === _vm.locale },
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.changeLang(lang)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "flag-icon mr-2",
                        class: _vm.iconClassLocale(lang),
                      }),
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.$t("locales." + lang)) +
                          "\n          "
                      ),
                    ]
                  )
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c("nav-user-menu"),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }