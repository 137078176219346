import store from "~/store";

// check if user is logged
export default async (to, from, next) => {
    if (!store.getters["auth/check"]) {
        next({ name: "login" });
    } else {
        //if current work shop is empty, get it
        if (
            !store.getters["auth/currentWorkShop"] ||
            store.getters["auth/currentWorkShop"].id !== to.params.work_shop_id
        ) {
            await store.dispatch("auth/getWorkShop", {
                work_shop_id: to.params.work_shop_id
            });
        }
        next();
    }
};
