import { deepCopy } from "../../utils/functions";

export class Tree {
    constructor({ key, data }) {
        this._root = new Node({ key, data });
    }

    traverseDFS(callback) {
        // это рекурсивная и мгновенно вызываемая функция
        (function recurse(currentNode) {
            //шаг 2
            for (
                let i = 0, length = currentNode.children.length;
                i < length;
                i++
            ) {
                recurse(currentNode.children[i]);
            }
            // шаг 4
            callback(currentNode);
            //шаг 1
        })(this._root);
    }

    traverseBFS(callback) {
        let queue = [];

        queue.push(this._root);
        let currentNode = queue.shift();
        while (currentNode) {
            for (
                let i = 0, length = currentNode.children.length;
                i < length;
                i++
            ) {
                queue.push(currentNode.children[i]);
            }
            let result = callback(currentNode);

            if (result) currentNode = queue.shift();
            else break;
        }
    }

    contains(callback, traversal) {
        traversal.call(this, callback);
    }

    /**
     *
     * @param {*} param0
     */
    add({ key, data, toKey, traversal, sortKey = null, name }) {
        let child = new Node({ key, data, name }),
            parent = null,
            callback = function(node) {
                if (node.key === toKey) {
                    parent = node;
                    return false;
                }
                return true;
            };
        this.contains(callback, traversal);

        if (parent) {
            child.parent = parent;
            parent.addChild(child);
            if (sortKey !== null) {
                parent.children = parent.children.sort((a, b) => {
                    if (a.data[sortKey] > b.data[sortKey]) {
                        return 1;
                    }
                    if (a.data[sortKey] < b.data[sortKey]) {
                        return -1;
                    }
                    return 0;
                });
            }
            return true;
        }
        return false;
    }

    parent({ ofKey, traversal }) {
        let parent = null,
            callback = function(node) {
                let childNode = node.children.find(
                    x => parseInt(x.key) === parseInt(ofKey)
                );
                if (childNode !== undefined) {
                    parent = node;
                    return false;
                }
                return true;
            };
        this.contains(callback, traversal);

        if (parent) {
            return parent;
        }
        return null;
    }

    find({ ofKey, traversal }) {
        let element = null,
            callback = function(node) {
                let item = parseInt(node.key) === parseInt(ofKey);
                if (item) {
                    element = node;
                    return false;
                }
                return true;
            };
        this.contains(callback, traversal);

        if (element) {
            return element;
        }
        return null;
    }

    reset() {
        this._root = new Node({ key: this._root.key, data: this._root.data });
    }

    /**
     * @param {Object} input  
     * @param {Array.<Object>} input.data 
      @param {String} input.idKey 
     * @param {String} input.parentKey 
     * @param {String} input.sortKey 
     */
    parse({ data, idKey, parentKey, sortKey, nameKey = "name" }) {
        let rows = deepCopy(data);

        let dataRow = rows.shift();
        while (dataRow) {
            if (
                dataRow[parentKey] !== undefined &&
                dataRow[parentKey] !== null
            ) {
                //subcategory
                const added = this.add({
                    key: dataRow[idKey],
                    data: dataRow,
                    toKey: dataRow[parentKey].id,
                    traversal: this.traverseBFS,
                    sortKey: sortKey,
                    name: dataRow[nameKey]
                });
                if (!added) {
                    rows.push(dataRow);
                }
            } else {
                //parent category
                this.add({
                    key: dataRow[idKey],
                    name: dataRow[nameKey],
                    data: dataRow,
                    toKey: 0,
                    traversal: this.traverseBFS,
                    sortKey
                });
            }

            dataRow = rows.shift();
        }
    }

    get root() {
        return this._root;
    }

    get empty() {
        return this._root.empty;
    }
}

export class Node {
    /**
     * @constructor
     * @param {int|String} key
     * @param {Object} data
     * @param {Node} parent
     */
    constructor({ key, name, data, children = [] }) {
        this.key = key;
        this._data = data;
        this.name = name;
        this._children = children;
        this._isEmpty = true;
        this._count = 0;
        this._isOpened = true;
        this.parent = null;
    }

    addChild(child) {
        this._children.push(child);
        this._isEmpty = false;
        this._count = this.children.length;
    }

    get children() {
        return this._children;
    }

    set children(children) {
        this._children = children;
        this._count = this.children.length;
        this._isEmpty = this.count === 0;
    }

    get open() {
        return this._isOpened;
    }

    toggleOpen() {
        this._isOpened = this.empty ? false : !this.open;
        return this.open;
    }

    get data() {
        return this._data;
    }

    get empty() {
        return this._isEmpty;
    }

    get count() {
        return this._count;
    }
}

export class NodeData {
    constructor(id, data) {
        this._id = id;
        this._data = data;
    }

    set oldIndex(value) {
        this._oldIndex = value;
    }

    get oldIndex() {
        return this._oldIndex;
    }

    set newIndex(value) {
        this._newIndex = value;
    }

    get newIndex() {
        return this._newIndex;
    }

    set parentId(value) {
        this._parentId = value;
    }

    get parentId() {
        return this._parentId;
    }

    get id() {
        return this._id;
    }

    get data() {
        return this._data;
    }
}
