var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.filterForShow.length > 0
    ? _c(
        "div",
        { staticClass: "callout callout-info" },
        [
          _c(
            "button",
            {
              staticClass: "close",
              attrs: { type: "button" },
              on: { click: _vm.resetFilters },
            },
            [_vm._v("×")]
          ),
          _vm._v(" "),
          _c("h5", [
            _c("i", { staticClass: "icon fas fa-search" }),
            _vm._v("\n    " + _vm._s(_vm.$t("actions.search")) + "\n  "),
          ]),
          _vm._v(" "),
          _vm._l(_vm.filterForShow, function (filter, index) {
            return [
              _vm.isDateRangeField(filter)
                ? _c("vue-my-table-date-range-filter", {
                    key: "filter_" + (Date.now() + index),
                    attrs: {
                      column: _vm.getColumnByFilterKey(filter.filterKey),
                      filter: filter,
                      "show-operator": index + 1 !== _vm.filterForShow.length,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isListField(filter)
                ? _c("vue-my-table-list-filter", {
                    key: "filter_" + (Date.now() + index),
                    attrs: {
                      column: _vm.getColumnByFilterKey(filter.filterKey),
                      filter: filter,
                      "show-operator": index + 1 !== _vm.filterForShow.length,
                    },
                  })
                : _vm._e(),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }