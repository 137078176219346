<template>
  <div class="form-group">
    <label :for="id">
      {{ title }}
      <span class="text-danger" v-if="required">*</span>
    </label>
    <input
      :id="id"
      :type="type"
      :readonly="readonly"
      v-model.trim="form[name]"
      class="form-control"
      :class="{ 'is-invalid': form.errors.has(name) }"
      :placeholder="placeholder"
      :autofocus="autofocus"
    />
    <has-error :form="form" :field="name" />
  </div>
</template>

<script>
import Form, { HasError } from "../index";

export default {
  name: "TextField",
  components: {
    HasError,
  },
  props: {
    form: {
      type: Form,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "",
    },
    required: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>