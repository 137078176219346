<template>
  <span>
    {{ formattedValue }}
  </span>
</template>

<script>
import { parseSecondsToHour } from "../../../../utils/functions";

export default {
  props: {
    val: {
      required: true,
    },
    showHour: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    formattedValue() {
      const result = parseSecondsToHour(this.val);
      const hour = this.showHour ? `${result.hour}:` : "";
      return `${hour}${result.minutes}:${result.seconds}`;
    },
  },
};
</script>