var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.form.errors.any()
    ? _c(
        "div",
        {
          staticClass: "alert alert-danger alert-dismissible",
          attrs: { role: "alert" },
        },
        [
          _vm.dismissible
            ? _c(
                "button",
                {
                  staticClass: "close",
                  attrs: { type: "button", "aria-label": "Close" },
                  on: { click: _vm.dismiss },
                },
                [
                  _c("span", { attrs: { "aria-hidden": "true" } }, [
                    _vm._v("×"),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.message
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.message) } })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ul",
            _vm._l(_vm.form.errors.flatten(), function (error) {
              return _c("li", { domProps: { innerHTML: _vm._s(error) } })
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }