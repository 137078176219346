var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "ml-4" }, [
    _vm._v(
      "\n  " +
        _vm._s(_vm.$t("tables.showing")) +
        " " +
        _vm._s(_vm.$t("tables.from")) +
        " " +
        _vm._s(_vm.from) +
        "\n  " +
        _vm._s(_vm.$t("tables.to")) +
        " " +
        _vm._s(_vm.to) +
        "\n  " +
        _vm._s(_vm.$t("tables.of")) +
        " " +
        _vm._s(_vm.total) +
        " " +
        _vm._s(_vm.$t("tables.rows")) +
        "\n"
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }