<template>
  <span
    :class="classes"
    :style="{
      color: textColor,
      'background-color': backgroundColor,
      'border-radius': '4px',
    }"
    >{{ statusName }}</span
  >
</template>

<script>
import { mapGetters } from "vuex";
import { OrderStatus } from "./index";

import { isset } from "../../../utils/functions";

export default {
  props: {
    status: {
      type: OrderStatus,
      required: true,
    },
    showTitle: {
      type: Boolean,
      default: true,
    },
    customClasses: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("lang", ["locale"]),
    statusName() {
      return isset(this.status) && this.showTitle
        ? this.status[`${this.locale}name`]
        : "";
    },
    textColor() {
      return isset(this.status) ? this.status.text_color : "";
    },
    backgroundColor() {
      return isset(this.status) ? this.status.color : "";
    },
    classes() {
      return !this.customClasses ? { "p-1": true, "text-center": true } : {};
    },
  },
};
</script>