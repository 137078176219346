var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      class: _vm.classes,
      style: {
        color: _vm.textColor,
        "background-color": _vm.backgroundColor,
        "border-radius": "4px",
      },
    },
    [_vm._v(_vm._s(_vm.statusName))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }