import Cookies from 'js-cookie'
import { TOGGLE_ARTICLE_TAG } from '../mutation-types'

// state
export const state = {
    articleTag: Cookies.get('articleTag') !== null && Cookies.get('articleTag') !== undefined ? JSON.parse(Cookies.get('articleTag')) : [],
}

// getters
export const getters = {
    articleTag: state => state.articleTag,
    isShowArticleTag: state => work_shop_id => {
        let article = state.articleTag.find(item => parseInt(item.k) === parseInt(work_shop_id));
        if (article !== undefined) {
            return article.v;
        }
        return true;
    }
}

// mutations
export const mutations = {
    [TOGGLE_ARTICLE_TAG](state, { work_shop_id, value }) {
        let article = state.articleTag.find(item => parseInt(item.k) === parseInt(work_shop_id));
        if (article !== undefined) {
            article.v = value;
        } else {
            state.articleTag.push({ k: work_shop_id, v: value });
        }
        Cookies.set('articleTag', state.articleTag, { expires: 365 });
    },
}

export const actions = {
    saveToggleTag({ commit }, payload) {
        commit(TOGGLE_ARTICLE_TAG, payload);
    },

};