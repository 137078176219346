<template>
  <v-select
    :label="label"
    :options="options"
    class="mb-1"
    v-model="value"
    @input="input"
    v-if="show"
    :getOptionKey="getOptionKey"
  ></v-select>
</template>

<script>
import vSelect from "vue-select";

export default {
  props: {
    label: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    show: {
      type: Boolean,
      default: true
    },
    val: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      value: this.val
    };
  },
  components: {
    vSelect
  },
  template: `
    
  `,
  methods: {
    input(val) {
      this.$emit("input", val);
    },
    getOptionKey(option) {
      return option.key;
    }
  }
};
</script>

<style>
</style>